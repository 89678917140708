<template>
  <span>
    <Multiselect
      mode="single"
      :options="options"
      :close-on-select="true"
      :can-clear="false"
      :can-deselect="false"
      :model-value="selected"
      :multiple-label="label"
      :use-default-label="true"
      :placeholder="t('graph')"
      class="graph-navigation"
      @change="navigate"
    />
  </span>
</template>

<script setup>
import { computed, unref } from 'vue';
import { useRouter, useRoute } from 'vue-jkrouter';
import Multiselect from '@/components/utils/Multiselect.vue';
import { useJkcms, useTranslation } from '@/jkcms';

const t = useTranslation();
const router = useRouter();
const route = useRoute();

const cms = useJkcms();

const options = [
  {
    label: computed(() => t('theNFP', 'Das NFP')),
    value: 'programs',
  },
  {
    label: computed(() => t('sdg', 'SDG')),
    value: 'sdg',
  },
  {
    label: computed(() => t('topics', 'Schwerpunkte')),
    value: 'topics',
  },
  {
    label: computed(() => t('researchProjects', 'Projekte')),
    value: 'projects',
  },
];

const label = (value) => value?.[0]?.label;

const selected = computed(() => {
  const paths = unref(route).namePath;
  // on project details display all options
  if (paths.includes('project')) return null;
  // check which page tree is selected
  return options.find((option) => paths.includes(option.value))?.value;
});

const navigate = (e) => {
  if (!e) return;
  router.pushState(e, { locale: cms.locale });
};
</script>

<style lang="scss" scoped>
:deep(.multiselect-number) {
  display: none;
}

.multi-select {
  --ms-bg: var(--color-green);
  --ms-dropdown-bg: var(--color-green);
  --ms-option-bg-selected: var(--color-green);
  --ms-option-bg-selected-pointed: var(--color-green);
  --ms-placeholder-color: var(--color-white);
  --ms-dropdown-border-width: 0;

  align-self: stretch;
  color: var(--color-white);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1000;
  font-weight: $bold;

  @include hover {
    background: var(--color-green);
    color: white;
  }

  :deep(.multiselect-option.is-selected) {
    display: none;
  }
  :deep(.multiselect-multiple-label) {
    padding-right: px(10);
  }

  :deep(.caret path) {
    stroke-width: 2px;
  }
}

:global(.multi-select + .link) {
  padding-left: px(18)!important;
}
</style>
