"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMatch = exports.usePathMatch = exports.useNameToPath = exports.useMatchToPath = exports.useMatchByName = exports.useRoute = exports.useRouter = exports.useRouterBusy = exports.INJECT_ROUTE_KEY = exports.INJECT_ROUTER_KEY = exports.INJECT_ROUTER_BUSY_KEY = void 0;
const vue_1 = require("vue");
const util_1 = require("./util");
exports.INJECT_ROUTER_BUSY_KEY = Symbol('@jk/router-busy');
exports.INJECT_ROUTER_KEY = Symbol('@jk/router');
exports.INJECT_ROUTE_KEY = Symbol('@jk/route-match');
function useRouterBusy() {
    return (0, vue_1.inject)(exports.INJECT_ROUTER_BUSY_KEY);
}
exports.useRouterBusy = useRouterBusy;
function useRouter() {
    return (0, vue_1.inject)(exports.INJECT_ROUTER_KEY);
}
exports.useRouter = useRouter;
function useRoute() {
    return (0, vue_1.inject)(exports.INJECT_ROUTE_KEY);
}
exports.useRoute = useRoute;
const defaultOptions = {
    exact: false,
    virtual: false,
};
exports.useMatchByName = (0, util_1.reactify)((name, parameters) => {
    const router = useRouter();
    const match = (0, vue_1.ref)(router.matchByName(name, parameters));
    const update = () => {
        match.value = router.matchByName(name, parameters);
    };
    router.on('routes', update);
    if ((0, vue_1.getCurrentScope)()) {
        (0, vue_1.onScopeDispose)(() => router.off('routes', update));
    }
    return match;
});
exports.useMatchToPath = (0, util_1.reactify)((match, query) => {
    const router = useRouter();
    const path = (0, vue_1.ref)(router.matchToPath(match, query));
    const update = () => {
        path.value = router.matchToPath(match, query);
    };
    router.on('routes', update);
    if ((0, vue_1.getCurrentScope)()) {
        (0, vue_1.onScopeDispose)(() => router.off('routes', update));
    }
    return path;
});
exports.useNameToPath = (0, util_1.reactify)((name, parameters, query) => {
    const router = useRouter();
    const path = (0, vue_1.ref)(router.nameToPath(name, parameters, query));
    const update = () => {
        path.value = router.nameToPath(name, parameters, query);
    };
    router.on('routes', update);
    if ((0, vue_1.getCurrentScope)()) {
        (0, vue_1.onScopeDispose)(() => router.off('routes', update));
    }
    return path;
});
exports.usePathMatch = (0, util_1.reactify)((path, options) => {
    const match = (0, vue_1.ref)(null);
    if (!path)
        return match;
    const router = useRouter();
    const route = useRoute();
    const { exact, virtual } = Object.assign(Object.assign({}, options), defaultOptions);
    const update = (currentRoute) => {
        const testRoute = virtual && currentRoute.virtualMatch ? currentRoute.virtualMatch : currentRoute;
        if (router.doesPathMatch(path, testRoute, exact)) {
            match.value = testRoute;
        }
        else {
            match.value = null;
        }
    };
    (0, vue_1.watch)(route, update);
    update(route.value);
    return match;
});
exports.useMatch = (0, util_1.reactify)((name, parameters = {}, options) => {
    const match = (0, vue_1.ref)(null);
    if (!name)
        return match;
    const router = useRouter();
    const route = useRoute();
    const { exact, virtual } = Object.assign(Object.assign({}, defaultOptions), options);
    const update = (currentRoute) => {
        const testRoute = virtual && currentRoute.virtualMatch ? currentRoute.virtualMatch : currentRoute;
        if (router.doesNameMatch(name, testRoute, parameters, exact)) {
            match.value = testRoute;
        }
        else {
            match.value = null;
        }
    };
    (0, vue_1.watch)(route, update);
    update(route.value);
    return match;
});
