const beta = process.env.NODE_ENV === 'development'
  || (!!['localhost', 'beta.', 'stage.'].find((domain) => window.location.hostname.startsWith(domain))
    && !window.prerender);

const locales = ['de', 'en', 'fr'];

function getLocale() {
  const path = window.location.pathname;
  const locale = path.split('/').find((v) => v.trim());
  return locales.includes(locale) ? locale : 'de';
}

export default {
  namespaced: true,
  state: {
    // defaults
    locale: getLocale(),
    layout: null,
    page: null,
    pages: {
      404: {
        content: [
          { component: 'NotFound', data: { id: -1 } },
          { component: 'Footer', data: { id: -2 } },
        ],
      },
    },

    // restore from prerender
    ...(window?.INIT_STORE_CMS || {}),

    // always set
    base: (beta || window.prerender) ? 'https://nfp73.ch/api' : 'https://nfp73.b-cdn.net/api',
    beta,
    locales,
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
    },
    setLayout(state, layout) {
      state.layout = layout;
    },
    setPage(state, page) {
      state.page = page;
    },
  },
  getters: {
    page: (state) => state.page,
    pageSlug: (state) => (pageId) => {
      if (!state.layout?.navigation) return undefined;

      const lookup = (p) => {
        if (p.subs[pageId]) return p.subs[pageId];
        // eslint-disable-next-line no-restricted-syntax
        for (const sub of Object.values(p.subs)) {
          const hit = lookup(sub);
          if (hit) return hit;
        }
        return undefined;
      };

      const page = lookup({ subs: state.layout?.navigation });
      return page?.alt?.[state.locale]?.slug;
    },
  },
  actions: {
    persist({ state }) {
      const script = document.createElement('script');
      script.innerHTML = `window.INIT_STORE_CMS=${JSON.stringify(state)};`;
      document.head.appendChild(script);
    },
  },
};
