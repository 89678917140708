"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _RouterFactory_defaultOptions;
Object.defineProperty(exports, "__esModule", { value: true });
const path_to_regexp_1 = require("path-to-regexp");
const util_1 = require("./util");
const regexRouter_1 = require("./regexRouter");
const routes_1 = require("./routes");
const EventEmitter = require("events");
function longestMatch(matches) {
    let [max, index] = [0, 0];
    for (let i = 0; i < matches.length; i++) {
        const match = matches[i];
        if (match.namePath.length > max) {
            max = match.namePath.length;
            index = i;
        }
    }
    return matches[index];
}
function defaultConflictResolution(matches) {
    const fullMatches = matches.filter((match) => !match.partial);
    if (fullMatches.length)
        return longestMatch(fullMatches);
    return longestMatch(matches);
}
const hasAllOf = (ofParams) => (params) => {
    return Object.entries(ofParams).every(([key, value]) => value === params[key]);
};
class RouterFactory extends EventEmitter {
    constructor(opts) {
        super();
        _RouterFactory_defaultOptions.set(this, {
            routes: [],
            plugins: [],
            router: (opts) => new regexRouter_1.default(opts),
            conflictResolution: defaultConflictResolution,
            routerOptions: {},
        });
        // We can possibly have many listeners
        this.setMaxListeners(1000);
        this.options = Object.assign(Object.assign({}, __classPrivateFieldGet(this, _RouterFactory_defaultOptions, "f")), opts);
        this.plugins = this.options.plugins;
        const transformMatch = this.createPluginApplication('onNavigatedMatch');
        this.options.onNavigate = (match) => opts.onNavigate(transformMatch(match));
        this.routerImpl = this.options.router(Object.assign({}, this.options.routerOptions));
        this.setRoutes(this.options.routes);
    }
    setRoutes(routes) {
        const transformRoutes = this.createPluginApplication('routesTransformer');
        this.routes = !(0, routes_1.isNormalized)(routes) ? (0, routes_1.normalizeRoutes)(routes) : routes;
        this.routes = transformRoutes(this.routes);
        this.routes = (0, routes_1.establishRouteDataInheritance)(this.routes);
        this.routes = (0, routes_1.initializeRoutes)(this.routes, this.options.routeInitializer);
        this.routerImpl.replaceRoutes(this.routes);
        this.emit('routes', this.routes);
    }
    navigate(path) { }
    enrichMatch(match, path) {
        var _a;
        if (!match)
            return match;
        const coercion = (_a = match.data.coercion) !== null && _a !== void 0 ? _a : ((params) => params);
        match.parameters = coercion({
            path: match.pathParams,
            query: path ? (0, util_1.buildQueryFromPath)(path) : {},
        });
        return match;
    }
    createPluginApplication(method) {
        return (data) => this.plugins.reduce((result, plugin) => {
            var _a;
            const pluginMethod = plugin[method];
            return (_a = pluginMethod === null || pluginMethod === void 0 ? void 0 : pluginMethod(result)) !== null && _a !== void 0 ? _a : result;
        }, data);
    }
    matchByPath(path) {
        const transformMatches = this.createPluginApplication('onMatches');
        const transformMatch = this.createPluginApplication('onMatch');
        const matches = transformMatches(this.routerImpl.matchByPath(path));
        const match = transformMatch(this.options.conflictResolution(matches));
        return transformMatch(this.enrichMatch(match, path));
    }
    matchByName(name, parameters) {
        const transformMatches = this.createPluginApplication('onMatches');
        const transformMatch = this.createPluginApplication('onMatch');
        const matches = transformMatches(this.routerImpl.matchByName(name, parameters));
        const match = this.options.conflictResolution(matches);
        return transformMatch(this.enrichMatch(match));
    }
    matchToPath(match, query) {
        if (!match)
            return null;
        const searchParams = (0, util_1.buildQueryString)(query);
        const toPath = (0, path_to_regexp_1.compile)(match.template, { encode: encodeURIComponent });
        try {
            const path = toPath(match.pathParams);
            return `${path}${searchParams}`;
        }
        catch (e) {
            console.error(e);
            return null;
        }
    }
    nameToPath(name, parameters, query) {
        const match = this.matchByName(name, parameters);
        return match && this.matchToPath(match, query);
    }
    doesPathMatch(path, match, exact = false) {
        let pathMatch = this.matchByPath(path);
        if (pathMatch === null || pathMatch === void 0 ? void 0 : pathMatch.namePath.every((partName, i) => partName === match.namePath[i])) {
            return exact ? (pathMatch === null || pathMatch === void 0 ? void 0 : pathMatch.namePath.length) === match.namePath.length : true;
        }
        return false;
    }
    doesNameMatch(name, match, parameters = {}, exact = false) {
        if (!match)
            return false;
        const matchParams = hasAllOf(parameters);
        if (typeof name === 'string') {
            if (match.namePath.includes(name) && matchParams(match.pathParams)) {
                return exact ? match.namePath.at(-1) === name : true;
            }
        }
        else if (name.every((partName, i) => partName === match.namePath[i]) &&
            matchParams(match.pathParams)) {
            return exact ? name.length === match.namePath.length : true;
        }
        return false;
    }
}
exports.default = RouterFactory;
_RouterFactory_defaultOptions = new WeakMap();
