"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const hooks_1 = require("./hooks");
exports.default = {
    props: {
        filter: { type: Function },
        name: { type: [String, Array] },
        parameters: { type: Object },
        path: { type: String },
        component: { type: Object },
        exact: { type: Boolean },
        matchVirtual: { type: Boolean, default: false },
    },
    setup(props) {
        const route = (0, hooks_1.useRoute)();
        const nameMatch = (0, hooks_1.useMatch)(props.name, props.parameters, {
            exact: props.exact,
            virtual: props.matchVirtual,
        });
        const pathMatch = (0, hooks_1.usePathMatch)(props.path, { exact: props.exact, virtual: props.matchVirtual });
        const busy = (0, hooks_1.useRouterBusy)();
        const match = (0, vue_1.computed)(() => {
            if (props.filter && typeof props.filter === 'function' && props.filter(route.value))
                return route.value;
            if (props.name)
                return nameMatch.value;
            if (props.path)
                return pathMatch.value;
            return null;
        });
        return {
            route,
            match,
            busy,
        };
    },
    render() {
        var _a, _b, _c, _d;
        const props = Object.assign({ match: this.match }, this.$attrs);
        if (!this.match)
            return (_b = (_a = this.$slots).else) === null || _b === void 0 ? void 0 : _b.call(_a, props);
        if (this.busy && this.$slots.busy)
            return this.$slots.busy();
        if (this.component)
            return (0, vue_1.h)(this.component, props);
        return (_d = (_c = this.$slots).default) === null || _d === void 0 ? void 0 : _d.call(_c, props);
    },
};
