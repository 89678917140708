"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const jkrouter_1 = require("jkrouter");
const vue_1 = require("vue");
const Route_1 = require("./src/Route");
const hooks_1 = require("./src/hooks");
const browserRouter_1 = require("jkrouter/dist/src/browserRouter");
const virtual_1 = require("./src/virtual");
const util_1 = require("./src/util");
__exportStar(require("./src/vue"), exports);
__exportStar(require("./src/hooks"), exports);
__exportStar(require("./src/Route"), exports);
exports.default = {
    install(app, options) {
        const routeMatch = (0, vue_1.ref)(null);
        const busy = (0, vue_1.ref)(null);
        let router;
        const swapMatch = (match) => {
            var _a;
            routeMatch.value = match;
            app.config.globalProperties.$route = match;
            (_a = options.onNavigate) === null || _a === void 0 ? void 0 : _a.call(options, match);
        };
        const routes = (0, util_1.unrefDeep)(options.routes);
        const updateQueue = [];
        function handleQueue() {
            return __awaiter(this, void 0, void 0, function* () {
                const oldMatch = routeMatch.value;
                const nextMatch = updateQueue[0];
                const controllerData = {
                    prevMatch: oldMatch,
                    match: nextMatch,
                };
                busy.value = true;
                const controllerPromise = (0, browserRouter_1.applyControllersDefaultStrategy)(router, oldMatch, nextMatch, controllerData).then((context) => {
                    busy.value = false;
                    return context;
                });
                if (oldMatch) {
                    try {
                        const context = yield controllerPromise;
                        swapMatch(context.data.match);
                    }
                    catch (e) {
                        console.groupCollapsed(`%cRoute transition cancelled: ${e}`, 'background-color: blue');
                        console.error(e);
                        console.groupEnd();
                    }
                }
                else {
                    swapMatch(nextMatch);
                    try {
                        const context = yield controllerPromise;
                        swapMatch(context.data.match);
                    }
                    catch (e) {
                        console.groupCollapsed(`%cRoute transition cancelled: ${e}`, 'background-color: #fffbe5; color: #6e6e6e');
                        console.error(e);
                        console.groupEnd();
                    }
                }
                updateQueue.shift();
                if (updateQueue.length) {
                    handleQueue();
                }
            });
        }
        router = new jkrouter_1.default(Object.assign(Object.assign({}, options), { routes, routeInitializer: (route) => {
                var _a, _b;
                return (_b = (_a = options === null || options === void 0 ? void 0 : options.routeInitializer) === null || _a === void 0 ? void 0 : _a.call(options, route)) !== null && _b !== void 0 ? _b : route;
            }, onNavigate: (match) => __awaiter(this, void 0, void 0, function* () {
                const oldMatch = routeMatch.value;
                const nextMatch = (0, virtual_1.virtualizedMatch)(router, match, oldMatch);
                updateQueue.push(nextMatch);
                if (updateQueue.length === 1) {
                    handleQueue();
                }
            }) }));
        router.init();
        // Routes may be reactive we can watch for route changes
        // and if found we can replace the routes to reinitialize / compile
        // the new routes.
        (0, vue_1.watch)(() => options.routes, () => {
            router.setRoutes((0, util_1.unrefDeep)(options.routes));
        }, { deep: true });
        app.component('route', Route_1.default);
        app.config.globalProperties.$routerBusy = busy;
        app.config.globalProperties.$router = router;
        app.config.globalProperties.$route = routeMatch.value;
        app.provide(hooks_1.INJECT_ROUTER_BUSY_KEY, busy);
        app.provide(hooks_1.INJECT_ROUTE_KEY, routeMatch);
        app.provide(hooks_1.INJECT_ROUTER_KEY, router);
    },
};
