"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeRoutes = exports.initializeRoutes = exports.establishRouteDataInheritance = exports.isNormalized = void 0;
function isNormalized(routes) {
    return !Array.isArray(routes[0]) && typeof routes[0] === 'object';
}
exports.isNormalized = isNormalized;
function mergeData(parentData, data = {}) {
    const keys = new Set(Object.keys(parentData).concat(Object.keys(data)));
    return Object.fromEntries(Array.from(keys).map((key) => {
        const value = data[key];
        const parentValue = parentData[key];
        if (key === 'name')
            return [key, value];
        if (Array.isArray(parentValue) && Array.isArray(value)) {
            return [key, parentValue.concat(value)];
        }
        return [key, value !== null && value !== void 0 ? value : parentValue];
    }));
}
function establishRouteDataInheritance(routes, parentData = {}) {
    return routes.map((route) => {
        const data = mergeData(parentData, route.data);
        return Object.assign(Object.assign({}, route), { data, children: establishRouteDataInheritance(route.children, data) });
    });
}
exports.establishRouteDataInheritance = establishRouteDataInheritance;
function initializeRoutes(routes, initializer) {
    return routes.map((route) => (Object.assign(Object.assign({}, initializer(route)), { children: initializeRoutes(route.children, initializer) })));
}
exports.initializeRoutes = initializeRoutes;
function normalizeRoutes(routes) {
    // If the first element is not a string we have an
    // array of routes
    if (!routes)
        return [];
    if (typeof routes[0] !== 'string') {
        return routes.flatMap((routes) => normalizeRoutes(routes));
    }
    // ["<path>", "<children>"]
    if (Array.isArray(routes[1])) {
        return [
            {
                path: routes[0],
                data: {},
                children: normalizeRoutes(routes[1]),
            },
        ];
    }
    // ["<path>", "<name>", "<children>"]
    if (typeof routes[1] === 'string') {
        const data = {
            name: routes[1],
        };
        return [
            {
                path: routes[0],
                data,
                children: normalizeRoutes(routes[2]),
            },
        ];
    }
    // ["<path>", "{ name: <name>, ... }", "<children>"]
    const data = routes[1];
    return [
        {
            path: routes[0],
            data,
            children: normalizeRoutes(routes[2]),
        },
    ];
}
exports.normalizeRoutes = normalizeRoutes;
