import { reactive, computed, unref } from 'vue';

class CompositionStore {
  constructor() {
    this.store = reactive({});
  }

  set(key, value) {
    unref(this.store)[key] = value;
  }

  get(key) {
    return computed(() => this.store[key]);
  }

  getAll() {
    return computed(() => this.store);
  }

  update(key, updateFn) {
    this.set(key, updateFn(this.get(key)));
  }

  delete(key) {
    delete unref(this.store)[key];
  }

  deleteAll() {
    Object.keys(this.store).forEach((key) => {
      delete unref(this.store)[key];
    });
  }
}

export default CompositionStore;
