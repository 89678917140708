export const cleanLabel = (name) => name.replace(/<\s*br\s*\/?>|\n/g, ' ');

export const makeOptions = (data, labelSelector = (entity) => entity.title) => {
  const entities = data ?? [];
  return entities.map((entity) => ({
    value: entity.id,
    label: cleanLabel(labelSelector(entity)),
  }));
};

export const withTagFilter = (entities, selectedTags, isSelected) => entities?.map((project) => ({
  ...project,
  filtered: !selectedTags.length || project.tags?.some((tag) => isSelected(tag)),
})) ?? [];

export const sortedEntities = (entities) => entities?.sort((a, b) => {
  if (a.filtered && b.filtered) return a.title.localeCompare(b.title);
  if (a.filtered) return -1;
  return 1;
}) ?? [];

export const entityIdSelector = (single, multiple) => (entity) => {
  const ids = entity[multiple] ?? [entity[single]];
  return ids.concat(entity.id);
};
