import CompositionStore from './compositionStore';

class ApiStore {
  constructor() {
    this.store = {
      queries: new CompositionStore(),
      cache: new CompositionStore(),
    };
  }

  setQuery(key, value) {
    this.store.queries.set(key, value);
  }

  getQuery(key) {
    return this.store.queries.get(key);
  }

  getQueries() {
    return this.store.queries.getAll();
  }

  updateQuery(key, updateFn) {
    this.store.queries.update(key, updateFn);
  }

  deleteQuery(key) {
    this.store.queries.delete(key);
  }

  setCache(key, value) {
    this.store.cache.set(key, value);
  }

  getCache(key) {
    return this.store.cache.get(key);
  }

  updateCache(key, updateFn) {
    this.store.cache.update(key, updateFn);
  }

  deleteCache(key) {
    this.store.cache.delete(key);
  }

  deleteAllCache() {
    this.store.cache.deleteAll();
  }
}

export default ApiStore;
