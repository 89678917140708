function remember(el, binding) {
  if (!binding.arg) throw new Error('Missing argument in remember directive');

  // eslint-disable-next-line no-param-reassign
  el[`__jkweb_${binding.arg}`] = binding.value;
}

export const getRemembered = (el, arg) => {
  if (!arg) throw new Error('Missing remembered argument');
  if (!el) throw new Error('Missing element');
  return el[`__jkweb_${arg}`];
};

// eslint-disable-next-line import/prefer-default-export
export const rememberDirective = {
  created: remember,
  updated: remember,
};
