<template>
  <component
    :is="props.is"
    class="part"
    :class="{ highlighted: payload?.optionen?.includes('highlighted') }"
  >
    <component :is="Container">
      <slot />
    </component>
  </component>
</template>

<script setup>
import { inject } from 'vue';

const props = defineProps({ is: { type: [Object, String], default: 'article' } });
const Container = inject('container');
const payload = inject('payload', {});
</script>

<style lang="scss">
:root {
  --part-highlight-color: #{transparentBackground(currentColor, 0.08)};
}
</style>
<style scoped lang="scss">
.part {
  padding-top: 0;
  padding-bottom: var(--part-padding);

  & + .part.highlighted {
    padding-top: var(--part-padding);
  }

  &.highlighted {
    background: var(--part-highlight-color);

    & + .part {
      padding-top: var(--part-padding);
    }

    & + .part.highlighted {
    //  padding-top: 0;
    }
  }
}
</style>
