<template>
  <article class="content">
    <div v-if="topic" class="topic">
      <div class="constrain">
        <header class="topic-header">
          <h1 v-html="topic.title" />
          <action-buttons class="action-buttons" role="menu">
            <BookmarkButton :id="topic?.id?.toString()" type="topic" />
          </action-buttons>
        </header>
      </div>
      <Dynamic :inject="topic.parts" />
    </div>
    <Footer class="footer" sidebar />
  </article>
</template>

<script setup>
import { computed, unref } from 'vue';
import entities from '@/entities';
import { hexToRgb } from '@/utils/color';
import Dynamic from '../structure/Dynamic.vue';
import BookmarkButton from '../utils/BookmarkButton.vue';
import ActionButtons from '../utils/ActionButtons.vue';
import Footer from '@/components/structure/Footer.vue';
import { useJkcms } from '@/jkcms';
import { useMeta } from '@/hooks/meta';

defineProps({
  match: { type: Object },
});

/*
const topicId = computed(() => props.match?.parameters.path.topicId);
const topic = entities.useTopic(topicId);
*/

const cms = useJkcms();
const topic = computed(() => {
  if (Array.isArray(cms.page?.content)) return null;
  return cms.page?.content;
});

const sdgId = computed(() => unref(topic)?.sdg);
const sdg = entities.useSdg(sdgId);

useMeta({
  title: computed(() => unref(topic)?.title),
});
</script>

<style lang="scss" scoped>
:global() {
  :root {
    --topic-color: black;
  }
}
.topic {
  --text-color: v-bind('sdg?.hervorhebungsfarbe');
  --text-color-rgb: v-bind('hexToRgb(sdg?.hervorhebungsfarbe)');
  --topic-color: v-bind('sdg?.farbe');
  --topic-color-rgb: v-bind('hexToRgb(sdg?.farbe)');
  color: v-bind('sdg?.hervorhebungsfarbe');
}

.topic-header {
  position: relative;
  display: flex;
  justify-content: space-between;

  h1 {
    flex-basis: 60%;
    @include responsive('margin-bottom', px(35), px(59));
  }
}

:deep(.action-buttons .active)::before {
  background: var(--color-red);
}
</style>
