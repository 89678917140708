import { createVuexEntityStore } from '@/utils/entities/vuex';
import { cap } from './utils/string';

const entityDefinitions = [
  createVuexEntityStore('synthesis', 'syntheses'),
  createVuexEntityStore('media', 'medias'),
  createVuexEntityStore('publication', 'publications'),
  createVuexEntityStore('project', 'projects'),
  createVuexEntityStore('topic', 'topics'),
  createVuexEntityStore('sdg', 'sdgs'),
];

export const stores = Object.fromEntries(
  entityDefinitions.map((definition) => [definition.pluralName, definition.store]),
);

/**
 * @type Object.<string, function | object>
 */
export default entityDefinitions.reduce(
  (all, definition) => ({
    ...all,
    [`create${cap(definition.singularName)}Adapter`]: definition.createAdapter,
    [`use${cap(definition.pluralName)}`]: definition.hook,
    [`use${cap(definition.singularName)}`]: definition.singleHook,
    [`${definition.singularName}Store`]: definition.store,
  }),
  {},
);
