<template>
  <article class="media-center">

    <div class="header-spacer bg-gray-200" />
    <div class="constrain">
      <div class="left-content row-12">
        <div v-if="videos.length" class="col-10 offset-1">
          <section class="newest-video">
            <h2>{{ t('media.newestVideo') }}</h2>
            <media-box v-if="videos.length > 0" :media="videos[0]" />
            <Button class="all-button" @click="selectType(907)">
              {{ t('allVideos') }}
            </Button>
          </section>
        </div>
        <div v-if="podcasts.length" class="col-10 offset-1">
          <section class="newest-podcast">
            <h2>{{ t('media.newestPodcast') }}</h2>
            <media-box v-if="podcasts.length > 0" :media="podcasts[0]" podcast />
            <Button class="all-button" @click="selectType(908)">
              {{ t('allPodcasts') }}
            </Button>
          </section>
        </div>
      </div>
    </div>
    <snf-logo />

    <ContentOverlay v-model="isContentOpen" disable-close>
      <div class="constrain">
        <h1>{{ title }}</h1>
        <div v-if="typeOptions.length" class="toolbar">
          <div class="w-full md:order-1 md:w-auto">
            <styled-link :to="t('Datenportal Link')" button>
              <span v-html="t('Datenportal Label')" />
            </styled-link>
          </div>
          <Multiselect
            v-model="selected.type"
            :placeholder="t('media.self')"
            :options="typeOptions"
            :close-on-select="true"
            mode="single"
          />
          <Multiselect
            v-model="selected.year"
            :placeholder="t('Jahr')"
            :options="yearOptions"
            :close-on-select="true"
            mode="single"
          />
          <!--
          <Multiselect
            v-model="selected.projects"
            :placeholder="t('projects')"
            :options="projectOptions"
          />
          <Multiselect
            v-model="selected.topics"
            :placeholder="t('topics')"
            :options="topicOptions"
          />
          <Multiselect v-model="selected.sdgs" :placeholder="t('sdgs')" :options="sdgOptions" />
          -->
        </div>

        <Transition name="appear" mode="out-in">
          <div :key="filteredMedia.length" class="list">
            <media-box
              v-for="entry of filteredMedia"
              :key="entry.id"
              :media="entry"
              icon-top-left
              colored
            />
            <div v-if="!filteredMedia.length && !isLoading">
              {{ t('Keine Ergebnisse') }}
            </div>
          </div>
        </Transition>

      </div>
      <Footer class="footer" sidebar />
    </ContentOverlay>

    <Filter />

  </article>
</template>

<script setup>
import {
  unref, computed, ref, watch,
} from 'vue';
import { useUrlSearchParams } from '@vueuse/core';
import Multiselect from '@/components/utils/Multiselect.vue';
import ContentOverlay from '@/components/utils/ContentOverlay.vue';
import SnfLogo from '@/components/utils/SnfLogo.vue';
import {
  useMediaQuery, // , useProjectsQuery, useTopicsQuery, useSdgsQuery,
} from '@/cmsApi';
import { useTagFilter } from '@/hooks/filter';
import { useJkcms, useTranslation } from '@/jkcms';
import MediaBox from '../utils/MediaBox.vue';
import Filter from '../utils/Filter.vue';
// import { entityIdSelector } from '@/utils/entities/selection';
import Footer from '@/components/structure/Footer.vue';
import { useWithinBreakpoint } from '@/hooks/breakpoints';
import { useMeta } from '@/hooks/meta';

defineProps({
  match: { type: Object },
});

const isContentOpen = ref(true);

const t = useTranslation();
const cms = useJkcms();
const meta = computed(() => cms?.page?.meta);
const types = computed(() => cms?.page?.content.data?.types || []);

const { data: media, isLoading } = useMediaQuery();
// const { data: projects } = useProjectsQuery();
// const { data: topics } = useTopicsQuery();
// const { data: sdgs } = useSdgsQuery();

watch(useWithinBreakpoint('md'), () => {
  isContentOpen.value = true;
});

const selected = useUrlSearchParams('history', {
  initialValue: {
    type: undefined,
    projects: undefined,
    topics: undefined,
    sdgs: undefined,
    year: undefined,
  },
});

const filteredMedia = computed(() => unref(useTagFilter(media) || [])
  .filter((entry) => {
    if (unref(selected.type) && entry.type !== unref(selected.type)) return false;
    // if (unref(selected.projects) && !entry.projects.includes(unref(selected.projects))) return false;
    //   if (unref(selected.topics) && !entry.topics.includes(unref(selected.topics))) return false;
    // if (unref(selected.sdgs) && !entry.sdgs.includes(unref(selected.sdgs))) return false;
    if (unref(selected.year) && !(entry.start || entry.datum).includes(unref(selected.year))) return false;
    return true;
  })
  .sort((a, b) => {
    const aa = a.start ? a.start : a.datum;
    const bb = b.start ? b.start : b.datum;
    // eslint-disable-next-line no-nested-ternary
    return (aa < bb) ? 1 : ((aa > bb) ? -1 : 0);
  }));

const selectType = (id) => {
  selected.type = types.value?.find((e) => e.id === id).slug;
  isContentOpen.value = true;
};

const videos = computed(() => unref(filteredMedia).filter((singleMedia) => singleMedia.parent === 907));
const podcasts = computed(() => unref(filteredMedia).filter((singleMedia) => singleMedia.parent === 908));

const typeOptions = computed(() => types.value.map((type) => ({
  label: type.alle,
  value: type.slug,
})));

const yearOptions = computed(() => {
  const years = [];
  (unref(media) || []).forEach((element) => {
    const year = (element.start || element.datum).split('-')[0];
    if (year && !years.includes(year)) years.push(year);
  });
  return years.sort((a, b) => b - a);
});
// const projectOptions = useEntityOptions(filteredMedia, projects, entityIdSelector('project', 'projects'));
// const topicOptions = useEntityOptions(filteredMedia, topics, entityIdSelector('topic', 'topics'));
// const sdgOptions = useEntityOptions(filteredMedia, sdgs, entityIdSelector('sdg', 'sdgs'));

const title = computed(() => t('media.title'));

useMeta({
  ...meta.value,
  title,
});
</script>

<style lang="scss" scoped>
.media-center {
  display: flex;
  flex-flow: column;
  flex: 1;
  @include responsive('width', 100%, 33%);

  @include responsive('--isotope-columns', 1, 4);

  @screen sm {
    --isotope-columns: 2;
  }

  @screen md {
    --isotope-columns: 3;
  }
}

.list {
  display: grid;
  grid-template-columns: auto;
  gap: 1.5rem;

  @screen md {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @screen lg {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

}

.left-content {
  position: relative;
}

:deep(.snf-logo) {
  background: linear-gradient(0, var(--color-gray-200) 75%, transparent 100%);
  padding-top: px(52);
  padding-bottom: px(52);
  padding-left: px(55);
  left: 0;
  right: 0;
  bottom: 0;
}

.toolbar {
  margin-bottom: px(36);
}

.newest-podcast {
  margin-top: px(58);
}

.all-button {
  margin-top: px(27);
}
</style>
