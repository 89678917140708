<template>
  <article class="content">
    <div class="projects constrain">
      <div class="toolbar">
        <!--
        <Multiselect
          v-model="selectedSort"
          mode="single"
          :can-clear="false"
          :can-deselect="false"
          :options="sortOptions"
          :hide-selected="true"
        />
        -->
        <Multiselect v-model="selected.topics" :placeholder="t('topics')" :options="topicOptions" />
        <Multiselect v-model="selected.sdgs" :placeholder="t('sdgs')" :options="sdgOptions" />
        <Multiselect
          v-model="selected.institutions"
          :placeholder="t('institution')"
          :options="institutionOptions"
        />
      </div>
      <Isotope v-if="projects?.length" :items="filteredProjects" :options="isotopeOptions">
        <template #default="{ item: project }">
          <ProjectCard
            class="project"
            :project="project"
            :faded="!project.filtered"
          />
        </template>
      </Isotope>
    </div>
    <Footer class="footer" sidebar />
  </article>
</template>

<script setup>
import { unref, computed } from 'vue';
import Multiselect from '@/components/utils/Multiselect.vue';
import { useProjectsQuery, useTopicsQuery, useSdgsQuery } from '@/cmsApi';
import { useFilters, useOptions, useTagFilter } from '@/hooks/filter';
// import { sortedEntities } from '@/utils/entities/selection';
import Isotope from '../utils/Isotope.vue';
import ProjectCard from '../utils/ProjectCard.vue';
import { useTranslation } from '@/jkcms';
import Footer from '@/components/structure/Footer.vue';

defineProps({
  match: { type: Object },
});

const t = useTranslation();

// const selectedSort = ref('alphabetical');

const { data: projects } = useProjectsQuery();
const { data: topics } = useTopicsQuery();
const { data: sdgs } = useSdgsQuery();

const institutions = computed(() => unref(projects)?.flatMap((project) => project.institutions ?? []));

const { selected, shouldFilter } = useFilters({
  topics: (project, id) => id === project.topic,
  sdgs: (project, id) => id === project.sdg,
  institutions: (project, id) => project.institutions?.find((institution) => institution.id === id),
});

const topicOptions = useOptions(topics);
const sdgOptions = useOptions(sdgs);
// const sortOptions = computed(() => [{ value: 'alphabetical', label: t('alphabetical') }]);
const institutionOptions = useOptions(institutions);

const filteredProjects = useTagFilter(projects);
// const sortedProjects = computed(() => sortedEntities(unref(filteredProjects)));

const isotopeOptions = computed(() => ({
  filter: unref(shouldFilter),
  masonry: {
    columnWidth: '.grid-sizer',
    gutter: '.gutter-sizer',
  },
}));
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
.projects {
  display: flex;
  flex-flow: column;
  flex: 1;

  --isotope-columns: 1;
  @screen md { --isotope-columns: 2 }
  @screen lg { --isotope-columns: 3 }
  @screen xxl { --isotope-columns: 4 }

}
</style>
