<template>
  <Go
    name="project"
    :parameters="{ uri: project.slug }"
    @mouseenter="graphBus.emit('hover', { projectId: project.id })"
    @focusin="graphBus.emit('hover', { projectId: project.id })"
    @mouseleave="graphBus.emit('blur', { projectId: project.id })"
    @focusout="graphBus.emit('blur', { projectId: project.id })"
  >
    <article class="project-card">
      <Box v-if="full" :aspect="1.457" class="project-box">
        <section class="project-content full">
          <Image v-if="project.vorschaubild?.id" :src="project.vorschaubild" />
          <span class="project-title" v-html="project.title" />
          <span class="project-contact">
            {{ project.kontakt }}
          </span>
        </section>
      </Box>
      <template v-else>
        <Box :aspect="0.97" class="project-box">
          <Image v-if="project.vorschaubild?.id" :src="project.vorschaubild" />
        </Box>
        <section class="project-content">
          <span class="project-title" v-html="project.title" />
          <span class="project-contact">
            {{ project.kontakt }}
          </span>
        </section>
      </template>
    </article>
  </Go>
</template>

<script setup>
import { computed, onUnmounted, unref } from 'vue';
import { useEventBus } from '@vueuse/core';
import Box from '@/components/utils/Box.vue';
import entities from '@/entities';
import Go from './Go.vue';

const props = defineProps({
  project: { type: Object },
  faded: { type: Boolean, default: false },
});

const graphBus = useEventBus('graph');
onUnmounted(() => { graphBus.emit('blur', { projectId: props.project.id }); });

const sdgId = computed(() => props.project?.sdg);

const sdg = entities.useSdg(sdgId);

const full = computed(() => props.project?.optionen?.includes('full'));

const highlightColor = computed(() => (unref(full)
  ? 'var(--color-white)'
  : unref(sdg)?.hervorhebungsfarbe ?? 'var(--color-gray-300)'));
const backgroundColor = computed(() => unref(sdg)?.farbe ?? 'var(--color-white)');
</script>

<style lang="scss" scoped>
.project-card {
  @apply rounded-big;

  overflow: hidden;
  clip-path: border-box;
  width: auto;
  position: relative;
  cursor: pointer;

  opacity: v-bind('faded ? 0.3 : 1');

  img {
    position: absolute;
    width: 100%;
    height: calc(100% + 1px);
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    will-change: transform;
    transition: transform var(--transition-fast) ease;
   // z-index: -2;
  }

  .project-box {
    clip-path: border-box;
  }

  .project-content {
    width: 100%;
    color: v-bind('highlightColor');
    background: v-bind('backgroundColor');
    padding: px(25) px(30);
    @screen lg {
      padding: px(20) px(23);
    }
    @screen xxl {
      padding: px(12) px(18);
    }

    &.full {
      padding: px(19) px(28);
      background: none;
      @screen lg {
        padding: px(16) px(23);
      }
      @screen xxl {
        padding: px(10) px(18);
      }
    }

    .project-title {
      position: relative;
      z-index: 1;
      font-weight: $semi-bold;
    }
    .project-contact {
      position: relative;
      z-index: 1;
      font-size: px(12);
      line-height: px(17);
      font-weight: $regular;
      margin-top: px(15);
      display: block;
    }

    &.full::after {
      content: '';
      opacity: 0.7;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      will-change: opacity;
      transition: opacity var(--transition-fast) ease;
      background: linear-gradient(var(--color-gray-300), transparent);
    }
  }

  &:hover {
    .content {
      img {
        transform: scale(1.05);
      }

      &::after {
        opacity: 1;
      }
    }
  }
}
</style>
