import { unref } from 'vue';
import { useGtm } from '@gtm-support/vue-gtm';
import { pageview } from 'vue-gtag';
import { redirectController, prerenderController, loadLayout } from './jkcms';
import {
  queryGraph,
  queryTags,
  queryProjects,
  queryTopics,
  querySdgs,
  queryCmsPage,
  queryPublications,
  queryMedia,
  refetchAll,
} from './cmsApi';
import store from './store';
import { bookmarksFromBase64 } from './utils/bookmarks';

function rootController() {
  return {
    name: 'rootController',
    parameters: { path: ['locale'] },
    async start({ path: { locale } }, { router, data: { match, prevMatch } }) {
      const { cms } = store.state;

      if (cms.locale !== locale) {
        store.commit('cms/setLocale', locale);
        refetchAll();
      }

      if (!cms.layout || cms.layout.locale !== locale) {
        await loadLayout();
        // eslint-disable-next-line no-use-before-define
        router?.setRoutes(getRoutes());

        if (prevMatch && match) {
          router.replaceState(
            match.data.name,
            match.parameters.path,
            match.parameters.query,
            match.state,
          );

          throw new Error('Redirect to language');
        } else {
          router.navigate();
        }
      }
    },
  };
}

function scrollController() {
  window.history.scrollRestoration = 'manual';
  return {
    priority: -100,
    getIdentity: (match) => ({
      uri: match.path,
    }),
    async start(_, { data: { match } }) {
      setTimeout(() => {
        window.scroll({
          top: match.state?.scrollPosition?.y || 0,
          left: match.state?.scrollPosition?.x || 0,
          behavior: 'instant',
        });
      }, 100); // give vue some time to render
    },
  };
}

function trackingController() {
  return {
    getIdentity: (match) => ({
      uri: match.path,
    }),
    async start({ uri }) {
      const gtm = useGtm();
      const { title } = window.document;
      gtm.trackView(title, uri);
      pageview({ page_title: title, page_path: uri });
    },
  };
}

function prefetchController(query, parameters = { path: ['locale'] }) {
  return {
    name: 'prefetchController',
    parameters,
    async start() {
      await query(null);
    },
  };
}

function bookmarksController(parameters = { path: ['locale'] }) {
  return {
    name: 'bookmarksController',
    parameters,
    start(_, { data: { match } }) {
      const bookmarkB64 = match?.parameters?.query.bookmarks;
      if (!bookmarkB64) return;
      const bookmarks = bookmarksFromBase64(bookmarkB64);
      store.commit('bookmarks/set', bookmarks);
    },
  };
}

function pageLoadController(prefix = '') {
  return {
    name: 'pageLoadController',
    getIdentity: (match) => ({
      prefix,
      uri: match.parameters.path.uri,
      locale: match.parameters.path.locale,
    }),
    parameters: { path: ['uri', 'locale'] },
    async start(_, context) {
      const pagePrefix = unref(prefix);
      let uri = context.data.match.parameters?.path.uri ?? '';
      const refetch = context.data.match.parameters?.path.locale
        !== context.data.prevMatch?.parameters?.path.locale;
      if (Array.isArray(uri)) {
        uri = uri.join('/');
      }
      const uriWithPrefix = pagePrefix ? `${pagePrefix}/${uri}` : uri;

      const result = await queryCmsPage(uriWithPrefix, { refetch });

      if (!result) {
        console.error('No response on:', uriWithPrefix);
        return;
      }

      store.commit('cms/setPage', result);
    },
  };
}

function localizedSlug(pageId, defaultSlug) {
  return store.getters['cms/pageSlug'](pageId) ?? defaultSlug;
}

function getRoutes() {
  return [
    '',
    {
      controllers: [redirectController()],
    },
    [
      '/:locale',
      {
        name: 'home',
        controllers: [
          trackingController(),
          scrollController(),
          rootController(),
          prerenderController(),
          prefetchController(queryTags),
          prefetchController(querySdgs),
        ],
      },
      [

        [
          '/',
          { name: 'graph', controllers: [prefetchController(queryGraph)] },
          [
            [
              localizedSlug(279, 'programs'),
              'programs',
              [
                [
                  '/',
                  {
                    name: 'program',
                    controllers: [pageLoadController(localizedSlug(279, 'programs'))],
                    sideContentOpen: true,
                  },
                ],
              ],
            ],
            [
              localizedSlug(250, 'sdgs'),
              'sdgs',
              [
                [
                  '/',
                  {
                    name: 'sdg',
                    controllers: [pageLoadController(localizedSlug(250, 'sdgs'))],
                    sideContentOpen: true,
                  },
                ],
              ],
            ],
            [
              localizedSlug(172, 'topics'),
              'topics',
              [
                [
                  '/:uri',
                  {
                    name: 'topic',
                    controllers: [
                      prefetchController(queryProjects),
                      prefetchController(queryTopics),
                      pageLoadController(localizedSlug(172, 'topics')),
                    ],
                    sideContentOpen: true,
                  },
                ],
              ],
            ],
            [
              localizedSlug(171, 'projects'),
              {
                name: 'projects',
                controllers: [prefetchController(queryProjects), prefetchController(queryTopics)],
                sideContentOpen: true,
              },
              [
                [
                  '/:uri',
                  {
                    name: 'project',
                    controllers: [pageLoadController(localizedSlug(171, 'projects'))],
                  },
                ],
              ],
            ],
          ],
        ],

        [
          `/${localizedSlug(48, 'mediacenter')}`,
          { name: 'media-center', controllers: [pageLoadController(localizedSlug(48, 'mediacenter'))] },
          [
            [
              `/${localizedSlug(906, 'news')}`,
              '',
              [
                // eslint-disable-next-line max-len
                ['/:uri', { name: 'news', controllers: [pageLoadController(`${localizedSlug(48, 'mediacenter')}/${localizedSlug(906, 'news')}`)] }],
              ],
            ],
            [
              `/${localizedSlug(909)}`,
              '',
              [
                // eslint-disable-next-line max-len
                ['/:uri', { name: 'news', controllers: [pageLoadController(`${localizedSlug(48, 'mediacenter')}/${localizedSlug(909, 'news')}`)] }],
              ],
            ],
            [
              `/${localizedSlug(1204)}`,
              '',
              [
                // eslint-disable-next-line max-len
                ['/:uri', { name: 'news', controllers: [pageLoadController(`${localizedSlug(48, 'mediacenter')}/${localizedSlug(1204, 'news')}`)] }],
              ],
            ],
          ],
        ],

        [
          `/${localizedSlug(197, 'synthesen')}/:uri`,
          {
            name: 'sidepage',
            controllers: [pageLoadController(`${localizedSlug(197, 'synthesen')}`)],
          },
        ],

        [
          '/',
          [
            [localizedSlug(280, 'search'), 'search'],
            [
              localizedSlug(282, 'bookmarks'),
              {
                name: 'bookmarks',
                controllers: [
                  prefetchController(queryProjects),
                  prefetchController(queryTopics),
                  prefetchController(queryPublications),
                  prefetchController(queryMedia),
                  bookmarksController(),
                ],
              },
            ],
            [':uri*', { name: 'cms-page', controllers: [pageLoadController()] }],
          ],
        ],

      ],
    ],
  ];
}

export default getRoutes;
