<template>
  <div ref="searchRef" class="search-bar">
    <button ref="searchFieldRef" class="search-field" @click="openField">
      <div class="icon">
        <SearchIcon class="search-icon" />
      </div>
      <div
        ref="overlayRef"
        class="search-overlay"
        @mouseover.stop
        @focus.stop
      >
        <div class="icon">
          <SearchIcon class="search-icon" />
        </div>
        <input
          ref="searchInput"
          v-model="searchTerm"
          class="search-input"
          type="text"
          :aria-label="t('search')"
          @keydown="onKeyDown"
        >
        <ChevronIcon class="chevron-right" @click="search" />
      </div>
    </button>
    <div
      class="autocomplete-dropdown"
      @mouseover.stop
      @focus.stop
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
import { ref, unref } from 'vue';
import { onClickOutside, onKeyStroke, useEventBus } from '@vueuse/core';
import SearchIcon from '@/assets/images/search.svg';
import ChevronIcon from '@/assets/images/chevron.svg';
import { useTranslation } from '@/jkcms';
import { useOptionalModel } from '@/hooks/model';

const emit = defineEmits(['update:modelValue', 'update:open', 'change']);
const props = defineProps({
  modelValue: { type: String, default: '' },
  open: { type: Boolean, default: false },
});
const bus = useEventBus('search');
const searchTerm = useOptionalModel(props, emit);
const internalOpen = useOptionalModel(props, emit, 'open');

const t = useTranslation();

const searchRef = ref(null);
const searchFieldRef = ref(null);
const searchInput = ref(null);

const openField = () => {
  if (!unref(internalOpen)) {
    internalOpen.value = true;
    unref(searchInput).focus();
    unref(searchFieldRef).closest('.select-slider').dispatchEvent(new MouseEvent('mouseleave'));
  }
};
const closeField = () => {
  internalOpen.value = false;
};
onClickOutside(searchRef, closeField);

bus.on((term) => {
  searchTerm.value = term;
  openField();
});

const search = () => {
  emit('change', unref(searchTerm));
};

const onKeyDown = (e) => {
  if (e.key !== 'Enter') return;
  search();
};
onKeyStroke('Escape', closeField);

</script>

<style lang="scss" scoped>
.search-bar {
  --icon-size: #{px(18)};
  --padding-left: #{px(28)};
  --padding-right: #{px(26)};

  --closed-width: calc(var(--padding-left) + var(--padding-right) + var(--icon-size));
  --open-width: 100%;

  display: flex;
  align-items: center;
  align-self: stretch;
  cursor: pointer;

  &.active {
    :deep(svg) {
      circle,
      path {
        stroke: currentColor; // var(--color-white);
      }
      circle[stroke='none'],
      circle[path='none'] {
        stroke: none;
      }
    }
  }

  nav .slider-nav > &.component {
    z-index: 15;
  }
}
.autocomplete-dropdown {
  @apply rounded-big;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: max-content;
  min-height: 100%;
  z-index: 0;
  opacity: v-bind('internalOpen ? 1 : 0');
  pointer-events: v-bind('internalOpen ? "all" : "none"');
  box-shadow: 0 3px 90px rgba(0, 0, 0, 0.16);

  cursor: default;
}

.search-field {
  cursor: pointer;
  padding-left: var(--padding-left);
  overflow: hidden;
  height: 100%;
  z-index: 10;

  &:focus {
    border: none;
    outline: none;
  }

  .search-overlay {
    @apply rounded-full;

    position: absolute;
    width: v-bind("internalOpen ? 'var(--open-width)' : 'var(--closed-width)'");
    opacity: v-bind('internalOpen ? 1 : 0');
    height: 100%;
    left: 0;
    top: 0;
    transform: translate3d(0, 0, 0);
    padding-left: var(--padding-left);
    background: var(--color-white);

    display: flex;
    align-items: center;

    transition: width var(--transition-fast) ease, opacity var(--transition-fast) ease;

    pointer-events: v-bind("internalOpen ? 'all' : 'none'");
  }

  .icon {
    padding-right: var(--padding-right);
    pointer-events: none;
  }

  :deep(svg) {
    pointer-events: none;
  }

  .search-icon {
    width: var(--icon-size);
    height: var(--icon-size);
  }

  .chevron-right {
    position: absolute;
    right: px(24);
    transform: rotate(-90deg);
    height: px(8);
    width: auto;
    margin-left: auto;
  }

  input.search-input {
    background: transparent;
    line-height: px(20);
    width: 100%;

    &:focus {
      border: none;
      outline: none;
    }
  }
}
</style>
