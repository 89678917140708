<template>
  <Go
    v-if="general?.awwwards "
    :to="general?.awwwardsLink"
    class="banner fixed z-20 py-3 px-8 w-full text-small font-semibold bg-green text-white text-center"
  >
    {{ general?.awwwards }}
  </Go>
</template>

<script setup>
import { computed, unref } from 'vue';
import { useJkcms } from '../../jkcms';

const cms = useJkcms();
const general = computed(() => unref(cms)?.layout?.general);

</script>

<style lang="scss">

.banner {
  transform: translateY(-100%);
  transition: 0.15s ease;
  .onTop & {
    transform: translateY(0);
  }
  &:hover {
    @apply text-green;
    background-color: #dde5ca;
  }

  @media (max-width: theme('screens.md')) {
    bottom: 0;
    transform: translateY(100%);
  }
}

</style>
