<template>
  <article class="content">
    <div v-if="sdg" class="sdg">
      <header class="sdg-header constrain">
        <h1 v-html="sdg.title" />
        <div v-if="sdg.intro" v-html="sdg.intro" />
      </header>
      <Dynamic :inject="sdg.parts" />
    </div>
    <Footer class="footer" sidebar />
  </article>
</template>

<script setup>
import { computed, unref } from 'vue';
import Dynamic from '../structure/Dynamic.vue';
import Footer from '@/components/structure/Footer.vue';
import { useJkcms } from '@/jkcms';
import { useMeta } from '@/hooks/meta';

defineProps({
  match: { type: Object },
});

const cms = useJkcms();
const sdg = computed(() => cms.page?.content);

useMeta({
  title: computed(() => unref(sdg)?.title),
});
</script>

<style lang="scss" scoped></style>
