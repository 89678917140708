import { onMounted, onUnmounted } from 'vue';

window.lockedOn = -1;

export function lock() {
  if (window.lockedOn > -1) return;
  const main = document.querySelector('main');
  window.lockedOn = window.pageYOffset;
  main.style.top = `${-window.lockedOn}px`;
  main.style.position = 'fixed';
}

export function unlock() {
  if (window.lockedOn === -1) return;
  const main = document.querySelector('main');
  main.style.position = '';
  main.style.top = '';
  document.documentElement.scrollTop = window.lockedOn;
  window.lockedOn = -1;
}

export function useScrollLock() {
  onMounted(lock);
  onUnmounted(unlock);
}
