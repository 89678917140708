<template>
  <section class="filter-content">
    <div v-for="tag in tags" :key="tag.id" class="tag-group">
      <h2 class="tag-title">
        {{ tag.title }}
      </h2>
      <ul>
        <li v-for="childTag in tag.children" :key="childTag.id" class="tag-list-item">
          <button
            class="tag"
            :class="{ selected: isSelected(childTag) }"
            @click.stop="toggleTag(childTag)"
          >
            {{ childTag.title }}
            <span class="remove-btn">
              <Close />
            </span>
          </button>
        </li>
      </ul>
    </div>
  </section>
  <section class="help-text">
    {{ t('filter.helpText') }}
  </section>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Close from '@/assets/images/close.svg';
import { useTagsQuery } from '@/cmsApi';
import { useTranslation } from '@/jkcms';

const t = useTranslation();

const { data: tags } = useTagsQuery();
const store = useStore();

const isSelected = computed(() => store.getters['filter/isSelected']);

const selectTag = (tag) => store.commit('filter/selectTag', tag);
const removeTag = (tag) => store.commit('filter/removeTag', tag);

const toggleTag = (tag) => (isSelected.value(tag) ? removeTag(tag) : selectTag(tag));
</script>

<style lang="scss" scoped>
.filter-content {
  // Add 30 px to compensate for padding transition on
  white-space: nowrap;
  margin-top: px(74);

  .tag-list-item {
    width: max-content;
    .tag {
      @apply rounded-full;

      display: flex;
      justify-content: space-between;
      align-items: center;

      position: relative;
      font-size: px(17);
      font-weight: $regular;
      line-height: px(23);
      margin: px(9) 0;
      transition: all var(--transition-fast) ease;
      padding: px(5) px(15) px(5) px(15);
      margin-left: px(-15);
      margin-right: px(42);

      .remove-btn {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: calc(#{px(17)} - 0.25em);
        top: 50%;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
        opacity: 0;
        transform-origin: center;
        pointer-events: none;
        transition: transform var(--transition-fast) ease, opacity var(--transition-fast) ease;

        svg {
          width: 0.5em;
          height: 0.5em;
        }
      }

      &.selected {
        background: white;
        margin: px(9) 0;
        color: var(--color-red);
      }

      @screen md {
        &:hover {
          background: white;
          margin: px(9) 0;
          color: var(--color-red);
        }
      }

      @screen md {
        &:hover {
          margin-right: px(27);
          .remove-btn {
            transform: translateY(-50%) scale(1.5);
          }
        }
      }

      &.selected {
        margin-right: 0;
        padding-right: px(42);

        .remove-btn {
          pointer-events: all;
          opacity: 1;
        }
      }
    }
  }

  .tag-group ul {
    margin-bottom: px(60);
  }

  .tag-title {
    position: relative;
    font-size: 23px;
    font-weight: bold;
    line-height: 32px;
    @include responsive('margin-bottom', 40px, px(26));

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 4px;
      background: var(--color-white);
      top: 100%;
      @include responsive('top', calc(100% + 10px), 100%);
      left: 0;
    }
  }
}

.help-text {
  font-weight: $semi-bold;
  font-style: italic;
  font-size: 13px;
  line-height: 22px;

  @include responsive('margin-right', 100px, 0);
}
</style>
