import { computed } from 'vue';

class VuexStore {
  constructor(prefix, name) {
    this.prefix = prefix;

    this.state = {};

    this.name = name;
    this.pascalName = `${name[0].toUpperCase()}${name.slice(1)}`;

    this.mutations = {
      [`set${this.pascalName}`](state, { key, value }) {
        state[name][key] = value;
      },
      [`delete${this.pascalName}`](state, key) {
        delete state[name][key];
      },
      delete(state) {
        Object.keys(state[name]).forEach((key) => {
          delete state[name][key];
        });
      },
    };

    this.getters = {
      [name]: (state) => (key) => state[name][key],
      [`all${this.pascalName}`]: (state) => state[name],
    };
  }

  setStore(store) {
    this.store = store;
  }

  set(key, value) {
    this.store.commit(`${this.prefix}/set${this.pascalName}`, { key, value });
  }

  get(key) {
    return computed(() => this.store.getters[`${this.prefix}/${this.name}`](key));
  }

  getAll() {
    return computed(() => this.store.getters[`${this.prefix}/all${this.pascalName}`]);
  }

  update(key, updateFn) {
    this.set(key, updateFn(this.get(key).value));
  }

  delete(key) {
    this.store.commit(`${this.prefix}/delete${this.pascalName}`, key);
  }

  deleteAll() {
    this.store.commit(`${this.prefix}/delete`);
  }

  vuex() {
    return {
      namespaced: !!this.prefix,
      state: this.state,
      mutations: this.mutations,
      getters: this.getters,
    };
  }
}

export default VuexStore;
