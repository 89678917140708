<template>
  <div class="filter-container">
    <button class="filter-button" @click="setOpen(true)">
      <span class="description" v-html="t('filter.openButtonText')" />
      <div class="rounded-icon">
        <Settings />
      </div>
      <div v-if="selectedTags.length > 0" class="selected">
        {{ selectedTags.length }}
      </div>
    </button>
    <aside v-if="isDesktop" v-on-click-outside="() => setOpen(false)" class="filter">
      <button class="filter-button close" @click="setOpen(false)">
        <div class="rounded-icon">
          <Close />
        </div>
        <span class="description">{{ t('filter.closeButtonText') }}</span>
      </button>
      <FilterContent />
    </aside>
    <MobileSwipe
      v-else
      :model-value="open"
      class="mobile-swipe-filter"
      @update:model-value="setOpen"
    >
      <div class="constrain">
        <FilterContent />
      </div>
    </MobileSwipe>
  </div>
</template>

<script setup>
import {
  computed, onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { vOnClickOutside } from '@vueuse/components';
import Settings from '@/assets/images/settings.svg';
import Close from '@/assets/images/close.svg';
import { useTranslation } from '@/jkcms';
import { useWithinBreakpoint } from '@/hooks/breakpoints';
import FilterContent from './FilterContent.vue';
import MobileSwipe from './MobileSwipe.vue';

const t = useTranslation();

const store = useStore();

const selectedTags = computed(() => store.getters['filter/selectedTags']);

const open = computed(() => store.getters['filter/isOpen']);

const setOpen = (nextOpen) => store.commit('filter/setOpen', nextOpen);

onUnmounted(() => setOpen(false));

const isDesktop = useWithinBreakpoint();
</script>

<style lang="scss" scoped>
.filter {
  position: fixed;
  top: 0;
  left: 100%;
  height: 100%;
  overflow-y: auto;
  padding: var(--header-height) px(210) px(75) px(270);
  background: var(--color-red);
  color: var(--color-white);
  will-change: transform;
  transition: transform var(--transition-fast) ease;
  transform: v-bind('open ? "translateX(-100%)" : "translateX(0)"');

  display: flex;
  flex-flow: column;
  justify-content: space-between;

  z-index: 1000;
}

.mobile-swipe-filter {
  z-index: 999;

  :deep(.mobile-swipe-card) {
    background: var(--color-red);
    color: var(--color-white);

    & .handle {
      background: var(--color-white);
    }
  }
}

.filter-button {
  --filter-button-color: var(--color-red);
  display: flex;
  position: fixed;
  font-size: px(15);
  line-height: px(20);
  color: var(--filter-button-color);
  font-weight: bold;
  text-align: right;
  align-items: center;
  z-index: 999;
  transition: opacity var(--transition-fast), color var(--transition-fast);

  @include responsive('right', px(31), px(53));
  @include responsive('top', initial, 50%);
  @include responsive('bottom', px(37), initial);
  @include responsive('transform', none, translateY(-50%));

  &:hover {
    --filter-button-color: var(--color-black);
    cursor: pointer;
  }

  .selected {
    position: absolute;
    top: 0;
    right: 0;
    width: px(22);
    height: px(22);
    font-size: px(15);
    font-weight: $bold;
    line-height: px(20);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(25%, -25%);

    &::before {
      content: '';
      @apply rounded-full;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      z-index: -1;
    }
  }

  .description {
    white-space: pre-line;
    max-width: 7rem;
    text-align: right;

    @include responsive('display', none, block);
  }

  .rounded-icon {
    margin-left: px(11);
    background-color: var(--filter-button-color);
  }

  &.close {
    left: 0;
    position: absolute;
    color: var(--color-white);
    text-align: left;
    margin-left: px(35);
    margin-right: px(100);
    right: initial;

    &:hover {
      opacity: 0.7;
    }

    .rounded-icon {
      background-color: var(--color-white);
      margin-left: 0;
      margin-right: px(11);

      :deep(svg) {
        max-width: 35%;
        max-height: 35%;
        path,
        circle {
          stroke: var(--color-red);
        }
      }
    }
  }
}

.filter-content {
  // Add 30 px to compensate for padding transition on
  white-space: nowrap;

  .tag-list-item {
    .tag {
      @apply rounded-full;

      display: flex;
      justify-content: space-between;
      align-items: center;

      position: relative;
      font-size: px(17);
      font-weight: $regular;
      line-height: px(23);
      margin: px(9) 0;
      transition: all var(--transition-fast) ease;
      padding: px(5) px(15) px(5) px(15);
      margin-left: px(-15);
      margin-right: px(42);

      .remove-btn {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: calc(#{px(17)} - 0.25em);
        top: 50%;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
        opacity: 0;
        transform-origin: center;
        pointer-events: none;
        transition: transform var(--transition-fast) ease, opacity var(--transition-fast) ease;

        svg {
          width: 0.5em;
          height: 0.5em;
        }
      }

      &:hover,
      &.selected {
        background: white;
        margin: px(9) 0;
        color: var(--color-red);
      }

      &:hover {
        margin-right: px(27);
        .remove-btn {
          transform: translateY(-50%) scale(1.5);
        }
      }

      &.selected {
        margin-right: 0;
        padding-right: px(42);

        .remove-btn {
          pointer-events: all;
          opacity: 1;
        }
      }
    }
  }

  .tag-group {
    margin-bottom: px(60);
  }

  .tag-title {
    position: relative;
    font-size: px(23);
    font-weight: bold;
    line-height: px(32);
    margin-bottom: px(26);

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 4px;
      background: var(--color-white);
      top: 100%;
      left: 0;
    }
  }
}

.help-text {
  @apply text-small;

  font-weight: $semi-bold;
  font-style: italic;
  line-height: px(22);
}
</style>
