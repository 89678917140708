"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stripQueryString = exports.buildQueryString = exports.buildQueryFromPath = exports.walkTransform = exports.walk = void 0;
function walk(routes, pathSelector, fn) {
    const recurse = (innerRoutes, path = []) => {
        for (let route of innerRoutes) {
            fn(route, path);
            const selectedPath = pathSelector(route);
            const nextPath = selectedPath ? path.concat(selectedPath) : path;
            recurse(route.children, nextPath);
        }
    };
    recurse(routes);
}
exports.walk = walk;
// POST-walk. Returns transformed routes as per mapping function.j
function walkTransform(routes, fn) {
    const recurse = (innerRoutes) => innerRoutes.flatMap((route) => {
        route.children = recurse(route.children);
        const transformedRoute = fn(route);
        if (!transformedRoute)
            return [];
        return [transformedRoute];
    }, innerRoutes);
    return recurse(routes);
}
exports.walkTransform = walkTransform;
function buildQueryFromPath(path) {
    let query = {};
    if (!path)
        return query;
    const queryIndex = path.indexOf('?');
    if (queryIndex !== -1) {
        const queryPart = path.slice(queryIndex + 1);
        query = Object.fromEntries(new URLSearchParams(queryPart).entries());
    }
    return query;
}
exports.buildQueryFromPath = buildQueryFromPath;
function buildQueryString(query) {
    if (!query)
        return '';
    if (!Object.keys(query).length)
        return '';
    const cleanedQuery = Object.fromEntries(Object.entries(query).map(([key, value]) => [key, value.toString()]));
    return `?${new URLSearchParams(cleanedQuery)}`;
}
exports.buildQueryString = buildQueryString;
function stripQueryString(path) {
    if (!path)
        return path;
    const queryIndex = path.indexOf('?');
    if (queryIndex === -1)
        return path;
    return path.slice(0, queryIndex);
}
exports.stripQueryString = stripQueryString;
