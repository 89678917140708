import { useStore } from 'vuex';
import store from './store';

export function useJkcms() {
  const localStore = useStore();
  return localStore.state.cms;
}

export function useTranslation() {
  const injectedCms = useJkcms();

  const findDeep = (ob, key, ...args) => {
    const parts = key.split('.');
    let current = ob;

    // eslint-disable-next-line no-restricted-syntax
    for (const part of parts) {
      if (!current?.[part]) {
        current = null;
        break;
      }
      current = current[part];
    }

    let [defaultValue, params] = args;
    if (typeof defaultValue !== 'string') {
      params = defaultValue;
      defaultValue = process.env.NODE_ENV === 'development' ? `[${key}]` : undefined;
    }

    if (typeof current !== 'string') return defaultValue;

    return current.replace(/{{\s*(.*?)\s*}}/g, (_, m) => params[m].toString() || '');
  };

  return (key, ...args) => findDeep(injectedCms.layout?.texts, key, ...args);
}

export async function loadLayout() {
  const { cms } = store.state;

  const response = await fetch(`${cms.base}/${cms.locale}/ajax/layout`, {
    headers: { Accept: 'application/json', View: cms.beta ? 'PREVIEW' : '' },
  });
  const json = await response.json();
  store.commit('cms/setLayout', json);
  return json;
}

export function redirectController() {
  return {
    name: 'redirectController',
    async start(_, { data: { match }, router }) {
      const { cms } = store.state;

      if (match && cms.locales.includes(match.pathParams.locale)) return;
      if (!cms.locale) return;
      const path = window.location.pathname === '/'
        ? window.location.search
        : `${window.location.pathname}${window.location.search}`;

      router.replaceStateByPath(`/${cms.locale}${path}`, {});

      throw new Error('Redirected');
    },
  };
}

export function prerenderController() {
  return {
    name: 'prerenderController',
    async start() {
      // @ts-ignore
      if (window.prerender) {
        // wait end of animations
        setTimeout(() => {
          // store state
          const script = document.createElement('script');
          const { cms } = store.state;
          script.innerHTML = `window.INITAL_ROUTER = ${JSON.stringify(cms)};`;
          document.head.appendChild(script);

          // we're done
          window.document.dispatchEvent(new Event('prerender-trigger'));
        }, 5000);
      }
    },
  };
}
