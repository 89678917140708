/* eslint-disable import/extensions */
import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import VueScrollTo from 'vue-scrollto';
import smoothscroll from 'smoothscroll-polyfill';

import VueJkrouter from 'vue-jkrouter';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createGtm } from '@gtm-support/vue-gtm';
import VueGtag from 'vue-gtag';

import App from '@/App.vue';
import Go from '@/components/utils/Go.vue';
import Image from '@/components/utils/Image.vue';
import Button from '@/components/utils/Button.vue';
import StyledLink from '@/components/utils/StyledLink.vue';
import Part from '@/components/utils/Part.vue';

import store from './store';
import checkView from './utils/vue-check-view';
import { cleanSvgDirective } from './utils/svg';

import 'perfect-scrollbar/css/perfect-scrollbar.css';
import './assets/styles/main.scss';

import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { rememberDirective } from './utils/remember';

import getRoutes from './routes';

// Fontawesome
library.add(faCircleNotch);

// polyfills
// --------------------------------
smoothscroll.polyfill();

// app
// --------------------------------
const app = createApp(App);

const head = createHead();

app.use(VueJkrouter, { routes: getRoutes() });
app.use(head);

app.use(store);
app.use(VueScrollTo);
app.use(checkView, { insideThreshold: () => (window.innerWidth < 960 ? 0.1 : 0.15) });
app.use(createGtm({ id: 'GTM-T7XF2LK', enabled: false }));

if (!window.prerender) {
  app.use(VueGtag, { config: { id: 'G-WG0LW3SHVC' }, enabled: false, bootstrap: !window.dataLayer });
}

window.getPath = () => window.location.pathname.replace(/\/$/, '');
window[atob('amt3ZWI=')] = atob('Tm8sIHRoaXMgaXMgUGF0cmljayE');

// i18n
// --------------------------------

// custom components
// --------------------------------
app.component('Go', Go);
app.component('Button', Button);
app.component('Image', Image);
app.component('StyledLink', StyledLink);
app.component('Part', Part);
app.component('FontAwesomeIcon', FontAwesomeIcon);

app.directive('clean-svgs', cleanSvgDirective);
app.directive('remember', rememberDirective);

app.mount('#app');
if (!window.prerender) setTimeout(() => window.document.body.classList.remove('no-anim'), 100);

if (window.prerender) {
  setTimeout(() => {
    // store state
    // eslint-disable-next-line no-underscore-dangle
    Object.keys(store._actions).forEach((key) => {
      if (key.endsWith('/persist')) store.dispatch(key);
    });
    // notify env
    window.document.dispatchEvent(new Event('prerender-trigger'));
    console.log('done');
  }, 4000);
}
