<template>
  <part is="footer" v-if="general" class="Footer constrain">

    <Go v-if="isSidebar" :to="general.sNFLink" class="logo">
      <LogoDe v-if="language == 'de'" />
      <LogoFr v-else-if="language == 'fr'" />
      <LogoEn v-else />
    </Go>

    <section class="links">
      <Go
        v-for="link in general.links"
        :key="link.id"
        :to="link.internerLink?.[0]?.link ?? link.link"
      >
        {{ link.internerLink?.[0]?.title ?? link.title }}
      </Go>
    </section>

    <section class="social">
      <Go v-if="general.youtube" :to="general.youtube">
        <YoutubeIcon />
      </Go>
      <Go v-if="general.facebook" :to="general.facebook">
        <FacebookIcon />
      </Go>
      <Go v-if="general.twitter" :to="general.twitter">
        <TwitterIcon />
      </Go>
      <Go v-if="general.linkedIn" :to="general.linkedIn">
        <LinkedinIcon />
      </Go>
    </section>

  </part>
</template>

<script setup>
import {
  provide, toRef, computed, unref,
} from 'vue';
import { useRoute } from 'vue-jkrouter';
import { useJkcms } from '../../jkcms';
import LogoDe from '@/assets/images/Logo_NFP73_D_Black.svg';
import LogoEn from '@/assets/images/Logo_NFP73_E_Black.svg';
import LogoFr from '@/assets/images/Logo_NFP73_F_Black.svg';
import YoutubeIcon from '@/assets/images/youtube.svg';
import FacebookIcon from '@/assets/images/facebook.svg';
import TwitterIcon from '@/assets/images/twitter.svg';
import LinkedinIcon from '@/assets/images/linkedin.svg';

const props = defineProps({ sidebar: { type: Boolean, default: false } });
const isSidebar = toRef(props, 'sidebar');

const cms = useJkcms();
const general = computed(() => unref(cms)?.layout?.general);

const match = useRoute();
const language = computed(() => unref(match).parameters.path.locale);

provide('container', (_, { slots }) => slots.default());
</script>

<style lang="scss">
:root {
  --footer-x-padding: #{px(55)};
  --footer-x-padding-mobile: #{px(20)};
  --footer-y-padding: var(--part-padding);
  --footer-y-padding-mobile: var(--part-padding);
}
</style>
<style scoped lang="scss">
.Footer.part {
  display: flex;
  row-gap: px(32);
  background: var(--color-gray-500);
  align-items: center;
  margin-top: auto;

  @include responsive('flex-flow', column, row wrap);
  @include responsive('padding-top', var(--footer-y-padding-mobile), var(--footer-y-padding));
  @include responsive('padding-left', var(--footer-x-padding-mobile), var(--footer-x-padding));
  @include responsive('padding-right', var(--footer-x-padding-mobile), var(--footer-x-padding));
  @include responsive('padding-bottom', var(--footer-y-padding-mobile), var(--footer-y-padding));
}

:deep(.part.highlighted) + .Footer {
  padding-top: 0;
}

.logo {
  width: 14rem;
}

.social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: px(18);

  @include responsive('margin', 0 auto, 0);

  :deep(svg) {
    width: px(22);
    height: px(22);
  }
}

.links {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: px(85);
  font-size: px(11);
  font-weight: $bold;
  line-height: px(20);

  @include responsive('margin', 0, auto px(108) auto auto);
}
</style>
