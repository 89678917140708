<template>
  <template v-for="component of parts" :key="component.data.id">
    <component
      :is="partComponents[component.component]"
      v-if="partComponents[component.component]"
      :id="component.data.slug"
      :payload="component.data"
      class="fade-item"
    />
  </template>
</template>

<script setup>
import {
  inject, computed, defineAsyncComponent, provide,
} from 'vue';

const defaultContainer = (_, { slots }) => <div class="constrain">{slots.default()}</div>;
const Container = inject('container', defaultContainer);
provide('container', Container);

const Filter = defineAsyncComponent(() => import('@/components/parts/Filter.vue'));
const Grid = defineAsyncComponent(() => import('@/components/parts/Grid.vue'));
const Header = defineAsyncComponent(() => import('@/components/parts/Header.vue'));
const MediaTeaser = defineAsyncComponent(() => import('@/components/parts/MediaTeaser.vue'));
const Notfound = defineAsyncComponent(() => import('@/components/structure/NotFound.vue'));
const Partners = defineAsyncComponent(() => import('@/components/parts/Partners.vue'));
const Personen = defineAsyncComponent(() => import('@/components/parts/Personen.vue'));
const PersonTeaser = defineAsyncComponent(() => import('@/components/parts/PersonTeaser.vue'));
const ProjekteTeaser = defineAsyncComponent(() => import('@/components/parts/ProjekteTeaser.vue'));
const Publikationen = defineAsyncComponent(() => import('@/components/parts/Publikationen.vue'));
const SynthesisSlider = defineAsyncComponent(() => import('@/components/parts/SynthesisSlider.vue'));
const SynthesisTeaser = defineAsyncComponent(() => import('@/components/parts/SynthesisTeaser.vue'));
const Testimonials = defineAsyncComponent(() => import('@/components/parts/Testimonials.vue'));
const Text = defineAsyncComponent(() => import('@/components/parts/Text.vue'));
const TextBild = defineAsyncComponent(() => import('@/components/parts/TextBild.vue'));
const TextZweispaltig = defineAsyncComponent(() => import('@/components/parts/TextZweispaltig.vue'));
const Ziele = defineAsyncComponent(() => import('@/components/parts/Ziele.vue'));

const partComponents = {
  Filter,
  Grid,
  Header,
  MediaTeaser,
  Notfound,
  Partners,
  Personen,
  PersonTeaser,
  ProjekteTeaser,
  Publikationen,
  SynthesisSlider,
  SynthesisTeaser,
  Testimonials,
  Text,
  TextBild,
  TextZweispaltig,
  Ziele,
};

const props = defineProps({ inject: { type: Object, default: Object } });

const parts = computed(() => props.inject?.filter((part) => !part.data?.hidden) || []);
</script>
