<template>
  <Search
    v-model="searchTerm"
    v-model:open="open"
    class="search"
    :class="{ open }"
    @change="search"
  >
    <article class="autocomplete" :class="{ show: !initial }">
      <span v-if="isLoading" class="loading-spinner">
        <font-awesome-icon
          class="m-auto block"
          icon="circle-notch"
          spin
          size="lg"
        />
      </span>
      <span v-else-if="!hasAutocompleteData" class="no-data font-bold">
        {{ t('noResults') }}
      </span>
      <template v-else>

        <AutocompleteSection
          v-if="foundTopics.length"
          :title="t('topics')"
          :link-to-all="getPath('topics')"
        >
          <Go
            v-for="topic in foundTopics"
            :key="topic.id"
            :style="{ color: topic.color }"
            name="topic"
            :parameters="{ uri: topic.slug }"
          >
            <media-icon :media="topic" />
            <span v-html="topic.highlight?.title || strip(topic.title)" />
          </Go>
        </AutocompleteSection>

        <AutocompleteSection
          v-if="foundProjects.length"
          :title="t('projects')"
          :link-to-all="getPath('projects')"
        >
          <Go
            v-for="project in foundProjects"
            :key="project.id"
            :style="{ color: project.color }"
            name="project"
            :parameters="{ uri: project.slug }"
            :title="strip(project.title)"
          >
            <media-icon :media="project" />
            <span v-html="project.highlight?.title || strip(project.title)" />
          </Go>
        </AutocompleteSection>

        <AutocompleteSection
          v-if="foundPublications.length"
          :title="t('publications.self')"
          :link-to-all="getPath('cms-page', { uri: localizedSlug(267, 'publications') })"
        >
          <Go
            v-for="publication in foundPublications"
            :key="publication.id"
            :to="publication.more||publication.route"
            :title="strip(publication.title)"
          >
            <media-icon :media="publication" :color="publication.color" />
            <span v-html="publication.highlight?.title || strip(publication.title)" />
          </Go>
        </AutocompleteSection>

        <AutocompleteSection
          v-if="foundSyntheses.length"
          :title="t('syntheses')"
          :link-to-all="getPath('cms-page', { uri: localizedSlug(267, 'publications') })"
        >
          <Go
            v-for="synthesis in foundSyntheses"
            :key="synthesis.id"
            :to="synthesis.link"
            :title="strip(synthesis.title)"
          >
            <media-icon :media="synthesis" />
            <span v-html="synthesis.highlight?.title || strip(synthesis.title)" />
          </Go>
        </AutocompleteSection>

        <AutocompleteSection
          v-if="foundMedias.length"
          :title="t('media.self')"
          :link-to-all="getPath('media-center')"
        >
          <Go
            v-for="media in foundMedias"
            :key="media.id"
            :to="media.data?.link||media.link"
            :title="strip(media.data?.title || media.title)"
          >
            <media-icon :media="media" :color="media.data?.color||media.color" />
            <span v-html="media.highlight?.title || strip(media.data?.title) || strip(media.title)" />
          </Go>
        </AutocompleteSection>

        <styled-link
          v-if="hasAutocompleteData"
          class="all-link"
          name="search"
          :query="{ term: searchTerm }"
        >
          {{ t('allResults') }}
        </styled-link>

      </template>
    </article>
  </Search>
</template>

<script setup>
import {
  ref, unref, computed, watch,
} from 'vue';
import { useRouter } from 'vue-jkrouter';
import { useDebounce } from '@vueuse/shared';
import { useStore } from 'vuex';
import { useSearchQuery } from '@/cmsApi';
import Search from './Search.vue';
import AutocompleteSection from './AutocompleteSection.vue';
import MediaIcon from '@/components/utils/MediaIcon.vue';
import { useJkcms, useTranslation } from '@/jkcms';
import { useSearchRouting } from '@/hooks/search';
import { strip } from '@/utils/strip';

const t = useTranslation();
const MIN_SEARCH_LENGTH = 2;

const router = useRouter();
const cms = useJkcms();
const store = useStore();

function localizedSlug(pageId, defaultSlug) {
  return store.getters['cms/pageSlug'](pageId) ?? defaultSlug;
}

const getPath = (name, params) => router.nameToPath(name, { ...params, locale: cms.locale });

const {
  term: searchTerm,
  search: originalSearch,
} = useSearchRouting();

const open = ref(false);

const {
  data: searchResults,
  isLoading,
  isInitial,
} = useSearchQuery(useDebounce(searchTerm, 200), { initial: false });

const initial = computed(() => unref(searchTerm).length < MIN_SEARCH_LENGTH || unref(isInitial));

const search = () => {
  open.value = false;
  originalSearch();
};

const useSearchType = (type) => computed(
  () => {
    const hasType = (typeof type === 'string') ? (r) => r.type === type : (r) => type.includes(r.type);
    return unref(searchResults)
      ?.results.filter(hasType)
      .map((topicMatch) => ({ ...topicMatch.data, highlight: topicMatch.highlight }))
      .filter((x) => x.data?.id || x.id)
       ?? [];
  },
);

const foundProjects = useSearchType('project');
const foundTopics = useSearchType('topic');
const foundPublications = useSearchType('publication');
const foundSyntheses = useSearchType('synthesis');
const foundMedias = useSearchType(['media', 'veranstaltungen', 'news', 'photo-gallery', 'page']);

const hasAutocompleteData = computed(
  () => unref(searchTerm).length >= MIN_SEARCH_LENGTH
    && (foundProjects.value.length
      || foundTopics.value.length
      || foundPublications.value.length
      || foundSyntheses.value.length
      || foundMedias.value.length),
);

watch(open, (newOpen) => {
  if (!newOpen) {
    searchTerm.value = '';
  }
});
</script>

<style lang="scss" scoped>

.search {
  color: currentColor;

  &.open {
    color: var(--color-gray-300);
  }
}

.autocomplete {
  @apply rounded-big;

  background: var(--color-gray-200);
  opacity: 0;
  height: 0;
  overflow: hidden;
  z-index: 100;
  box-shadow: 0 3px 90px rgba(0, 0, 0, 0.16);

  transition: opacity var(--transition-fast) ease;

  &.show {
    opacity: 1;
    padding: px(62) px(45) px(30) px(42);
    height: auto;
    max-height: 80vh;
    overflow-y: auto;
  }
}

.media-icon {
  border-radius: px(5);
  height: 1rem;
  width: 1rem;
  flex: 0 0 auto;
  margin-right: px(8);

  :deep(svg) {
    max-width: 50%;
    max-height: 50%;
  }
}

.all-link {
  display: block;
  margin-top: px(40);
}

:deep(em) {
  font-style: inherit;
  text-decoration: underline;
}
</style>
