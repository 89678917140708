<template>
  <article class="content">
    <div v-if="project" class="project">
      <div class="constrain">
        <header class="project-header">
          <h1 v-html="project.title" />
          <div v-if="project.intro" class="mb-12" v-html="project.intro" />
          <Image v-if="sdg?.bild.normal" class="sdg-image" :src="sdg.bild" />
          <action-buttons>
            <BookmarkButton :id="project?.id?.toString()" type="project" role="menuitem" />
          </action-buttons>
        </header>
      </div>

      <Dynamic :inject="project?.parts" />

    </div>
    <Footer class="footer" sidebar />
  </article>
</template>

<script setup>
import { computed, unref } from 'vue';
import entities from '@/entities';
import { hexToRgb } from '@/utils/color';
import Dynamic from '../structure/Dynamic.vue';
import BookmarkButton from '../utils/BookmarkButton.vue';
import ActionButtons from '../utils/ActionButtons.vue';
import Footer from '@/components/structure/Footer.vue';
import { useJkcms } from '@/jkcms';
import { useMeta } from '@/hooks/meta';

defineProps({
  match: { type: Object },
});

const cms = useJkcms();
const project = computed(() => {
  if (Array.isArray(cms.page?.content)) return null;
  return cms.page?.content;
});

const sdgId = computed(() => unref(project)?.sdg);
const sdg = entities.useSdg(sdgId);

useMeta({
  title: computed(() => unref(project)?.title),
  image: computed(() => unref(sdg)?.bild.normal),
  description: computed(() => unref(project)?.intro),
});

</script>

<style lang="scss" scoped>
:global() {
  :root {
    --project-color: black;
  }
}

:deep(.media-list) {
  @screen md {
    --isotope-columns: 2;
  }
}

.project {
  --text-color: v-bind('sdg?.hervorhebungsfarbe');
  --text-color-rgb: v-bind('hexToRgb(sdg?.hervorhebungsfarbe)');
  --project-color: v-bind('sdg?.farbe');
  --project-color-rgb: v-bind('hexToRgb(sdg?.farbe)');
  color: v-bind('sdg?.hervorhebungsfarbe');
}

.project-header {
  position: relative;
  h1 {
    width: 80%;
    @include responsive('margin-bottom', px(35), px(59));
    @include responsive('order', 2, 1);
  }
}

.sdg-image {
  width: px(82);
  height: px(82);
  margin-bottom: px(34);

  @include responsive('order', 1, 2);
}

.further h2 {
  @include underlinedHeading();
}
</style>
