<template>
  <select-slider
    :items="topRightNavigation"
    :selected="selectedRightNav"
    class="slider-nav right-nav"
  >
    <template #default="{ item, targetRef, active }">
      <Go
        v-if="item.type !== 'component'"
        :key="`${item.name}-link`"
        :ref="targetRef"
        :to="item.to"
        :name="item.name"
        :parameters="item.parameters"
        :match-virtual="item.virtual"
        class="link font-bold selectable"
        :class="{ active, 'icon-only': !item.text }"
      >
        <component :is="item.icon" v-if="item.icon" class="icon" />
        {{ item.text }}
      </Go>
      <component
        :is="item.component"
        v-else
        :ref="item.indicator !== false ? targetRef : null"
        class="component"
        :class="{ active }"
      />
    </template>
  </select-slider>
</template>
<script setup>
import {
  computed, markRaw, unref,
} from 'vue';
import { useRoute } from 'vue-jkrouter';
import { useStore } from 'vuex';
import PageIcon from '@/assets/images/page.svg';
import PlayIcon from '@/assets/images/play.svg';
import BookmarkIcon from '@/assets/images/bookmark.svg';
import Language from '../utils/Language.vue';
import SearchBar from '../utils/Autocomplete.vue';
import SelectSlider from '../utils/SelectSlider.vue';
import GraphNavigation from '../utils/GraphNavigation.vue';
import { useJkcms, useTranslation } from '@/jkcms';

const cms = useJkcms();
const t = useTranslation();
const match = useRoute();
const store = useStore();
const isFilterOpen = computed(() => store.getters['filter/isOpen']);

const cmsPages = [267];

const pages = computed(() => cmsPages.flatMap((page) => cms?.layout?.navigation[page] ?? []));

const topRightNavigation = computed(() => [
  ...(unref(match).namePath.includes('graph')
    ? [
      {
        type: 'component',
        indicator: false,
        component: markRaw(GraphNavigation),
      },
    ]
    : []),
  {
    type: 'component',
    component: markRaw(SearchBar),
  },
  {
    name: 'bookmarks',
    icon: markRaw(BookmarkIcon),
    virtual: true,
  },
  ...unref(pages).map((link) => ({
    to: link.uri,
    text: link.title,
    icon: markRaw(PageIcon),
  })),
  {
    name: 'media-center',
    text: t('media.news'),
    icon: markRaw(PlayIcon),
  },
  {
    type: 'component',
    indicator: false,
    component: markRaw(Language),
  },
]);

const selectedRightNav = computed(
  () => unref(match)?.data.name
    && unref(topRightNavigation)
      .filter((navEl) => navEl.indicator !== false)
      .findIndex(
        (navEl) => unref(match)?.namePath.includes(navEl.name)
          || (navEl.to && unref(match)?.path === navEl.to)
          || unref(match).virtualMatch?.namePath.includes(navEl.name)
          || (navEl.to && unref(match).virtualMatch?.path === navEl.to),
      ),
);
</script>

<style lang="scss" scoped>
nav .slider-nav.right-nav {
  --bg-color: v-bind("isFilterOpen ? 'transparent' : 'var(--color-gray-400)'");
  --text-color: v-bind("isFilterOpen ? 'white' : 'var(--color-gray-300)'");
  --text-color-active: v-bind("isFilterOpen ? 'var(--color-red)' : 'white'");
  --indicator-background-color: v-bind("isFilterOpen ? 'white' : 'var(--color-gray-300)'");
  background: var(--bg-color);
  color: var(--text-color);
  width: fit-content;
  align-self: center;

  @include responsive('margin-right', px(37), px(50));

  :deep(.indicator) {
    background: var(--indicator-background-color);
  }

  > .link {
    padding: px(8) px(14);
  }

  > .active {
    color: var(--text-color-active)!important;
  }

  @screen lg {
    :deep(.graph-navigation) {
      display: none;
    }
  }
}
</style>
