import { clamp } from '@vueuse/shared';
import * as d3 from 'd3';
import { ZoomTransform } from 'd3';
import { hierarchy } from 'd3-hierarchy';
import { calculateBoundingBox } from './boundingBox';
import cluster from './circleCluster';

const STAGGER_OFFSET = 300;
const STAGGER_SPEED = 500;
const LEVEL_TRANSITION_SPEED = 300;

function findAncestor(node, fn) {
  return node.ancestors().find(fn);
}

function firstAncestorValue(node, fn) {
  const ancestor = findAncestor(node, fn);
  return ancestor ? fn(ancestor) : null;
}

function textAnchor(node) {
  if (node.x > 0) {
    return 'left';
  }
  if (node.x < 0) {
    return 'right';
  }
  if (node.y < 0) {
    return 'left';
  }
  return 'right';
}

function halfBetween(from, to) {
  if (typeof to !== 'number' && !to) return from;
  if (typeof from !== 'number' && !from) return to;
  return (to - from) / 2 + from;
}

function halfBetweenCoordinates(from, to) {
  return [halfBetween(from?.x, to?.x), halfBetween(from?.y, to?.y)];
}

function transitionStagger(selection, to) {
  return selection
    .transition()
    .duration(STAGGER_SPEED)
    .ease(d3.easeSinOut)
    .delay((d, i) => {
      if (d.depth) return d.depth * STAGGER_OFFSET;
      if (d.target?.depth) return d.target.depth * STAGGER_OFFSET;
      return i * STAGGER_OFFSET;
    })
    .call(to);
}

function transitionLevel(selection, to) {
  return selection.transition().duration(LEVEL_TRANSITION_SPEED).call(to);
}

function drawLevelCircles(graph, levelSeparations, levelHighlights) {
  // Draw level circles
  return graph
    .selectAll('g.circle')
    .data(levelSeparations, (_, i) => i)
    .join(
      (enter) => enter
        .append('g')
        .attr('class', 'circle')
        .append('circle')
        .attr('class', 'level-circle')
        .style('fill', 'rgba(0, 0, 0, 0)')
        .style('stroke', (_, i) => (levelHighlights.includes(i) ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.2)'))
        .style('stroke-width', (_, i) => (levelHighlights.includes(i) ? 2 : 1))
        .attr('r', (d, i) => halfBetween(levelSeparations[i - 1], d) || 0)
        .attr('class', 'level-circle hidden')
        .call(transitionStagger, (sel) => sel.attr('r', (d) => d).attr('class', 'level-circle')),
      (update) => update
        .select('circle.level-circle')
        .attr('class', 'level-circle')
        .call(transitionLevel, (sel) => sel
          .style('stroke', (_, i) => (levelHighlights.includes(i) ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.2)'))
          .style('stroke-width', (_, i) => (levelHighlights.includes(i) ? 2 : 1))
          .attr('r', (d) => d)),
      (exit) => exit.remove(),
    );
}

function drawPaths(graph, data) {
  let group = graph.select('g.path-group');

  if (!group.node()) {
    group = graph.append('g').attr('class', 'path-group');
  }

  const links = data.links().filter((link) => link.source !== data);

  const halfStepRadius = (d) => {
    const { parent } = d;
    if (!parent) return d;
    return halfBetween(parent.radius, d.radius);
  };

  return (
    group
      .attr('fill', 'none')
      .attr('stroke', 'black')
      .attr('stroke-opacity', 0)
      .attr('stroke-width', '1')
      .selectAll('path')
      // Don't make links from root
      .data(links, (d) => `${d.source.data.id}#${d.target.data.id}`)
      .join(
        (enter) => enter
          .append('path')
          .attr('class', 'path hidden')
          .attr(
            'd',
            d3
              .linkRadial()
              .angle((d) => Math.PI - d.angle)
              .radius(halfStepRadius),
          )
          .attr('stroke', (d) => (d.target.data.highlighted
            ? firstAncestorValue(d.target, (ancestor) => ancestor?.data?.colorHighlight)
            : 'black'))
          .attr('stroke-width', (d) => (d.target.data.highlighted ? 2 : 1))
          .call(transitionStagger, (sel) => sel
            .attr('stroke-opacity', (d) => (d.target.data.highlighted ? 1 : 0.1))
            .attr(
              'd',
              d3
                .linkRadial()
                .angle((d) => Math.PI - d.angle)
                .radius((d) => d.radius),
            )
            .attr('class', 'path')),
        (update) => update.call(transitionLevel, (sel) => sel
          .attr(
            'd',
            d3
              .linkRadial()
              .angle((d) => Math.PI - d.angle)
              .radius((d) => d.radius),
          )
          .attr('stroke', (d) => (d.target.data.highlighted
            ? firstAncestorValue(d.target, (ancestor) => ancestor?.data?.colorHighlight)
            : 'black'))
          .attr('stroke-width', (d) => (d.target.data.highlighted ? 2 : 1))
          .attr('stroke-opacity', (d) => (d.target.data.highlighted ? 1 : 0.1))
          .attr('class', 'path')),
        (exit) => exit.remove(),
      )
  );
}

function addText(selection, texts) {
  const textClass = (d) => `node-text text-${d.data.id} anchor-${textAnchor(d)}`;

  selection.each((d) => {
    d3.select(texts)
      .append('div')
      .attr('class', textClass(d))
      .style('opacity', 0)
      .style('--text-x', `${halfBetween(d.parent?.x, d.x)}px`)
      .style('--text-y', `${halfBetween(d.parent?.y, d.y)}px`)
      .style(
        'color',
        firstAncestorValue(d, (ancestor) => ancestor?.data?.colorHighlight),
      )
      .html(d.data.title)
      .datum(d)
      .call(transitionStagger, (sel) => sel
        .style('--text-x', `${d.x}px`)
        .style('--text-y', `${d.y}px`)
        .style('opacity', function () {
          return this.classList.contains('in') ? 1 : null;
        }));
  });
}

function removeText(selection, texts) {
  selection.each((d) => {
    d3.select(texts)
      .selectAll(`.node-text.text-${d.data.id}`)
      .call((sel) => {
        if (!sel.node()) return;

        sel.node().addEventListener('transitionend', () => {
          sel.remove();
        });

        if (!sel.node().classList.contains('in')) {
          sel.remove();
        } else {
          sel.node().classList.remove('in');
        }
      });
  });
}

function updateText(selection, texts) {
  selection.each((d) => {
    texts
      .select(`.node-text.text-${d.data.id}`)
      .html(d.data.title)
      .call(transitionLevel, (sel) => sel
        .style('--text-x', `${d.x}px`).style('--text-y', `${d.y}px`).style('opacity', null));
  });
}

function activateText(selection, texts) {
  selection
    .select(function () {
      return this.closest('g');
    })
    // .filter((d) => !d.data.preventTextHighlight)
    .select((d) => texts.select(`.node-text.text-${d.data.id}`).node())
    .call((sel) => sel.node()?.classList.add('in'));
}

function deactivateText(selection, texts) {
  selection
    .select(function () {
      return this.closest('g');
    })
    .select((d) => texts.select(`.node-text.text-${d.data.id}`).node())
    .call((sel) => sel.node()?.classList.remove('in'));
}

function activateNode(selection, texts) {
  selection
    .selectAll(function () {
      return this.parentNode.childNodes;
    })
    .each(function () {
      this.classList.add('active');
    });

  activateText(selection, texts);
}

function deactivateNode(selection, texts) {
  selection
    .selectAll(function () {
      return this.parentNode.childNodes;
    })
    .each(function () {
      this.classList.remove('active');
    });

  deactivateText(selection, texts);
}

function updateNode(selection, texts) {
  selection
    .select(function () {
      return this.closest('g');
    })
    .call(updateText, texts);
}

function drawNodeCircle(node, classes, circleFn) {
  return node
    .append('circle')
    .style('--node-color', (d) => firstAncestorValue(d, (ancestor) => ancestor?.data?.color))
    .style('--node-color-highlight', (d) => firstAncestorValue(d, (ancestor) => ancestor?.data?.colorHighlight))
    .attr('class', classes)
    .attr('r', (d) => d.nodeSize)
    .call(circleFn ?? (() => {}));
}

function updateNodeCircle(node, textNode) {
  node.selectAll('circle').attr('r', (d) => d.nodeSize);

  node.select('circle').each(function () {
    updateNode(d3.select(this), d3.select(textNode));
  });

  node.select('circle.main').each(function (d) {
    if (d.data.focus) {
      this.classList.add('focused');
    } else {
      this.classList.remove('focused');
    }
  });

  return node;
}

function updateFocusCircle(selection) {
  const focused = selection.filter((d) => d.data.focus);
  const unFocused = selection.filter((d) => !d.data.focus);

  focused
    .selectAll('circle.focus')
    .attr('stroke', 'var(--color-red)')
    .attr('stroke-width', 4)
    .attr('fill', 'transparent')
    .attr('r', (d) => d.nodeSize * 1.3)
    .style('opacity', 1);

  unFocused.selectAll('circle.focus').style('opacity', 0);
}

function drawFocusCircle(selection) {
  selection.append('circle').attr('class', 'focus');
  selection.call(updateFocusCircle);
}

function drawNodes(graph, data, textNode, levelHighlights, hasHiglight) {
  const descendants = data.descendants();
  const nodes = graph.selectAll('a.node-link').data(descendants, (d) => d.data.id);

  const attachHandlers = (selection) => {
    selection
      .on('mouseover', null)
      .on('mouseover', function (_, d) {
        const focused = graph
          .selectAll('a.node-link')
          // select all highlighted children of the parent node expect however target itself
          .filter(
            (n) => n.depth > 2
              && n.data.highlighted
              && n.parent.data.id === d.parent?.data.id
              && n.data.id !== d.data.id,
          );
        if (focused.size()) {
          deactivateText(focused, d3.select(textNode));
        }

        if (!d.data.highlighted) {
          activateNode(d3.select(this), d3.select(textNode));
        } else if (d.data.preventTextHighlight) {
          activateText(d3.select(this), d3.select(textNode));
        }
      })
      .on('mouseout', null)
      .on('mouseout', function (_, d) {
        const focused = graph
          .selectAll('a.node-link')
          .filter((n) => n.data.highlighted && !n.data.preventTextHighlight);
        if (focused.size()) {
          activateText(focused, d3.select(textNode));
        }

        if (!d.data.highlighted) {
          deactivateNode(d3.select(this), d3.select(textNode));
        } else if (d.data.preventTextHighlight) {
          deactivateText(d3.select(this), d3.select(textNode));
        }
      });
  };

  const updateMainCircle = (selection) => {
    selection
      .each(function (d) {
        if (d.data.highlighted || d.data.focus) {
          activateNode(d3.select(this), d3.select(textNode));
        } else {
          deactivateNode(d3.select(this), d3.select(textNode));
        }
        if (d.data.preventTextHighlight && !d.data.focus) {
          deactivateText(d3.select(this), d3.select(textNode));
        }
      })
      // We use the colorHighlight for the state when no level is highlighted
      .style('--node-color', (d) => (!levelHighlights.length && !hasHiglight
        ? firstAncestorValue(d, (ancestor) => ancestor?.data?.colorHighlight)
        : firstAncestorValue(d, (ancestor) => ancestor?.data?.color)))
      .call(attachHandlers);
  };

  nodes.join(
    (enter) => enter
      .append('a')
      .attr('class', 'node-link')
      .attr('href', (d) => d.data.link)
      .append('g')
      .attr('class', 'node')
      .attr('transform', (d) => {
        const [x, y] = halfBetweenCoordinates(d.parent, d);
        return `translate(${x},${y})`;
      })
      .call(addText, textNode)
      .call(drawNodeCircle, 'node-circle circle-highlight highlight-2')
      .call(drawNodeCircle, 'node-circle circle-highlight highlight-1')
      .call(
        drawNodeCircle,
        (d) => `node-circle main ${d.data.focus && 'focused'}`,
        updateMainCircle,
      )
      .call(drawFocusCircle)
      .attr('class', 'node hidden')
      .call(transitionStagger, (sel) => sel.attr('transform', (d) => `translate(${d.x},${d.y})`).attr('class', 'node')),

    (update) => {
      const group = update.select('g.node');
      update.attr('href', (d) => d.data.link);
      group
        .call(updateNodeCircle, textNode)
        .call(updateFocusCircle)
        .call(transitionLevel, (sel) => sel.attr('class', 'node').attr('transform', (d) => `translate(${d.x},${d.y})`));

      group.select('.node-circle.main').call(updateMainCircle);
      return update;
    },
    (exit) => exit.call(removeText, textNode).remove(),
  );

  return graph.selectAll('g.node').data(data.descendants(), (d) => d.data.id);
}

function filterNodes(nodes, filter = () => true) {
  const targetNodes = [];

  nodes.each((node) => {
    if (filter(node)) {
      targetNodes.push(node);
    }
  });

  return targetNodes;
}

export function getTransform(clusterData, availableWidth, availableHeight, reduced) {
  const nodes = filterNodes(clusterData, (node) => node.data.focus || node.data.highlighted);

  const bb = calculateBoundingBox(nodes);
  const bbWidth = bb.bottomRight.x - bb.topLeft.x;
  const bbHeight = bb.bottomRight.y - bb.topLeft.y;

  const scaleX = availableWidth < bbWidth ? availableWidth / bbWidth : 1;
  const scaleY = availableHeight < bbHeight ? availableHeight / bbHeight : 1;
  let scale = Math.abs(availableWidth - bbWidth) < 50 || Math.abs(availableHeight - bbHeight) < 50
    ? 0.95
    : Math.min(scaleX, scaleY);
  let translateX = -(bb.bottomRight.x + bb.topLeft.x) / 2;
  const translateY = -(bb.bottomRight.y + bb.topLeft.y) / 2;

  // add offset for label
  const magicOffset = 90;
  if (nodes.length === 1 && nodes[0].depth) { translateX += translateX < 0 ? -magicOffset : magicOffset; }

  scale = scale !== 1 ? scale - 0.2 : scale;
  if (nodes.length > 1 && reduced) scale *= 0.75;
  scale = clamp(scale, 0.33, 3);

  return {
    scale,
    translateX,
    translateY,
  };
}

export function hasHighlight(data) {
  let highlighted = false;

  data.each((node) => {
    if (node.data.highlighted) highlighted = true;
  });

  return highlighted;
}

export function getNodeData({
  data,
  nodeSize,
  levelSeparations,
  levelHighlights,
  levelHighlightTextMax = 2,
}) {
  const hierarchyData = hierarchy(data);
  const createCluster = cluster({
    clusterSeparationMultiplier: 2,
    levelSeparation: (d) => levelSeparations[d.depth],
  });
  const clusterData = createCluster(hierarchyData);

  // Highlight node always if the level is highlighted
  clusterData.each((node) => {
    // eslint-disable-next-line no-param-reassign
    node.nodeSize = nodeSize;

    if (levelHighlights.includes(node.depth)) {
      // eslint-disable-next-line no-param-reassign
      node.data.highlighted = true;
      if (node.depth > levelHighlightTextMax) {
        // eslint-disable-next-line no-param-reassign
        node.data.preventTextHighlight = true;
      }
    }
  });

  return clusterData;
}

function updateZoom(zoom, hasReducedWidth, width, height, svg, graph, textNode, transform) {
  const el = graph;
  const targetElements = [el, textNode];

  if (zoom) {
    zoom.on('zoom', null);
    zoom.transform(svg, d3.zoomIdentity);
  }

  function setTransform(zoomTransform, ...elements) {
    const widthAdjustment = hasReducedWidth ? -width : 0;
    const transformString = ''
      + ` translate(${zoomTransform.translateX}, ${zoomTransform.translateY})`
      + ` translate(${widthAdjustment}px, 0)`
      + ` scale(${zoomTransform.scale})`;

    elements.forEach((element) => {
      element.style('transform', transformString);
      element.style('transform-origin', 'center');
    });

    d3.select('.graph-container').style('--zoom-scale', zoomTransform.scale);
  }

  function handleZoom(e) {
    const targetTransform = {
      scale: e.transform.k,
      translateX: `${e.transform.x - width / 2}px`,
      translateY: `${e.transform.y - height / 2}px`,
    };
    setTransform(targetTransform, el, textNode);
  }

  const target = new ZoomTransform(
    transform.scale,
    transform.translateX + width / 2,
    transform.translateY + height / 2,
  );

  const zoomInstance = zoom ?? d3.zoom().scaleExtent([0.33, 3]).clickDistance(10);

  zoomInstance
    .extent([
      [0, 0],
      [width, height],
    ])
    // eslint-disable-next-line no-shadow
    .constrain((transform) => {
      const box = document.getElementsByClassName('animation-root')[0].getBoundingClientRect();
      const translateExtent = [
        [Math.min(0, box.left / 2.5), box.top / 3],
        [Math.max(box.width, width), Math.max(box.height, height)],
      ];

      const dx0 = (transform.x - translateExtent[0][0]) / transform.k;
      const dx1 = (transform.x - translateExtent[1][0]) / transform.k;
      const dy0 = (transform.y - translateExtent[0][1]) / transform.k;
      const dy1 = (transform.y - translateExtent[1][1]) / transform.k;

      return transform.translate(
        (dx1 > dx0 ? (dx0 + dx1) / 2 : Math.min(0, dx0) || Math.max(0, dx1)) * -1,
        (dy1 > dy0 ? (dy0 + dy1) / 2 : Math.min(0, dy0) || Math.max(0, dy1)) * -1,
      );
    })
    .translateExtent([
      [0, 0],
      [width, height],
    ]);

  svg.call(zoomInstance.on('zoom', handleZoom));

  zoomInstance.transform(svg, target);

  zoomInstance.on('start', () => {
    targetElements.forEach((element) => element.style('transition', 'none'));
  });

  zoomInstance.on('end', () => {
    targetElements.forEach((element) => element.style('transition', null));
  });

  return zoomInstance;
}

export default function drawGraph({
  data,
  svgNode,
  textNode,
  levelSeparations,
  levelHighlights,
  transform,
  hasReducedWidth,
  width,
  height,
  zoom,
}) {
  // Graph is a root 'g' element centered on the page
  const root = d3.select(svgNode).select('g.root');
  const graph = root.node()
    ? root
    : d3
      .select(svgNode)
    // Header spacer for pushing down the contents of the
    // svg by the height of the header. See CSS in Graph.vue
      .append('g')
      .attr('class', 'header-spacer')
    // Root element for zoom-transforms
      .append('g')
      .attr('class', 'animation-root')
    // Actual, centered, root element
      .append('g')
      .attr('class', 'root');

  // Center svg content
  const { width: svgWidth, height: svgHeight } = svgNode.getBoundingClientRect();
  graph.attr('transform', `translate(${svgWidth / 2}, ${svgHeight / 2})`);

  const container = d3.select(svgNode).select('g.animation-root');

  drawPaths(graph, data);
  drawLevelCircles(graph, levelSeparations, levelHighlights);
  drawNodes(graph, data, textNode, levelHighlights, hasHighlight(data));

  return {
    zoom: updateZoom(
      zoom,
      hasReducedWidth,
      width,
      height,
      d3.select(svgNode),
      container,
      d3.select(textNode.parentNode),
      transform,
    ),
  };
}
