module.exports = {
  purge: {
    content: [
      './public/**/*.html',
      './src/**/*.vue',
    ],
    options: {
      safelist: [
        // 'bg-red',
      ],
    },
  },

  theme: {

    colors: {
      black: { DEFAULT: 'var(--color-black)' },
      white: { DEFAULT: 'var(--color-white)' },

      gray: {
        100: 'var(--color-gray-100)',
        200: 'var(--color-gray-200)',
        300: 'var(--color-gray-300)',
        400: 'var(--color-gray-400)',
        500: 'var(--color-gray-500)',
      },

      red: { DEFAULT: 'var(--color-red)' },
      green: {
        DEFAULT: 'var(--color-green)',
        100: 'var(--color-green-100)',
      },

      orange: {
        100: 'var(--color-orange-100)',
        200: 'var(--color-orange-200)',
        300: 'var(--color-orange-300)',
      },

    },

    extend: {
      borderRadius: {
        small: '0.625rem',
        big: '1.25rem',
        huge: '50px',
      },
    },

    fontFamily: {
      sans: ['Open Sans', 'sans-serif'],
    },

    fontSize: {
      small: '0.8125rem',
      normal: '0.9375rem',
      h1: '2.1875rem',
      h3: '1.25rem',
    },

    screens: {
      sm: '640px',
      md: '920px',
      lg: '1500px',
      xl: '1900px',
      xxl: '2200px',
    },

  },

};
