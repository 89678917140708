<template>
  <div class="toolbar">
    <Multiselect
      v-model="selected.type"
      :placeholder="t('media.self')"
      :options="mediaTypeOptions"
      :close-on-select="true"
      mode="single"
    />
    <Multiselect
      v-model="selected.projects"
      :placeholder="t('projects')"
      :options="projectOptions"
    />
    <Multiselect v-model="selected.topics" :placeholder="t('topics')" :options="topicOptions" />
    <Multiselect v-model="selected.sdgs" :placeholder="t('sdgs')" :options="sdgOptions" />
    <Multiselect
      v-model="selected.institutions"
      :placeholder="t('institution')"
      :options="institutionOptions"
    />
  </div>
</template>

<script setup>
import {
  toRef, unref, computed, watch,
} from 'vue';
import { useEntityOptions, useFilters } from '@/hooks/filter';
import { entityIdSelector } from '@/utils/entities/selection';
import { useTranslation } from '@/jkcms';
import { useProjectsQuery, useSdgsQuery, useTopicsQuery } from '@/cmsApi';
import Multiselect from '@/components/utils/Multiselect.vue';

const props = defineProps({
  entities: { type: Array, default: () => [] },
});

const emits = defineEmits(['change']);

const t = useTranslation();

const { selected, shouldFilter } = useFilters({
  type: (entity, value) => (unref(entity.media)?.[0]?.type.split('/')[0] ?? 'link') === value,
  projects: (entity, id) => (Array.isArray(entity.projects) ? entity.projects.includes(id) : entity.project === id)
    || entity.id === id,
  topics: (entity, id) => (Array.isArray(entity.topics) ? entity.topics.includes(id) : entity.topic === id)
    || entity.id === id,
  sdgs: (entity, id) => (Array.isArray(entity.sdgs) ? entity.sdgs.includes(id) : entity.sdg === id) || entity.id === id,
  institutions: (entity, id) => (Array.isArray(entity.institutions)
    ? entity.institutions.find((institution) => institution.id === id)
    : entity.institution === id) || entity.id === id,
});

watch(
  shouldFilter,
  (nextFilter) => {
    emits('change', nextFilter);
  },
  { immediate: true },
);

const { data: projects } = useProjectsQuery();
const { data: topics } = useTopicsQuery();
const { data: sdgs } = useSdgsQuery();

const mediaTypeOptions = computed(() => [
  {
    label: t('allVideos'),
    value: 'video',
  },
  {
    label: t('allPodcasts'),
    value: 'audio',
  },
  {
    label: t('allLinks'),
    value: 'link',
  },
]);
const entities = toRef(props, 'entities');
const institutions = computed(() => unref(entities).flatMap((entity) => entity.institutions ?? []));

const projectOptions = useEntityOptions(
  entities,
  projects,
  entityIdSelector('project', 'projects'),
);
const topicOptions = useEntityOptions(entities, topics, entityIdSelector('topic', 'topics'));
const sdgOptions = useEntityOptions(entities, sdgs, entityIdSelector('sdg', 'sdgs'));
const institutionOptions = useEntityOptions(
  institutions,
  institutions,
  entityIdSelector('institution', 'institutions'),
);
</script>

<style lang="scss" scoped></style>
