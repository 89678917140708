<template>
  <Go
    :to="media?.link"
    class="media-box z-0 relative"
    :class="{ 'has-link': !!media?.link }"
  >

    <template v-if="!props.podcast">
      <Box class="cover" :aspect="0.56">
        <media-icon
          class="media-icon"
          :media="media"
          :color="colored ? data.color : ''"
        />
        <div class="media-content">
          <Image v-if="data.bild?.id" :src="data.bild" />
        </div>
      </Box>

      <div>
        <span class="meta float-right pl-4">
          {{ metaInfo }}
        </span>
        <span class="title">
          {{ data.title }}
        </span>
      </div>
    </template>

    <template v-else>
      <div
        class="audio-box"
        target="_blank"
        @click.stop="startAudio"
        @keydown.stop="$event.key === 'Enter' && startAudio($event)"
      >
        <div class="squared-icon audio-icon">
          <PlayIcon />
        </div>
        <div class="info-box">
          <div class="meta">
            <span v-if="data.episode?.length">
              {{ data.episode }}
            </span>
            <span v-if="metaInfo">
              {{ metaInfo }}
            </span>
          </div>
          <span class="title">
            {{ data.title }}
          </span>
        </div>
      </div>
    </template>

  </Go>
</template>

<script setup>
import {
  toRef, ref, computed, unref, watch,
} from 'vue';
import { format, isValid } from 'date-fns';
import Go from '@/components/utils/Go.vue';
import Box from '@/components/utils/Box.vue';
import MediaIcon from '@/components/utils/MediaIcon.vue';
import PlayIcon from '@/assets/images/media-play.svg';

const props = defineProps({
  media: { type: Object, default: () => ({}) },
  faded: { type: Boolean, default: false },
  cover: { type: Boolean, default: false },
  audioInVideo: { type: Boolean, default: false },
  iconTopLeft: { type: Boolean, default: false },
  link: { type: Boolean, default: false },
  colored: { type: Boolean, default: false },
  podcast: { type: Boolean, default: false },
});

const videoElement = ref(null);
const audioElement = ref(null);

const faded = toRef(props, 'faded');
const cover = toRef(props, 'cover');
const colored = toRef(props, 'colored');
const data = toRef(props, 'media');
// const mediaFile = computed(() => unref(data).media[0]);
const color = computed(() => (unref(colored) ? unref(data).color : 'currentColor'));
// const majorMimeType = computed(() => unref(mediaFile)?.type.split('/')[0]);
const metaInfo = ref('');
const isMediaPlaying = ref(false);

const updateDuration = (duration) => {
  const minutes = Math.trunc(duration / 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.trunc(duration % 60)
    .toString()
    .padStart(2, '0');
  metaInfo.value = `${minutes}:${seconds}`;
};

watch([videoElement, audioElement], ([videoEl, audioEl]) => {
  if (videoEl?.duration) updateDuration(videoEl.duration);
  if (audioEl?.duration) updateDuration(audioEl.duration);
});

watch(
  data,
  (changedData) => {
    const lastChanged = new Date(changedData.datum || changedData.start);
    metaInfo.value = isValid(lastChanged) ? format(lastChanged, 'dd.MM.yyyy') : '';
  },
  { immediate: true },
);

const startAudio = async () => {
  if (unref(cover)) return;
  if (props.audioInVideo) return;

  if (!unref(isMediaPlaying)) {
    audioElement.value.play();
    isMediaPlaying.value = true;
  } else {
    isMediaPlaying.value = false;
    audioElement.value.pause();
  }
};

/*
const startVideo = async (toggle = true) => {
  if (unref(cover)) return;

  const audioInVideo = props.audioInVideo && majorMimeType.value === 'audio';
  if (!unref(isMediaPlaying)) {
    videoElement.value.controls = true;
    isMediaPlaying.value = true;
    if (videoElement.value.paused || videoElement.value.ended) {
      videoElement.value.play();
    }
  } else if (toggle) {
    videoElement.value.controls = false;
    isMediaPlaying.value = false;
    if (!videoElement.value.paused) {
      videoElement.value.pause();
    }
    if (audioInVideo) {
      videoElement.value.controls = false;
    }
  }
};
*/
</script>

<style scoped lang="scss">
.media-box {
  --media-color: v-bind('color');

  display: block;
  opacity: v-bind('faded ? 0.3 : 1');

  &.has-link > * {
    pointer-events: none;
  }

  .cover {
    margin-bottom: px(18);
    position: relative;

    .media-content {
      @apply rounded-big bg-gray-200;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;

      & > .video {
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
    }

    audio, video, img {
      object-fit: cover;
    }
    audio, video {
      cursor: pointer;
    }

    .media-icon {
      position: absolute;
      top: v-bind('cover || iconTopLeft ? "0" : "50%"');
      left: v-bind('cover || iconTopLeft ? "0" : "50%"');
      transform: v-bind('cover || iconTopLeft ? "none" : "translate(-50%, -50%)"');
    }
  }

  .title,
  .meta {
    @apply text-small;

    display: block;
    font-weight: $bold;
    line-height: px(18);
    color: var(--media-color);
  }

  .meta {
    font-weight: $regular;
  }
}

.audio-player {
  height: 0;
  opacity: 0;
  transition:
    height var(--transition-fast) ease,
    opacity var(--transition-fast) ease;

  &.playing {
    opacity: 1;
    height: px(48);
  }

  audio {
    height: px(48);
    width: 100%;
    padding-top: px(16);
  }
}

.audio-box {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: currentColor;

    .audio-icon {
      transform: scale(1.1);
    }
  }

  .audio-icon {
    z-index: 10;
    flex-grow: 0;
    flex-shrink: 0;
    background: black;
    width: px(58);
    height: px(58);
    transition: transform var(--transition-fast) ease;

    svg {
      max-width: px(16);
      max-height: px(16);
    }
  }

  .info-box {
    @apply font-normal;
    @apply rounded-small;

    display: flex;
    column-gap: px(30);
    align-items: center;
    margin-left: px(-16);

    padding: px(20) px(30) px(20) px(38);
    background: var(--color-white);
    font-weight: $regular;
    z-index: 0;

    flex-flow: column;

    .meta {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: px(16);
      order: 2;
    }

    .meta span {
      font-weight: $bold;
      display: block;
      white-space: nowrap;
    }

    .title {
      @apply font-normal;
      font-weight: $regular;
      order: 1;
    }

    @screen sm {
      flex-flow: row;

      .title {
        order: 2;
      }

      .meta {
        display: block;
        order: 1;
        margin-top: 0;
        width: auto;
      }
    }
  }
}

.audio-poster {
  @apply absolute w-full h-full;

  & + video, & + audio {
    width: fit-content;
    height: px(30);
    margin-bottom: px(15);
    flex-basis: calc(100% - #{px(30)});
  }
}
</style>
