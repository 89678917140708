<template>

  <Graph
    v-if="graph"
    :data="graph"
    :highlighted-level="highlightedLevel"
    :has-side-content="showSideContent"
  />

  <ContentOverlay :model-value="showSideContent" @update:model-value="sideContentOpenChanged">
    <transition name="fade-slow" mode="out-in" appear>
      <route
        key="graph"
        name="graph"
        :filter="(m) => Object.keys(routes).includes(m.data.name)"
        :component="routes[props.match.data.name]"
      />
    </transition>
  </ContentOverlay>

  <Filter />

</template>

<script setup>
import {
  computed, ref, watch, unref,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-jkrouter';
import { useDebounceFn, useEventBus } from '@vueuse/core';
import Projects from '@/components/pages/Projects.vue';
import Sdg from '@/components/pages/Sdg.vue';
import Topic from '@/components/pages/Topic.vue';
import Project from '@/components/pages/Project.vue';
import Graph from '@/components/utils/graph/Graph.vue';
import Filter from '@/components/utils/Filter.vue';
import ContentOverlay from '@/components/utils/ContentOverlay.vue';
import { useGraphQuery } from '../../cmsApi';
import { walk } from '../../utils/graph';
import Program from './Program.vue';

const store = useStore();
const showSideContent = ref(false);

const router = useRouter();
const routeMatch = useRoute();

const props = defineProps({ match: Object });

const routes = {
  projects: Projects,
  sdg: Sdg,
  topic: Topic,
  project: Project,
  program: Program,
};

watch(
  routeMatch,
  (newRouteMatch) => {
    if (newRouteMatch.state?.sidebarOpen !== false) {
      showSideContent.value = !!newRouteMatch?.data?.sideContentOpen;
    }
  },
  { immediate: true },
);

const sideContentOpenChanged = (newValue) => {
  showSideContent.value = newValue;
  if (!routeMatch.value) return;
  const namePath = unref(routeMatch)?.namePath;
  if (!newValue && namePath.length > 3) {
    router.pushState(
      routeMatch.value.namePath.slice(0, 3),
      routeMatch.value.parameters?.path,
      null,
      { sidebarOpen: false },
    );
  }
};

const selectedTags = computed(() => store.state.filter.selectedTags);

const { data: graphData } = useGraphQuery();

const focusedDebounced = ref([]);
const focused = [];
const syncFocus = useDebounceFn(() => {
  focusedDebounced.value.length = 0;
  focusedDebounced.value.push(...focused);
}, 250);

const levelHover = ref(null);
const highlightedLevel = computed(() => {
  if (levelHover.value) return levelHover.value;
  if (focusedDebounced.value.length) return null;
  return props.match.data.name;
});

const graphBus = useEventBus('graph');
graphBus.on((type, d) => {
  if (type === 'hover') focused.push(d.projectId);
  if (type === 'blur') focused.splice(focused.indexOf(d.projectId), 1);
  if (type === 'level') levelHover.value = d;
  syncFocus();
});

const graph = computed(() => walk(graphData.value, (node) => {
  // eslint-disable-next-line no-param-reassign
  const groups = Object.entries(selectedTags.value);
  const nothingSelected = groups.every(([, ids]) => ids.length === 0);
  const isFocused = groups.every(
    ([, ids]) => !ids.length || node.tags.some((tag) => ids.includes(tag.id)),
  );

  let { highlighted, link } = node;

  const currentRouter = unref(router);
  const currentRoute = unref(routeMatch);

  // if nothing is hoverd, show current state
  if (!levelHover.value) {
    const slug = currentRoute.parameters?.path.uri;
    highlighted ||= currentRoute.data?.name === node.type && (slug ? node.slug === slug : true);
  }

  if (focusedDebounced.value.length) highlighted = false;
  if (focusedDebounced.value.includes(node.id * 1)) highlighted = true;

  link = currentRouter.nameToPath(node.type, {
    locale: currentRoute.parameters.path.locale,
    uri: node.slug,
  });

  return {
    ...node,
    link,
    highlighted,
    focus: !nothingSelected && isFocused,
  };
}));
</script>
