<template>
  <div v-if="icon" class="squared-icon media-icon">
    <component :is="icon" />
  </div>
</template>

<script setup>
import { computed, unref, toRef } from 'vue';

import LinkIcon from '@/assets/images/media-link.svg';
import PlayIcon from '@/assets/images/media-play.svg';
import NewsIcon from '@/assets/images/news.svg';
import SearchIcon from '@/assets/images/search.svg';
import EventIcon from '@/assets/images/event.svg';
import DocumentIcon from '@/assets/images/document.svg';
import GraphIcon from '@/assets/images/graph.svg';
import GalleryIcon from '@/assets/images/gallery.svg';

const map = {
  197: GraphIcon,
  267: GraphIcon,
  906: NewsIcon,
  907: PlayIcon,
  908: PlayIcon,
  909: EventIcon,
  910: DocumentIcon,
  1123: DocumentIcon,
  1204: GalleryIcon,
};

const props = defineProps({
  media: { type: Object },
  color: { type: String },
});

const data = toRef(props, 'media');

const icon = computed(() => {
  if (!unref(data)) return SearchIcon;
  if (map[data.value.parent]) return map[data.value.parent];
  if (unref(data)?.link) return LinkIcon;
  return SearchIcon;
});
</script>

<style lang="scss" scoped>
.squared-icon {
  background-color: v-bind("color || 'currentColor'");
  z-index: 10;
  pointer-events: none;
}
</style>
