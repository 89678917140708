<template>
  <section class="cms-page">
    <div class="header-spacer" />
    <div class="constrain">
      <div class="row-12">
        <div class="col-12 md:col-10 md:offset-1">
          <h1>{{ t('bookmarks') }}</h1>
          <FilterBar :entities="allEntities" @change="filterChange" />
          <EntityList
            :projects="projects"
            :topics="topics"
            :publications="publications"
            :media="media"
            :sdgs="sdgs"
            :filter="filter"
          />
          <section class="actions">
            <h2>{{ t('actions') }}</h2>
            <button class="action" @click="copyLink">
              <LinkIcon />
              {{ t('copyLink') }}
            </button>
          </section>
          <div v-if="linkCopied" class="link-copied">
            <span class="message">{{ t('linkCopied') }}</span>
            {{ link }}
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </section>
</template>

<script setup>
import { computed, unref, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-jkrouter';
import { useTranslation } from '@/jkcms';
import LinkIcon from '@/assets/images/link.svg';
import Footer from '@/components/structure/Footer.vue';
import EntityList from '@/components/utils/EntityList.vue';
import FilterBar from '@/components/utils/FilterBar.vue';
import { bookmarksToBase64 } from '@/utils/bookmarks';
import { useMeta } from '@/hooks/meta';

const t = useTranslation();
const store = useStore();

const router = useRouter();
const route = useRoute();

const filter = ref(() => true);
const linkCopied = ref(false);

const bookmarks = computed(() => store.getters['bookmarks/all']);
const buildGetById = (type) => computed(() => store.getters[`${type}/getById`]);
const entitiesByBookmark = (singular, plural) => computed(
  () => unref(bookmarks)[singular]
    ?.map(unref(buildGetById(plural)))
    ?.filter((v) => v) ?? [],
);

const link = computed(() => `${
  window.location.origin
}${
  router.matchToPath(unref(route), { bookmarks: bookmarksToBase64(unref(bookmarks) ?? {}) })
}`);

const copyLink = async () => {
  await navigator.clipboard.writeText(unref(link));
  linkCopied.value = true;
};

const projects = entitiesByBookmark('project', 'projects');
const topics = entitiesByBookmark('topic', 'topics');
const publications = entitiesByBookmark('publication', 'publications');
const media = entitiesByBookmark('media', 'medias');
const sdgs = entitiesByBookmark('sdg', 'sdgs');

const allEntities = computed(() => [
  ...unref(projects),
  ...unref(topics),
  ...unref(publications),
  ...unref(media),
  ...unref(sdgs),
]);

const filterChange = (nextFilter) => {
  filter.value = nextFilter;
};

useMeta({
  title: t('bookmarks'),
});
</script>

<style lang="scss" scoped>
.actions {
  margin-top: px(100);
  margin-bottom: px(100);

  .action {
    display: flex;
    height: px(20);
    gap: px(16);
    font-weight: $bold;
    align-items: center;
    text-align: left;
    cursor: pointer;

    :deep(svg) {
      height: 100%;
    }
  }
}

.link-copied {
  @apply rounded-big;

  padding: px(16);
  margin-top: px(16);
  background: var(--color-white);
}

.message {
  display: block;
  font-size: px(14);
  font-weight: $bold;
  margin-bottom: px(8);
}
</style>
