<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <button @click="open = true">
      <HamburgerIcon />
    </button>
    <Teleport to="#overlay">
      <aside class="mobile-overlay" :class="{ open }">
        <div class="light-part">
          <header class="header" :class="{ open }">
            <button class="close-btn" @click="open = false">
              {{ t('close') }} <CloseIcon />
            </button>
          </header>

          <div class="search-input">
            <SearchIcon @click="search" />
            <input
              v-model="searchTerm"
              aria-label="search"
              type="text"
              :placeholder="t('search')"
              @keydown.enter="search"
            >
          </div>

          <nav class="graph-links" role="list">
            <Go name="programs" role="listitem" class="graph-link entry" :style="{'--index': 0}">
              <ProgramIcon /> {{ t("program") }}
            </Go>
            <Go name="sdgs" role="listitem" class="graph-link entry" :style="{'--index': 1}">
              <SdgIcon /> {{ t("sdg") }}
            </Go>
            <Go name="topics" role="listitem" class="graph-link entry" :style="{'--index': 2}">
              <TopicIcon /> {{ t("topic") }}
            </Go>
            <Go name="projects" role="listitem" class="graph-link entry" :style="{'--index': 3}">
              <ProjectIcon /> {{ t("project") }}
            </Go>
          </nav>
        </div>

        <nav class="links highlighted" role="list">
          <Go
            :to="publicationPage?.uri"
            role="listitem"
            class="link entry"
            :style="{'--index': 4}"
          >
            <PageIcon /> {{ t("publications.self") }}
          </Go>
          <Go name="media-center" role="listitem" class="link entry" :style="{'--index': 5}">
            <PlayIcon /> {{ t("media.self") }}
          </Go>
        </nav>
        <footer class="entry" :style="{'--index': 6}">
          <span class="copyright">{{ t('copyright') }}</span>
          <div class="social" role="list">
            <Go :to="general?.youtube" role="listitem">
              <YoutubeIcon />
            </Go>
            <Go :to="general?.linkedin" role="listitem">
              <LinkedinIcon />
            </Go>
          </div>
        </footer>
      </aside>
      <header class="header">
        <Go name="home" aria-label="Home" class="home">
          <LogoMobile />
        </Go>
      </header>
    </Teleport>
  </div>
</template>

<script setup>
import {
  computed, unref, watch,
} from 'vue';
import { useToggle } from '@vueuse/shared';
import { useRoute } from 'vue-jkrouter';
import HamburgerIcon from '@/assets/images/hamburger.svg';
import SearchIcon from '@/assets/images/search.svg';
import CloseIcon from '@/assets/images/close.svg';
import LogoMobile from '@/assets/images/logo.svg';
import ProgramIcon from '@/assets/images/program.svg';
import SdgIcon from '@/assets/images/sdg.svg';
import TopicIcon from '@/assets/images/topic.svg';
import ProjectIcon from '@/assets/images/project.svg';
import PageIcon from '@/assets/images/page.svg';
import PlayIcon from '@/assets/images/play.svg';
import YoutubeIcon from '@/assets/images/youtube.svg';
import LinkedinIcon from '@/assets/images/linkedin.svg';
import { useJkcms, useTranslation } from '@/jkcms';
import { useSearchRouting } from '@/hooks/search';
import { lock, unlock } from '@/hooks/scrolllock';

const {
  term: searchTerm,
  search,
} = useSearchRouting();

const t = useTranslation();

const [open] = useToggle();

const cms = useJkcms();
const route = useRoute();
const general = computed(() => unref(cms)?.layout?.general);

const publicationPage = computed(() => cms?.layout?.navigation[267]);

watch(route, () => {
  open.value = false;
});

watch(open, (newOpen) => {
  if (newOpen) {
    lock();
  } else {
    unlock();
  }
});
</script>

<style lang="scss" scoped>
.home {
  display: block;
  z-index: 1000;
  position: relative;
  pointer-events: all;

  svg {
    @apply text-green;
    width: auto;
    @include responsive('height', 4rem, 3rem);
  }
}

.light-part {
  background: var(--color-white);

  padding: 0 px(37) px(67);

  .header {
    position: sticky;
    padding-right: 0;
    margin-bottom: px(44);
    pointer-events: all;
    height: px(48);
  }
}

aside.mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  background: var(--color-gray-200);
  transition: transform var(--transition-fast) ease;
  z-index: 1001;
  overflow-x: hidden;
  overflow-y: auto;

  @include responsive('display', block, none);

  &.open {
    transform: translateX(0);
  }
}

.header {
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  padding: px(25) px(37) 0 px(37);
  z-index: 1001;
  display: none;
  height: calc(var(--header-height) - px(25));
  pointer-events: none;

  @include responsive('display', flex, none);

  .home, .home svg {
    height: 100%;
    width: auto;
  }
}

.close-btn {
  display: flex;
  align-items: center;
  font-weight: $bold;
  margin-left: auto;

  :deep(svg) {
    width: px(14);
    height: px(14);
    margin-left: px(20);
  }
}

.search-input {
  @apply rounded-full;
  position: relative;
  width: 100%;
  padding: px(10);
  padding-left: px(64);
  background: var(--color-gray-200);

  input {
    background: none;
    font-weight: $bold;

    &::placeholder {
      color: currentColor;
      opacity: 0.5;
      outline: none;
    }

    &:focus {
      outline: none;
    }
  }

  :deep(svg) {
    position: absolute;
    left: px(28);
    top: 50%;
    transform: translateY(-50%);
    width: px(18);
    height: px(18);
  }
}

.graph-links {
  color: var(--color-green);
}

.graph-links, .links {
  margin-top: px(69);
  padding: 0 px(37) 0 px(37);

  .graph-link, .link {
    display: grid;
    grid-template-columns: px(36) 1fr;
    grid-column-gap: 1rem;
    margin: px(30) 0;
    font-size: px(21);
    font-weight: $bold;
    line-height: px(28);
    align-items: center;

    & > :first-child {
      justify-self: center;
    }
  }

  :deep(svg) {
    height: px(24);
    path {
      color: currentColor;
    }
  }
}

.links {
  margin-top: px(50);
  background: var(--color-gray-200);
  color: var(--color-gray-600);
  padding: 0 px(74) 0 px(74);
}

.entry {
  transform: translateX(3rem);
  opacity: 0;
  counter-increment: entries;
  transition: transform var(--transition-slow) ease-out, opacity var(--transition-slow) ease-out;

  .open & {
    transition-delay: calc(.1s + var(--index) * .07s);
    transform: translateX(0);
    opacity: 1;
  }
}

footer {
  padding: px(50) px(74);
  display: flex;
  align-items: center;
  color: var(--color-gray-600);
  justify-content: space-between;
}

.social {
  display: flex;
  column-gap: px(24);

  :deep(svg) {
    height: px(32);
  }
}

.copyright {
  font-size: px(20);
  line-height: px(20);
  font-weight: $bold;
}
</style>
