"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _RegexRouter_instances, _RegexRouter_trimStartingSlash, _RegexRouter_compileRoutes, _RegexRouter_createMatchResult;
Object.defineProperty(exports, "__esModule", { value: true });
const path_to_regexp_1 = require("path-to-regexp");
const util_1 = require("./util");
class RegexRouter {
    constructor(options = {}) {
        _RegexRouter_instances.add(this);
        this.options = options;
        if (options.routes) {
            this.replaceRoutes(options.routes);
        }
    }
    replaceRoutes(routes) {
        this.routes = routes;
        this.compiledRoutes = __classPrivateFieldGet(this, _RegexRouter_instances, "m", _RegexRouter_compileRoutes).call(this, this.routes, this.options);
    }
    ;
    matchByName(name, parameters) {
        if (name === undefined || name === null)
            return [];
        const result = [];
        const isSingleName = typeof name === 'string';
        (0, util_1.walk)(this.compiledRoutes, (route) => route.data.name, (route, path) => {
            let match;
            if (isSingleName) {
                const doesMatch = name === route.data.name;
                if (!doesMatch)
                    return;
                const currentPath = path.concat(name);
                match = __classPrivateFieldGet(this, _RegexRouter_instances, "m", _RegexRouter_createMatchResult).call(this, route, currentPath, parameters);
            }
            else {
                const doesMatch = name[path.length] === route.data.name;
                if (!doesMatch)
                    return;
                const currentPath = path.concat(name[path.length]);
                match = __classPrivateFieldGet(this, _RegexRouter_instances, "m", _RegexRouter_createMatchResult).call(this, route, currentPath, parameters, currentPath.length !== name.length);
            }
            if (match)
                result.push(match);
        });
        return result;
    }
    matchByPath(path) {
        const result = [];
        const strippedPath = (0, util_1.stripQueryString)(path);
        (0, util_1.walk)(this.compiledRoutes, (route) => route.data.name, (route, namePath) => {
            const match = route.match(strippedPath);
            if (match) {
                const currentPath = route.data.name ? namePath.concat(route.data.name) : namePath;
                const partial = !route.regexFull.test(strippedPath);
                const matchResult = __classPrivateFieldGet(this, _RegexRouter_instances, "m", _RegexRouter_createMatchResult).call(this, route, currentPath, match.params, partial);
                result.push(matchResult);
            }
        });
        return result;
    }
}
exports.default = RegexRouter;
_RegexRouter_instances = new WeakSet(), _RegexRouter_trimStartingSlash = function _RegexRouter_trimStartingSlash(path) {
    return path.startsWith('/') && path.length > 1 ? path.slice(1) : path;
}, _RegexRouter_compileRoutes = function _RegexRouter_compileRoutes(routes, options) {
    return routes.map((route) => {
        var _a;
        const prefix = (_a = options.prefix) !== null && _a !== void 0 ? _a : '';
        const routePath = prefix === '/' ? __classPrivateFieldGet(this, _RegexRouter_instances, "m", _RegexRouter_trimStartingSlash).call(this, route.path) : route.path;
        const path = `${prefix}${routePath}`;
        const keys = [];
        return Object.assign(Object.assign({}, route), { path,
            keys, regex: (0, path_to_regexp_1.pathToRegexp)(path, keys, Object.assign(Object.assign({}, options), { end: !route.children })), regexFull: (0, path_to_regexp_1.pathToRegexp)(path, null, Object.assign({}, options)), match: (0, path_to_regexp_1.match)(path, { decode: decodeURIComponent, end: !route.children }), compile: (0, path_to_regexp_1.compile)(path, { encode: encodeURIComponent }), children: __classPrivateFieldGet(this, _RegexRouter_instances, "m", _RegexRouter_compileRoutes).call(this, route.children, {
                prefix: path,
            }) });
    });
}, _RegexRouter_createMatchResult = function _RegexRouter_createMatchResult(route, namePath, parameters, partial = false) {
    if (path_to_regexp_1.match) {
        try {
            const compiledPath = route.compile(parameters);
            const matchingParams = parameters
                ? Object.fromEntries(Object.entries(parameters).filter(([key]) => route.keys.find((rkey) => rkey.name === key)))
                : {};
            return {
                path: compiledPath,
                template: route.path,
                data: route.data,
                pathParams: matchingParams,
                queryParams: {},
                partial,
                namePath,
            };
        }
        catch (_a) {
            // Not all parameters given. This is a partial match.
            return {
                template: route.path,
                data: route.data,
                pathParams: {},
                queryParams: {},
                partial: true,
                namePath,
            };
        }
    }
    return null;
};
