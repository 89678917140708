<template>
  <action-button :active="isActive" :icon="BookmarkIcon" @action="toggleBookmark" />
</template>

<script setup>
import { computed, unref } from 'vue';
import { useStore } from 'vuex';
import ActionButton from '@/components/utils/ActionButton.vue';
import BookmarkIcon from '@/assets/images/bookmark.svg';

const props = defineProps({
  id: { type: String },
  type: { type: String },
});

const store = useStore();

const toggleBookmark = () => store.commit('bookmarks/toggle', { type: props.type, id: unref(props.id) });
const isActive = computed(() => store.getters['bookmarks/active']({ type: props.type, id: unref(props.id) }));
</script>
