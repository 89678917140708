export function calculateBoundingBox(nodes) {
  if (!nodes.length) {
    return {
      topLeft: { x: 0, y: 0 },
      bottomRight: { x: 0, y: 0 },
    };
  }

  const lx = nodes.map((node) => node.x);
  const ly = nodes.map((node) => node.y);

  return {
    topLeft: { x: Math.min(...lx), y: Math.min(...ly) },
    bottomRight: { x: Math.max(...lx), y: Math.max(...ly) },
  };
}
