<template>
  <article class="SidePage">

    <ContentOverlay :model-value="true" disable-close>
      <div v-if="page" class="project">
        <div class="constrain">
          <header class="project-header">
            <h1 v-html="page.title" />
            <div v-if="page.intro" class="mb-12" v-html="page.intro" />
            <action-buttons>
              <BookmarkButton :id="page?.id?.toString()" type="project" role="menuitem" />
            </action-buttons>
          </header>
        </div>

        <Dynamic :inject="page?.parts" />

      </div>
      <Footer class="footer" sidebar />
    </ContentOverlay>
  </article>
</template>

<script setup>
import { computed } from 'vue';
import { hexToRgb } from '@/utils/color';
import Dynamic from '../structure/Dynamic.vue';
import BookmarkButton from '../utils/BookmarkButton.vue';
import ActionButtons from '../utils/ActionButtons.vue';
import Footer from '@/components/structure/Footer.vue';
import { useJkcms } from '@/jkcms';
import { useMeta } from '@/hooks/meta';
import ContentOverlay from '@/components/utils/ContentOverlay.vue';
import entities from '@/entities';

const cms = useJkcms();
const page = computed(() => {
  if (Array.isArray(cms.page?.content)) return null;
  return cms.page?.content;
});

const sdg = entities.useSdg(page.value?.sdgs?.[0]);

useMeta(computed(() => cms?.page?.meta));

</script>

<style lang="scss" scoped>

    .project {
        --text-color: v-bind('sdg?.hervorhebungsfarbe');
        --text-color-rgb: v-bind('hexToRgb(sdg?.hervorhebungsfarbe)');
        --project-color: v-bind('sdg?.farbe');
        --project-color-rgb: v-bind('hexToRgb(sdg?.farbe)');
        color: v-bind('sdg?.hervorhebungsfarbe');
    }

    .project-header {
        position: relative;
        h1 {
            width: 80%;
            @include responsive('margin-bottom', px(35), px(59));
            @include responsive('order', 2, 1);
        }
    }
</style>
