import { computed, watch, unref } from 'vue';
import ApiStore from './apiStore';

function useQueryName(query) {
  return `use${query[0].toUpperCase()}${query.slice(1)}Query`;
}

function queryName(query) {
  return `query${query[0].toUpperCase()}${query.slice(1)}`;
}

function assertIdentity(identity) {
  if (!identity) throw new Error('Missing identity');
}

export const baseQuery = async (params, opts = {}) => {
  // ToDo: why do I need to supply a controller?
  const { baseUrl, controller } = opts;

  if (typeof params === 'string') {
    const url = baseUrl ? `${baseUrl}${params}` : params;
    const result = await fetch(url, {
      signal: controller.signal,
    });
    return result.json();
  }
  const url = baseUrl ? `${baseUrl}${params.url}` : params.url;
  const restParams = Object.fromEntries(Object.entries(params).filter(([key]) => key !== 'url'));
  const result = await fetch(url, { ...restParams, signal: controller.signal });
  return result.json();
};

const STATUS_LOADING = 'loading';
const STATUS_ERROR = 'error';
const STATUS_SUCCESS = 'success';

export default function createApi(opts) {
  const { endpoints } = opts;
  const internalBaseQuery = opts.baseQuery ?? baseQuery;
  const store = opts.store ?? new ApiStore();

  const getIdentity = (definition, params) => {
    let identity = typeof definition.identity === 'function' ? definition.identity(params) : definition.identity;
    if (!identity) identity = JSON.stringify({ key: definition.key, params });
    assertIdentity(identity);
    return identity;
  };

  const getCacheKey = (identity, params) => identity ?? JSON.stringify(params);

  const getCacheFn = (definition) => definition.cache
    ?? ((identity, params, data) => {
      store.setCache(getCacheKey(identity, params), data);
    });

  const getFromCacheFn = (definition) => definition.fromCache ?? (
    (identity, params) => store.getCache(getCacheKey(identity, params))
  );

  const getInvalidateCacheFn = (definition) => definition.invalidateCache
    ?? ((identity, params) => {
      store.deleteCache(getCacheKey(identity, params));
    });

  const executeQuery = async (definition, params, queryOpts) => {
    const queryParams = typeof definition.query === 'string' ? definition.query : definition.query(params, queryOpts);
    const result = await internalBaseQuery(queryParams, queryOpts);
    if (definition.transformResponse) {
      return definition.transformResponse(result, { parameters: params });
    }
    return result;
  };

  const executeCachedQuery = async (definition, params, queryOpts) => {
    const identity = getIdentity(definition, params);
    store.updateQuery(identity, (v) => ({
      ...v, definition, params, queryOpts,
    }));
    const updateStatus = (status) => store.updateQuery(identity, (v) => ({ ...v, status }));
    const updateError = (error) => store.updateQuery(identity, (v) => ({ ...v, error }));
    const cached = unref(getFromCacheFn(definition)(identity, params));
    if (cached && !queryOpts?.refetch) {
      updateStatus(STATUS_SUCCESS);
      return cached;
    }
    if (!store.getQuery(identity)) store.setQuery(identity, {});

    if (store.getQuery(identity).status === STATUS_LOADING) {
      return new Promise((resolve, reject) => {
        const stop = watch(store.getQuery(identity), (query) => {
          const status = query?.status;
          if (status !== STATUS_LOADING) {
            if (status === STATUS_ERROR) {
              reject(query.error);
            } else {
              resolve(unref(getFromCacheFn(definition)(identity, params)));
            }
            stop();
          }
        });
      });
    }

    try {
      updateStatus(STATUS_LOADING);
      const result = await executeQuery(definition, params, queryOpts);
      getCacheFn(definition)(identity, params, result);
      updateStatus(STATUS_SUCCESS);
      return result;
    } catch (e) {
      updateStatus(STATUS_ERROR);
      updateError(e);
      return null;
    }
  };

  const executeRefetchQuery = (definition, params, queryOpts) => {
    const identity = getIdentity(definition, params);
    getInvalidateCacheFn(definition)(identity, params);
    return executeCachedQuery(definition, params, queryOpts);
  };

  const createUseQuery = (definition) => (params = null, queryOpts = null) => {
    const identity = computed(() => getIdentity(definition, unref(params)));

    if (queryOpts?.initial ?? true) {
      executeCachedQuery(definition, params, unref(queryOpts));
    }

    watch(identity, () => {
      executeCachedQuery(definition, unref(params), unref(queryOpts));
    });

    const queryState = computed(() => unref(store.getQuery(unref(identity))));
    const status = computed(() => unref(queryState)?.status);

    return {
      data: computed(() => unref(getFromCacheFn(definition)(unref(identity), unref(params)))),
      refetch: () => executeRefetchQuery(definition, unref(params), unref(queryOpts)),
      status,
      isInitial: computed(() => !unref(status)),
      isLoading: computed(() => unref(status) === STATUS_LOADING),
      isError: computed(() => unref(status) === STATUS_ERROR),
      isSuccess: computed(() => unref(status) === STATUS_SUCCESS),
      error: computed(() => unref(queryState)?.error),
    };
  };

  const createQuery = (definition) => async (params, queryOpts) => {
    const identity = getIdentity(definition, params);
    const result = await executeCachedQuery(definition, params, queryOpts);

    if (store.getQuery(identity).status === STATUS_ERROR) {
      throw store.getQuery(identity).error;
    }

    return result;
  };

  const exposedQueries = Object.entries(endpoints).reduce(
    (api, [key, definition]) => ({
      ...api,
      [useQueryName(key)]: createUseQuery({ ...definition, key }),
      [queryName(key)]: createQuery({ ...definition, key }),
    }),
    {
      invalidateAll: () => {
        store.deleteAllCache();
        Object.values(endpoints).forEach((definition) => {
          definition.invalidateCache?.();
        });
      },
      refetchAll: () => {
        const queries = store.getQueries();
        store.deleteAllCache();
        Object.values(queries.value).forEach(({ definition, params, queryOpts }) => {
          executeRefetchQuery(definition, unref(params), unref(queryOpts));
        });
      },
    },
  );

  return exposedQueries;
}
