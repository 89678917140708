<template>
  <Go :to="sNFLink" target="_blank">
    <Image class="snf-logo" :src="logo" />
  </Go>
</template>

<script setup>
import { computed } from 'vue';
import { useJkcms } from '@/jkcms';

const cms = useJkcms();
const logo = computed(() => cms?.layout?.general?.logo);
const sNFLink = computed(() => cms?.layout?.general?.sNFLink);
</script>

<style lang="scss" scoped>
.snf-logo {
  position: fixed;
  left: px(55);
  bottom: px(52);

  @include responsive('display', none, block);

}
</style>
