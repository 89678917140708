<template>
  <div ref="slider" class="relative select-slider">
    <div ref="indicator" class="indicator" />
    <slot
      v-for="item in items"
      :item="item"
      :target-ref="targetRef"
      :active="isActive(item)"
    />
  </div>
</template>

<script setup>// @ts-nocheck
import { useRoute } from 'vue-jkrouter';
import {
  ref, toRef, onBeforeUpdate, onMounted, onUnmounted, watch, computed, unref, nextTick,
} from 'vue';

const match = useRoute();

const props = defineProps({
  selected: { type: Number },
  items: { type: Array, default: () => [] },
});

let targetRefs = [];
const slider = ref(null);
const indicator = ref(null);

const selected = toRef(props, 'selected');
const activeIndex = ref(-1);

const indicatorItems = computed(() => props.items.filter((item) => item.indicator !== false));

onBeforeUpdate(() => {
  targetRefs = [];
});

const isActive = (item) => unref(activeIndex) !== -1 && unref(indicatorItems).indexOf(item) === unref(activeIndex);

const moveIndicatorToTarget = (index) => {
  if (!slider.value) return;

  if (activeIndex.value === -1) {
    indicator.value.style.transition = '0s';
  }

  indicator.value.style.opacity = 0;

  const target = targetRefs[index];

  if (target) {
    const { left, width } = target.getBoundingClientRect();
    const { left: nLeft } = slider.value.getBoundingClientRect();
    indicator.value.style.left = `${left - nLeft + width / 2}px`;
    indicator.value.style.width = `${width}px`;
    indicator.value.style.opacity = 1;
  }

  activeIndex.value = index;

  setTimeout(() => {
    indicator.value.style.transition = '0.3s';
  }, 10);
};

watch([selected, match], ([currentSelected]) => {
  nextTick(() => {
    moveIndicatorToTarget(currentSelected);
  });
});

const onMouseover = ({ target }) => {
  let current = target;
  while (!targetRefs.includes(current) && current !== unref(slider)) {
    current = current.parentElement;
  }
  const idx = targetRefs.indexOf(current);
  if (idx !== -1) moveIndicatorToTarget(idx);
  else moveIndicatorToTarget(selected.value);
};

const onMouseleave = () => {
  moveIndicatorToTarget(selected.value);
};

const onResize = () => {
  setTimeout(onMouseleave, 200);
};

onMounted(() => {
  window.addEventListener('resize', onResize);
  slider.value.addEventListener('mouseover', onMouseover);
  slider.value.addEventListener('mouseleave', onMouseleave);
  setTimeout(() => onMouseleave(), 500);
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize);
});

const targetRef = (el) => el && targetRefs.push(el.$el ?? el);
</script>
