<template>
  <section v-if="sdgs.length" class="result-type sdgs">
    <h2 v-if="filteredSdgs.length">
      {{ t('sdgs') }}
    </h2>
    <sdg-list :sdgs="sdgs" :filter="filter" />
  </section>
  <section v-if="topics.length" class="result-type topics">
    <h2 v-if="filteredTopics.length">
      {{ t('topics') }}
    </h2>
    <topic-list :topics="topics" :filter="filter" />
  </section>
  <section v-if="projects.length" class="result-type projects">
    <h2 v-if="filteredProjects.length">
      {{ t('projects') }}
    </h2>
    <project-list :projects="projects" :filter="filter" />
  </section>
  <section v-if="publications.length" class="result-type publications">
    <h2 v-if="filteredPublications.length">
      {{ t('publications.self') }} & {{ t('syntheses') }}
    </h2>
    <publication-list :publications="publications" :filter="filter" />
  </section>
  <section v-if="media.length" class="result-type medias">
    <h2 v-if="filteredMedias.length">
      {{ t('media.self') }}
    </h2>
    <media-list :medias="media.map(x=>x?.data||x).filter(x=>x.id)" :filter="filter" />
  </section>
</template>

<script setup>
import { computed, unref } from 'vue';
import { useTranslation } from '@/jkcms';
import TopicList from '@/components/utils/TopicList.vue';
import SdgList from '@/components/utils/SdgList.vue';
import ProjectList from '@/components/utils/ProjectList.vue';
import PublicationList from '@/components/utils/PublicationList.vue';
import MediaList from '@/components/utils/MediaList.vue';

const props = defineProps({
  filter: { type: Function, default: () => true },
  projects: { type: Array, default: () => [] },
  sdgs: { type: Array, default: () => [] },
  news: { type: Array, default: () => [] },
  topics: { type: Array, default: () => [] },
  publications: { type: Array, default: () => [] },
  media: { type: Array, default: () => [] },
});

const t = useTranslation();

const filteredSdgs = computed(() => unref(props.sdgs)?.filter(props.filter) ?? []);
const filteredTopics = computed(() => unref(props.topics)?.filter(props.filter) ?? []);
const filteredProjects = computed(() => unref(props.projects)?.filter(props.filter) ?? []);
const filteredMedias = computed(() => unref(props.media)?.filter(props.filter) ?? []);
const filteredPublications = computed(
  () => unref(props.publications)?.filter(props.filter) ?? [],
);
</script>

<style lang="scss" scoped>
h2,
.title {
  font-weight: $bold;

  @include responsive('font-size', px(16), px(28));
  @include responsive('line-height', px(24), px(38));
}

.result-type {
  @include responsive('margin-bottom', px(40), px(70));

  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
