import { createStore, createLogger } from 'vuex';
import { stores as entityStores } from '@/entities';
import filter from './modules/filter';
import bookmarks from './modules/bookmarks';
import cms from './modules/cms';
import VuexApiStore from '@/utils/api/vuexApiStore';

const debug = process.env.NODE_ENV !== 'production';

export const apiStore = new VuexApiStore('api');

const store = createStore({
  modules: {
    filter,
    bookmarks,
    cms,
    api: apiStore.vuex(),
    ...entityStores,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});

apiStore.setStore(store);

export default store;
