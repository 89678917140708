<template>
  <section class="cms-page">
    <div class="header-spacer" />
    <Dynamic
      v-if="cms.page?.content?.parts"
      :key="cms.meta?.uri"
      :inject="cms.page?.content?.parts"
    />
    <Footer />
    <slot />
  </section>
</template>

<script setup>
import { provide, computed } from 'vue';
import { useMeta } from '@/hooks/meta';
import Dynamic from '@/components/structure/Dynamic.vue';
import Footer from '@/components/structure/Footer.vue';
import { useJkcms } from '@/jkcms';

const cms = useJkcms();

useMeta(computed(() => cms?.page?.meta));

provide('container', (_, { slots }) => (
  <div class="constrain">
    <div class="row-12">
      <div class="col-12 md:col-10 md:offset-1">{slots.default()}</div>
    </div>
  </div>
));
</script>

<style lang="scss">
.cms-page {
  @apply flex flex-col;
  --part-highlight-color: var(--color-gray-200);
  min-height: 100vh;

  background-color: var(--color-gray-500);
}
</style>
