import { ref, unref } from 'vue';
import { useRoute, useRouter } from 'vue-jkrouter';
import { querySearch } from '@/cmsApi';
import { useJkcms } from '@/jkcms';

export const useSearch = () => {
  const loading = ref(false);
  const searchResults = ref(null);
  const router = useRouter();
  const match = useRoute();
  const language = ref(unref(match).parameters.path.locale);
  const searchTerm = ref(unref(match).parameters.query.term);

  const search = async () => {
    if (unref(match).parameters.query.term !== unref(searchTerm)) {
      router.replaceState(unref(match).data.name, unref(match).parameters.path, {
        ...unref(match).parameters.query,
        term: unref(searchTerm),
      });
    }
    const lang = unref(match).parameters.path.locale;
    const refetch = unref(language) !== lang;
    language.value = lang;
    loading.value = true;
    searchResults.value = await querySearch(unref(searchTerm), { refetch });
    loading.value = false;
  };

  return {
    term: searchTerm,
    results: searchResults,
    loading,
    search,
  };
};

export const useSearchRouting = () => {
  const searchTerm = ref('');

  const router = useRouter();
  const cms = useJkcms();
  const search = () => {
    if (!unref(searchTerm)) return;
    router.pushState('search', { locale: cms.locale }, { term: unref(searchTerm) });
  };
  return {
    search,
    term: searchTerm,
  };
};
