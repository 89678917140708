import { computed, unref } from 'vue';
import NProgress from 'nprogress';
import createApi from './utils/api/api';
import entities from './entities';
import store, { apiStore } from '@/store';

const synthesisAdapter = entities.createSynthesisAdapter(store);
const publicationAdapter = entities.createPublicationAdapter(store);
const mediaAdapter = entities.createMediaAdapter(store);
const projectAdapter = entities.createProjectAdapter(store);
const topicAdapter = entities.createTopicAdapter(store);
const sdgAdapter = entities.createSdgAdapter(store);

const entitiesCache = (adapter) => ({
  cache(_, __, data) {
    adapter.addMany(data);
  },
  fromCache() {
    const { all } = adapter;
    return computed(() => (unref(all)?.length ? unref(all) : null));
  },
  invalidateCache() {
    adapter.removeAll();
  },
});

async function loadPage(uri, opts) {
  NProgress.start();

  try {
    const { cms } = store.state;

    const response = await fetch(`${cms.base}/${cms.locale}/${uri}`, {
      headers: { Accept: 'application/json', View: cms.beta ? 'PREVIEW' : '' },
      signal: opts?.signal,
    });

    let incomingUri = response.url;
    // remove host
    incomingUri = incomingUri.replace(cms.base, '');

    const json = await response.json();

    NProgress.done();

    return { uri: incomingUri, data: json };
  } catch (e) {
    NProgress.done();
    // we want the callee to handle the exception as well
    throw e;
  }
}

const api = createApi({
  store: apiStore,
  async baseQuery(uri, opts) {
    const { data } = await loadPage(uri, opts);
    return data;
  },
  endpoints: {
    graph: {
      query: 'ajax/graph',
    },
    tags: {
      query: 'ajax/tags',
    },
    search: {
      query: (q) => `ajax/search?q=${q}`,
    },
    suggest: {
      query: (q) => `ajax/search?q=${q}&suggest`,
    },
    cmsPage: {
      query: (uri) => uri,
    },
    publications: {
      query: 'ajax/publications',
      ...entitiesCache(publicationAdapter),
    },
    synthesis: {
      query: 'ajax/synthesis',
      ...entitiesCache(synthesisAdapter),
    },
    media: {
      query: 'ajax/media',
      ...entitiesCache(mediaAdapter),
    },
    projects: {
      query: 'ajax/projects',
      ...entitiesCache(projectAdapter),
    },
    topics: {
      query: 'ajax/topics',
      ...entitiesCache(topicAdapter),
    },
    sdgs: {
      query: 'ajax/sdgs',
      ...entitiesCache(sdgAdapter),
    },
  },
});

// eslint-disable-next-line import/prefer-default-export
export const {
  invalidateAll,
  refetchAll,
  useGraphQuery,
  queryGraph,
  useTagsQuery,
  queryTags,
  useProjectsQuery,
  queryProjects,
  useTopicsQuery,
  queryTopics,
  useSdgsQuery,
  querySdgs,
  useMediaQuery,
  queryMedia,
  useSynthesisQuery,
  querySynthesis,
  useCmsPageQuery,
  queryCmsPage,
  usePublicationsQuery,
  queryPublications,
  useSearchQuery,
  querySearch,
  useSuggestQuery,
  querySuggest,
} = api;
