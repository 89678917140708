const createState = JSON.parse(global.localStorage.getItem('bookmarks')) ?? {};

const actions = {};
const mutations = {
  set(state, bookmarks) {
    Object.keys(state).forEach((key) => delete state[key]);
    Object.entries(bookmarks).forEach(([key, value]) => {
      state[key] = value;
    });
    global.localStorage.setItem('bookmarks', JSON.stringify(state));
  },
  toggle(state, { type, id }) {
    if (!(type in state)) state[type] = [];
    if (state[type]?.includes(id)) {
      state[type] = state[type].filter((bookmarkId) => bookmarkId !== id);
    } else {
      state[type].push(id);
    }
    global.localStorage.setItem('bookmarks', JSON.stringify(state));
  },
};

const getters = {
  all: (state) => state,
  active:
    (state) => ({ type, id }) => state[type]?.includes(id),
};

export default {
  namespaced: true,
  state: createState,
  actions,
  mutations,
  getters,
};
