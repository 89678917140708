<template>
  <div id="nav" class="relative px-12 flex">
    <!-- DESKTOP -->
    <nav class="inner w-full items-center">

      <div class="home">
        <Go name="home" aria-label="Home" class="inline-block">
          <Transition name="appear" mode="out-in">
            <LogoDe v-if="language == 'de'" />
            <LogoFr v-else-if="language == 'fr'" />
            <LogoEn v-else />
          </Transition>
        </Go>
      </div>

      <select-slider
        :items="topNavigation"
        :selected="selectedCenterNav"
        class="slider-nav center-nav"
      >
        <template #default="{ item, targetRef, active }">
          <Go
            :key="`${item.name}-link`"
            :ref="targetRef"
            :name="item.name"
            class="link font-bold selectable"
            :class="{ active }"
            @mouseenter="graphBus.emit('level', item.name)"
            @focusin="graphBus.emit('level', item.name)"
            @mouseleave="graphBus.emit('level', undefined)"
            @focusout="graphBus.emit('level', undefined)"
          >
            {{ item.text }}
          </Go>
        </template>
      </select-slider>

      <Teleport to="#overlay-navigation">
        <div id="nav" class="desktop">
          <nav>
            <top-right-navigation />
          </nav>
        </div>
      </Teleport>
      <top-right-navigation class="placeholder" />
    </nav>
    <!-- MOBILE -->
    <nav class="mobile-topright-nav">
      <Go name="home" aria-label="Home" class="home">
        <LogoMobile />
      </Go>
      <select-slider :items="mobileNavigation" class="slider-nav mobile-nav">
        <template #default="{ item, targetRef, active }">
          <Go
            v-if="item.type !== 'component'"
            :key="`${item.name}-link`"
            :ref="targetRef"
            :to="item.to"
            :name="item.name"
            :parameters="item.parameters"
            class="link font-bold selectable"
            :class="{ active }"
          >
            <component :is="item.icon" v-if="item.icon" class="icon" />
            {{ item.text }}
          </Go>
          <component
            :is="item.component"
            :ref="item.indicator !== false ? targetRef : null"
            class="component"
            :class="{ active }"
          />
        </template>
      </select-slider>
    </nav>
  </div>
</template>

<script setup>
import {
  computed, ref, unref, markRaw, onUnmounted,
} from 'vue';
import { useRoute } from 'vue-jkrouter';
import { useEventBus } from '@vueuse/core';
import { useTranslation } from '@/jkcms';

import LogoDe from '@/assets/images/Logo_NFP73_D_Color.svg';
import LogoEn from '@/assets/images/Logo_NFP73_E_Color.svg';
import LogoFr from '@/assets/images/Logo_NFP73_F_Color.svg';
import LogoMobile from '@/assets/images/logo.svg';
import SearchIcon from '@/assets/images/search.svg';
import SelectSlider from '../utils/SelectSlider.vue';
import GraphNavigation from '../utils/GraphNavigation.vue';
import MobileNavigation from './MobileNavigation.vue';
import TopRightNavigation from './TopRightNavigation.vue';
import { useMeta } from '@/hooks/meta';

const t = useTranslation();

const match = useRoute();
const language = computed(() => unref(match).parameters.path.locale);

const graphBus = useEventBus('graph');
onUnmounted(() => { graphBus.emit('level', undefined); });

const topNavigation = ref([
  {
    name: 'program',
    text: computed(() => t('theNFP', 'Das NFP')),
  },
  {
    name: 'sdg',
    text: computed(() => t('sdg', 'SDG').toUpperCase()),
  },
  {
    name: 'topics',
    text: computed(() => t('topics', 'Schwerpunkte')),
  },
  {
    name: 'projects',
    text: computed(() => t('researchProjects', 'Projekte')),
  },
]);

const mobileNavigation = computed(() => [
  ...(unref(match).namePath.includes('graph')
    ? [
      {
        type: 'component',
        indicator: false,
        component: markRaw(GraphNavigation),
      },
    ]
    : []),
  {
    name: 'search',
    icon: markRaw(SearchIcon),
  },
  {
    type: 'component',
    indicator: false,
    component: markRaw(MobileNavigation),
  },
]);

const selectedCenterNav = computed(
  () => unref(match)?.data.name
    && unref(topNavigation).findIndex((navEl) => unref(match).namePath.includes(navEl.name)),
);

useMeta({
  title: computed(() => (unref(selectedCenterNav) !== -1 ? unref(topNavigation)[unref(selectedCenterNav)]?.text : '')),
});
</script>

<style lang="scss">
#nav {
  @include responsive('padding', px(25) px(37), px(55) 0 1rem px(55));
  .onTop & {
    @include responsive('padding', px(25) px(37), px(75) 0 1rem px(55));
  }
}
</style>

<style lang="scss" scoped>
.home {
  svg {
    @apply text-green;
    width: auto;
    @include responsive('height', 4rem, 3rem);
  }
}

#nav {
  font-size: px(15);
  line-height: px(20);
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  transition: 0.15s ease;
  pointer-events: none;

  // @include responsive('padding', px(25) px(37), px(55) 0 1rem px(55));

  .inner {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-column-gap: px(16);
  }

  @media (max-width: theme('screens.md')) {
    &.desktop, .home, .inner {
      display: none;
    }
  }

  @media (max-width: theme('screens.lg')) {
    .inner > * {
      display: none;
    }
    .inner > .home {
      display: block;
    }
  }

  &.onTop {
    background: transparent;
  }
  &.down {
    transform: translateY(-100%);
  }
}

/* MOBILE NAV */
.backdrop {
  content: '';
  position: fixed;
  z-index: 98;
  top: -30vh; // -30 for ios overscroll
  left: 0;
  width: 100%;
  height: 160vh; // +2*30 for ios overscroll
  background: rgba(255, 255, 255, 0);
  transition: 0.5s ease;
  pointer-events: none;
  &.active {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    pointer-events: all;
  }
}

.mobile-topright-nav {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
  pointer-events: none;

  & > * {
    pointer-events: all;
  }

  @screen md {
    display: none;
  }

  .select-slider {
    margin: 0;
  }
}

nav {
  display: flex;
  align-items: center;
  height: 3.5rem;

  :deep(.indicator) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    will-change: left, width;

    height: 100%;
    box-sizing: content-box;
    background: var(--color-green);
    border-radius: var(--border-radius-nav);
    pointer-events: none;

    transition: 0.3s;
    transform: translateX(-50%);
  }
}

nav {
  @screen md {
    pointer-events: none;

    & > * {
      pointer-events: all;
    }
  }
  :deep(.slider-nav) {
    display: flex;
    align-items: center;
    margin: auto;
    position: relative;
    z-index: 0;
    border-radius: var(--border-radius-nav);
    height: 2.3rem;

    > .link {
      cursor: pointer;
      text-decoration: none;
      transition: color 0.25s;
      padding: px(8) px(27);
      z-index: 3;
      display: flex;
      align-items: center;
      height: 100%;

      .icon {
        height: 1em;
        width: auto;
        margin-right: px(8);
      }

      &.icon-only .icon {
        margin-right: 0;
      }

      &:hover {
        text-decoration: none;
        color: white;
      }

      &.active {
        color: var(--color-white);
      }
    }

    > .component {
      z-index: 10;
      height: 100%;
      display: flex;
      align-items: center;

      .multiselect {
        min-height: 100%;
      }

      &.search-bar {
        margin-right: px(-10);
      }
    }

    &.center-nav {
      background: var(--color-green-100);
      color: var(--color-green);
    }

    &.placeholder {
      visibility: hidden;
    }

    &.mobile-nav {
      background: var(--color-gray-400);
      color: var(--color-gray-300);

      .icon {
        width: 18px;
        height: 18px;
      }

      & > .link {
        padding: 0 px(16) 0 px(27);
      }

      & > :last-child {
        padding: 0 px(27) 0 0;
      }
    }
  }
}
</style>
