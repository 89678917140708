import { computed } from 'vue';
import { useWindowSize } from './observer';
import { theme, breakpointSize } from '../utils/tailwind';

const breakpointSizes = Object.entries(theme().screens).map(([n, s]) => [n, parseInt(s, 10)]);

breakpointSizes.sort(([, a], [, b]) => b - a);

function isWidthWithinBreakpoint(width, breakpoint) {
  const size = breakpointSize(breakpoint);
  return width >= size;
}

export function useBreakpoint() {
  const breakpoints = breakpointSizes.map(([name]) => name);
  const windowSize = useWindowSize();

  return computed(() => breakpoints.find(
    (breakpoint) => isWidthWithinBreakpoint(windowSize.value, breakpoint),
  ));
}

export function useWithinBreakpoint(breakpoint = 'md') {
  const windowSize = useWindowSize();
  return computed(() => isWidthWithinBreakpoint(windowSize.value, breakpoint));
}
