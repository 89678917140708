import {
  computed, ref, watch, unref,
} from 'vue';
import { useStore } from 'vuex';
import { and } from '@/utils/logic';
import { makeOptions, withTagFilter } from '@/utils/entities/selection';

export const useFilters = (options) => {
  const keys = Object.keys(options);
  const initialFilters = Object.fromEntries(keys.map((key) => [key, []]));
  const selectedFilters = ref(initialFilters);

  const getFilter = () => and(
    ...Object.entries(unref(selectedFilters)).map(([key, selected]) => (entity) => {
      if (!Array.isArray(selected)) {
        return (
          selected === undefined || selected === null || options[key](unref(entity), selected)
        );
      }
      return (
        !selected.length || selected.some((selectedId) => options[key](unref(entity), selectedId))
      );
    }),
  );

  const shouldFilter = ref(getFilter());

  watch(
    selectedFilters,
    () => {
      shouldFilter.value = getFilter();
    },
    { deep: true },
  );

  return {
    selected: selectedFilters,
    shouldFilter,
  };
};

export const useOptions = (entities) => computed(() => makeOptions(unref(entities)));

export const useTagFilter = (entities) => {
  const store = useStore();
  const selectedTags = computed(() => store.getters['filter/selectedTags']);
  return computed(() => withTagFilter(unref(entities), unref(selectedTags), store.getters['filter/isSelected']));
};

export const useEntityOptions = (existingEntities, entities, idsSelector) => {
  const existingIds = computed(() => {
    const ids = new Set();

    unref(existingEntities)?.forEach((entity) => {
      idsSelector(entity).forEach((id) => ids.add(id));
    });

    return Array.from(ids);
  });

  const all = computed(() => unref(existingIds)
    .map((id) => unref(entities)?.find((entity) => entity.id === id))
    .filter((v) => v));

  return useOptions(all);
};
