<template>
  <div class="Language">
    <Multiselect
      :model-value="language"
      :options="options"
      :allow-empty="false"
      :close-on-select="true"
      :can-clear="false"
      :can-deselect="false"
      mode="single"
      class="language-select"
      @input="switchLanguage"
    />
  </div>
</template>

<script setup>
import {
  unref, computed,
} from 'vue';
import { useRoute, useRouter } from 'vue-jkrouter';
import { useHead } from '@vueuse/head';
import { useJkcms } from '@/jkcms';
import Multiselect from './Multiselect.vue';

const match = useRoute();
const router = useRouter();
const cms = useJkcms();

const language = computed(() => unref(match).parameters.path.locale);
useHead({
  htmlAttrs: { lang: language },
  meta: computed(() => [
    { name: 'og:locale', content: unref(language) },
  ]),
});

const languages = computed(() => cms.locales);

const options = computed(() => unref(languages)?.map((lang) => ({ value: lang, label: lang.toUpperCase() })));

const switchLanguage = async (value) => {
  const actualMatch = unref(match).virtualMatch ?? unref(match);
  const params = actualMatch.parameters.path;
  if (params.uri) {
    params.uri = cms.page?.meta?.alt?.[value].slug ?? params.uri;
  }

  // ToDo: @alpoxo please check xD
  if (actualMatch.data.name === 'cms-page') {
    router.pushStateByPath(cms.page?.meta?.alt[value].uri);
    return;
  }

  router.pushState(
    actualMatch.data.name,
    { ...params, locale: value },
    { ...actualMatch.parameters.query },
  );
};
</script>

<style scoped lang="scss">
.language-select {
  font-weight: bold;

  --ms-bg: var(--bg-color);
  --ms-dropdown-bg: var(--bg-color);
  --ms-dropdown-border-width: 0px;
  --ms-option-radius: 25px;

  :deep(.caret path) {
    stroke-width: 2px;
  }

  :deep(.multiselect-dropdown) {
    width: auto;
    border-top-right-radius: 0;
  }

  :deep(.multiselect-option.is-selected) {
    display: none;
  }
}
</style>
