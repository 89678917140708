<template>
  <article class="content">
    <div v-if="program" class="program">
      <header class="program-header constrain">
        <h1 v-html="program.title" />
        <div v-if="program.intro" class="mb-12" v-html="program.intro" />
      </header>
      <Dynamic :inject="program.parts" />
    </div>
    <Footer class="footer" sidebar />
  </article>
</template>

<script setup>
import { computed, unref } from 'vue';
import Dynamic from '../structure/Dynamic.vue';
import Footer from '@/components/structure/Footer.vue';
import { useJkcms } from '@/jkcms';
import { useMeta } from '@/hooks/meta';

const cms = useJkcms();
const program = computed(() => cms.page?.content);

useMeta({
  title: computed(() => unref(program)?.title),
});
</script>

<style lang="scss" scoped>

</style>
