"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Html5History {
    constructor(options) {
        this.onNavigateImpl = options.onNavigate;
        this.router = options.router;
    }
    handler() {
        this.onNavigate(this.getPath());
    }
    shouldIgnoreAnchorClick(event, el, url) {
        const hostname = location === null || location === void 0 ? void 0 : location.hostname;
        const isCurrentDomain = (!location.protocol && !location.hostname) || hostname === url.hostname;
        const noModifierPressed = !event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey;
        const noFileExtension = !url.pathname.includes('.pdf') && !url.pathname.includes('.zip');
        const currentWindowTarget = !el.hasAttribute('target') || ['', '_self'].includes(el.getAttribute('target'));
        const isLeftMouseButton = event.button === 0;
        const isNotContentEditable = !el.isContentEditable;
        return (hostname &&
            isCurrentDomain &&
            noModifierPressed &&
            noFileExtension &&
            currentWindowTarget &&
            isLeftMouseButton &&
            isNotContentEditable &&
            this.router.matchByPath(url.pathname));
    }
    ignoreAnchorClick(event) {
        const target = event.composedPath() ? event.composedPath()[0] : event.target;
        const el = target.closest('a');
        if (!el)
            return;
        let href = el.href;
        if (el instanceof SVGAElement) {
            try {
                new URL(el.href.baseVal);
                href = el.href.baseVal; // The value is valid and absolute
            }
            catch (e) {
                // Likely relative
                href = `${window.location.protocol}//${window.location.host}${el.href.baseVal}`;
            }
        }
        const url = new URL(href);
        const shouldIgnore = this.shouldIgnoreAnchorClick(event, el, url);
        if (shouldIgnore) {
            event.preventDefault();
            const targetPath = url.pathname + url.search + url.hash;
            this.router.pushStateByPath(targetPath);
        }
    }
    init() {
        window.addEventListener('popstate', this.handler.bind(this), false);
        document.addEventListener('click', this.ignoreAnchorClick.bind(this));
        this.handler();
    }
    stop() {
        window.removeEventListener('popstate', this.handler.bind(this));
        document.removeEventListener('click', this.ignoreAnchorClick.bind(this));
    }
    onNavigate(path) {
        const match = this.router.matchByPath(path);
        match.state = window.history.state;
        this.onNavigateImpl(match, this);
    }
    getPath() {
        return `${window.location.pathname}${window.location.search}`;
    }
    href(path) {
        return path;
    }
}
exports.default = Html5History;
