import VuexStore from './vuexStore';

class VuexApiStore {
  constructor(prefix) {
    this.prefix = prefix;

    this.queries = new VuexStore(prefix, 'queries');
    this.cache = new VuexStore(prefix, 'cache');
  }

  setStore(store) {
    this.queries.setStore(store);
    this.cache.setStore(store);
  }

  setQuery(key, value) {
    this.queries.set(key, value);
  }

  getQuery(key) {
    return this.queries.get(key);
  }

  getQueries() {
    return this.queries.getAll();
  }

  updateQuery(key, updateFn) {
    this.queries.update(key, updateFn);
  }

  deleteQuery(key) {
    this.queries.delete(key);
  }

  setCache(key, value) {
    this.cache.set(key, value);
  }

  getCache(key) {
    return this.cache.get(key);
  }

  updateCache(key, updateFn) {
    this.cache.update(key, updateFn);
  }

  deleteCache(key) {
    this.cache.delete(key);
  }

  deleteAllCache() {
    this.cache.deleteAll();
  }

  vuex() {
    const vuexQueries = this.queries.vuex();
    const vuexCache = this.cache.vuex();
    return {
      namespaced: !!this.prefix,
      state: {
        queries: vuexQueries.state,
        cache: vuexCache.state,
        ...(window?.INIT_STORE_API || {}),
      },
      mutations: {
        ...vuexQueries.mutations,
        ...vuexCache.mutations,
      },
      getters: {
        ...vuexQueries.getters,
        ...vuexCache.getters,
      },
      actions: {
        persist({ state }) {
          const script = document.createElement('script');
          script.innerHTML = `window.INIT_STORE_API=${JSON.stringify(state)};`;
          document.head.appendChild(script);
        },
      },
    };
  }
}

export default VuexApiStore;
