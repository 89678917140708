<template>
  <div ref="swipe" class="mobile-swipe" :class="{ open }">
    <div class="spacer" aria-hidden @click="open = false" />
    <div class="mobile-swipe-card">
      <div class="handle" aria-hidden @click="open = false" />
      <slot :open="open" />
    </div>
  </div>
</template>

<script setup>
import {
  onMounted, onUnmounted, ref, watch,
} from 'vue';
import { useDrag } from '@vueuse/gesture';
import { useSpring, useMotionProperties } from '@vueuse/motion';
import { useOptionalModel } from '@/hooks/model';
import { lock, unlock } from '@/hooks/scrolllock';
import { useWindowSize } from '@/hooks/observer';

const props = defineProps({
  modelValue: { type: Boolean, default: false },
});

const emit = defineEmits(['update:modelValue']);

const swipe = ref(null);

const open = useOptionalModel(props, emit);

const targetY = () => (open.value ? 0 : swipe.value?.getBoundingClientRect().height ?? 0);

const { motionProperties } = useMotionProperties(swipe);

const { set, stop: stopNormalAnimation } = useSpring(motionProperties, {
  damping: 10,
  mass: 0.2,
  stiffness: 100,
});
const { set: setSlow, stop: stopSlowAnimation } = useSpring(motionProperties, {
  damping: 20,
  mass: 0.5,
});

const updateLock = () => {
  if (open.value) {
    lock();
    document.documentElement.style.overscrollBehaviorY = 'none';
    document.body.style.overscrollBehaviorY = 'none';
  } else {
    const allSwipes = [...document.querySelectorAll('.mobile-swipe.open')];
    const otherOpen = !!allSwipes.find((s) => s !== swipe.value);
    if (!otherOpen) {
      unlock();
      document.documentElement.style.overscrollBehaviorY = 'initial';
      document.body.style.overscrollBehavior = 'initial';
    }
  }
};

const updateOffset = () => {
  motionProperties.y = targetY();
};

useWindowSize(() => {
  updateOffset();
});

watch(open, (newOpen) => {
  updateLock();
  if (newOpen) {
    stopSlowAnimation();
    set({ y: targetY() });
  } else {
    stopNormalAnimation();
    setSlow({ y: targetY() });
  }
});

const stop = watch(open, () => {
  updateOffset();
  stop();
});

onMounted(() => {
  updateLock();
  updateOffset();
});

onUnmounted(() => {
  open.value = false;
  updateLock();
});

const dragHandler = ({
  movement: [, y], dragging, swipe: [, swipeY], event,
}) => {
  if (!open.value) return;

  const scrollTop = swipe.value?.scrollTop;
  if (scrollTop > 0) return;

  if (y > 0) {
    event.preventDefault();
  }

  if (swipeY) {
    if (swipeY > 0) {
      open.value = false;
      // const rect = swipe.value?.getBoundingClientRect();
      // stopSlowAnimation();
      // set({ y: rect.height });
    }
    return;
  }

  if (!dragging) {
    if (y > window.innerHeight * 0.3) {
      open.value = false;
      return;
    }

    set({ y: targetY() });
    return;
  }

  if (y < 0) return;

  // const rubber = rubberbandIfOutOfBounds(y, 0, 0, 0.17);
  // set({ y: rubber });
  set({ y });
};

// Composable usage
useDrag(dragHandler, {
  domTarget: swipe,
  useTouch: true,
  eventOptions: { passive: false },
  swipeDistance: 100,
});
</script>

<style lang="scss" scoped>
.mobile-swipe {
  width: 100%;
  height: 100vh;
  will-change: transform;
  pointer-events: none;
  position: fixed;
  top: 0;
  display: flex;
  flex-flow: column;
  opacity: 0;
  animation: 2s forwards fade-in;
  z-index: 99;

  &.open {
    overflow: hidden scroll;
    pointer-events: all;
  }

  &.mobile-swipe-card > :deep(*) {
    animation: var(--transition-slow) ease forwards fade-out;
  }

  &.mobile-swipe-card.open > :deep(*) {
    animation: var(--transition-slow) ease forwards fade-out;
  }
}

.mobile-swipe-card {
  @apply rounded-t-huge;

  position: relative;
  padding-top: 90px;
  padding-bottom: 60px;
  background: var(--color-gray-500);
  box-shadow: 0 3px 60px rgba(0, 0, 0, 0);
  margin-top: auto;
  z-index: 0;

  will-change: box-shadow;
  transition: box-shadow var(--transition-fast) ease;

  .mobile-swipe.open & {
    box-shadow: 0 3px 60px rgba(0, 0, 0, 0.16);
  }

  .handle {
    @apply rounded-full;

    overflow: hidden;
    position: absolute;
    width: 90px;
    top: 29px;
    left: 50%;
    height: 4px;
    background: var(--color-orange-300);
    z-index: 100;
    transform: translateX(-50%);

    &::after {
      content: '';
      position: absolute;
      height: 27px;
      width: 100%;
      top: 0;
      left: 0;
      transform: translateY(calc(-50% + 2px));
    }
  }
}

.spacer {
  min-height: 148px;
  opacity: 0;

  .mobile-swipe.open {
    opacity: 1;
  }
}
</style>
