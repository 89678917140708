<template>
  <SideContent v-if="isDesktop" v-bind="$props" v-model="open">
    <slot />
  </SideContent>
  <MobileSwipe v-else v-bind="$props" v-model="open">
    <slot />
  </MobileSwipe>
</template>

<script setup>
import { useVModel } from '@vueuse/core';
import { useWithinBreakpoint } from '@/hooks/breakpoints';
import MobileSwipe from './MobileSwipe.vue';
import SideContent from './SideContent.vue';

const isDesktop = useWithinBreakpoint();

const props = defineProps({
  modelValue: { type: Boolean, default: false },
  disableClose: { type: Boolean, default: false },
});

const emit = defineEmits(['update:modelValue']);

const open = useVModel(props, 'modelValue', emit, { passive: true });
</script>

<style lang="scss" scoped>
</style>
