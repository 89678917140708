<template>
  <article class="publication">
    <div class="squared-icon">
      <MediaIcon :media="publication" />
    </div>
    <span class="type">
      {{ publication.typ }}
    </span>
    <span class="title" v-html="publication.title" />
    <span class="teaser-text" v-html="publication.teaserText" />

    <styled-link v-if="publication.more || publication.link" class="link" :to="publication.more || publication.link">
      {{ t("readNow", "Jetzt lesen") }}
    </styled-link>
  </article>
</template>

<script setup>
import { toRef, unref, computed } from 'vue';
import { useTranslation } from '@/jkcms';
import MediaIcon from './MediaIcon.vue';

const t = useTranslation();

const props = defineProps({ publication: { type: Object, default: () => ({}) } });
const publication = toRef(props, 'publication');
const color = computed(() => unref(publication)?.color ?? 'currentColor');
</script>

<style scoped lang="scss">

.publication {
  --sdg-color: v-bind("color");
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: px(20);
}

.squared-icon {
  grid-row: 1 / 5;
  background: var(--sdg-color);

  :deep(svg) {
    max-width: px(13);
    max-height: px(13);
    path {
      stroke: var(--color-white);
    }
  }
}

.type {
  @apply text-small;

  display: block;
  position: relative;
  font-weight: $bold;
  line-height: px(22);
  margin-bottom: px(20);
  color: var(--sdg-color);

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: calc(100% + #{px(8)});
    width: 100%;
    height: 3px;
    background-color: var(--sdg-color);
  }
}

.title {
  display: block;
  font-weight: $bold;
  margin-bottom: px(18);
}

.teaser-text {
  @apply text-small;

  display: block;
  margin-bottom: px(18);
  line-height: px(18);
  font-weight: $semi-bold;
  font-style: italic;
}

.link {
  cursor: pointer;
}
</style>
