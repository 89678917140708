<template>
  <Multiselect
    mode="multiple"
    class="multi-select"
    :hide-selected="false"
    :close-on-select="false"
    v-bind="$props"
    @open="updateScrollbar"
  >
    <template #caret>
      <div class="caret">
        <Caret />
      </div>
    </template>
    <template v-if="!useDefaultLabel" #multiplelabel="{ values }">
      <div class="multiselect-multiple-label">
        {{ $attrs.placeholder }}
      </div>
      <div class="multiselect-number">
        {{ values.length }}
      </div>
    </template>
  </Multiselect>
</template>

<script setup>
import Multiselect from '@vueform/multiselect';
// import PerfectScrollbar from 'perfect-scrollbar';
import {
  onMounted, ref, toRef, watch,
} from 'vue';
// import { useCurrentElement } from '@vueuse/core';
import Caret from '@/assets/images/caret.svg';

const props = defineProps({
  useDefaultLabel: { type: Boolean, default: false },
  options: { type: [Array, Function] },
});

// const currentElement = useCurrentElement();
const scrollbar = ref();

const updateScrollbar = () => {
  scrollbar.value?.update();
};

watch(toRef(props, 'options'), updateScrollbar, { flush: 'post', deep: true });

onMounted(() => {
  /*
  const scrollContainer = currentElement.value.querySelector('.multiselect-dropdown');
  scrollbar.value = new PerfectScrollbar(scrollContainer, {
    wheelPropagation: false,
    suppressScrollX: true,
    scrollbarYActive: true,
  });
  scrollbar.value.update();
  */
});
</script>

<style lang="scss" scoped>
.multi-select {
  /* Multiselect */
  --ms-bg: var(--color-gray-400);
  --ms-px: #{px(20)};
  --ms-radius: 18px;
  --ms-ring-width: 0px;
  --ms-border-width: 0px;
  --ms-font-size: 15px;
  --ms-placeholder-color: var(--color-gray-300);

  --ms-dropdown-bg: var(--color-gray-400);
  --ms-dropdown-radius: 18px;

  --ms-option-bg-pointed: var(--color-gray-700);

  --ms-option-bg-selected: var(--color-red);
  --ms-option-bg-selected-pointed: var(--color-red);
  --ms-option-color-selected: var(--color-white);
  --ms-option-color-selected-pointed: var(--color-white);
}

.multiselect {
  width: fit-content;
  min-width: px(180);
  margin: initial;
  min-width: auto;

  @include hover {
    background: var(--ms-option-bg-pointed,#f3f4f6);
    color: var(--ms-option-color-pointed,#1f2937);
  }

  .caret {
    will-change: transform;
    transform: rotate(0);
    transition: transform var(--transition-fast) ease;
    margin-right: px(21);

    :deep(path) {
      stroke: currentColor;
    }
  }

  &.is-open .caret {
    transform: rotate(180deg);
  }

  :deep(.multiselect-placeholder),
  :deep(.multiselect-single-label),
  :deep(.multiselect-multiple-label) {
    padding-right: var(--ms-px);
    position: relative;
    margin-right: auto;
  }

  :deep(.multiselect-dropdown) {
    overflow-x: hidden;
    overflow-y: auto;
    border: 0;
    min-width: 100%;
    width: 14rem;
    border-top-right-radius: var(--ms-radius, 4px);

    @screen md {
      width: 18rem;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: var(--ms-option-bg-pointed,#f3f4f6);
      border-top-right-radius: var(--ms-radius, 4px);
      border-bottom-right-radius: var(--ms-radius, 4px);

    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-gray-600);
     border-radius: var(--ms-radius, 4px);
    }

  }

}

.multiselect-number {
  position: absolute;
  top: 0;
  right: 0;
  width: px(22);
  height: px(22);
  font-size: px(15);
  font-weight: $bold;
  line-height: px(20);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(25%, -25%);
  color: var(--color-red);

  &::before {
    content: '';
    @apply rounded-full;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: -1;
  }
}
</style>
