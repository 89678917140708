<template>
  <article ref="gridRef" role="list" class="grid-container isotope">
    <slot name="column-sizer">
      <div class="grid-sizer" />
    </slot>
    <slot name="gutter-sizer">
      <div class="gutter-sizer" />
    </slot>
    <section
      v-for="item in items"
      :key="item.id"
      v-remember:item="item"
      role="listitem"
      class="isotope-item"
    >
      <slot :item="item" />
    </section>
  </article>
</template>

<script setup>
import {
  toRef, onMounted, ref, watch, onBeforeUnmount, computed, unref, nextTick,
} from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import Isotope from 'isotope-layout';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'isotope-fit-columns';
import { getRemembered } from '@/utils/remember';

const props = defineProps({
  items: { type: Array },
  options: { type: Object },
});

const items = toRef(props, 'items');

const gridRef = ref(null);
const isotope = ref(null);

const filter = computed(() => {
  if (!props.options?.filter) return undefined;
  return typeof props.options.filter === 'string'
    ? unref(props.options.filter)
    : (el) => unref(props.options.filter)(getRemembered(el, 'item'));
});

watch(
  items,
  (_, oldItems) => {
    const arrangeOpts = { filter: unref(filter) };
    if (!oldItems?.length) {
      arrangeOpts.transitionDuration = 0;
    }

    nextTick(() => {
      isotope.value?.reloadItems();
      isotope.value?.layout();
      isotope.value?.arrange(arrangeOpts);
    });
  },
  { flush: 'post' },
);

watch(filter, () => {
  nextTick(() => {
    isotope.value?.arrange({ filter: unref(filter) });
  });
});

onMounted(() => {
  isotope.value = new Isotope(gridRef.value, {
    masonry: {
      columnWidth: '.grid-sizer',
      gutter: '.gutter-sizer',
    },
    fitRows: {
      gutter: '.gutter-sizer',
    },
    ...props.options,
    itemSelector: '.isotope-item',
    filter: unref(filter),
  });
});

onBeforeUnmount(() => {
  isotope.value?.destroy();
});
</script>

<style lang="scss">
:root {
  --isotope-gutter-width: clamp(#{px(15)}, 2vw, #{px(35)});
  --isotope-columns: 3;
}
</style>
<style lang="scss" scoped>
.grid-container {
  width: 100%;
  margin-bottom: calc(var(--isotope-gutter-width) * -1);

  --isotope-item-width: calc(
    (100% / var(--isotope-columns)) -
    var(--isotope-gutter-width) +
    (var(--isotope-gutter-width) / var(--isotope-columns))
  );
}

.isotope-item {
  & > :deep(*) {
    display: block;
  }

  width: var(--isotope-item-width);
  margin-bottom: var(--isotope-gutter-width);
}

.grid-sizer {
  // 3 columns
  width: var(--isotope-item-width);
}

.gutter-sizer {
  width: var(--isotope-gutter-width);
}
</style>
