"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.virtualizedMatch = void 0;
function isObject(ob) {
    return !Array.isArray(ob) && typeof ob === 'object';
}
function updateVirtualHost(router, host, target, currentState) {
    const state = Object.assign(Object.assign({}, (isObject(currentState) ? currentState : {})), { virtualHost: host });
    window.history.replaceState(state, '', router.history.href(target));
}
function matchVirtualHost(router, match) {
    const virtualHost = match.data.virtualHost;
    if (!virtualHost)
        return;
    const host = typeof virtualHost === 'function' ? virtualHost(match) : virtualHost;
    return host.includes('/') ? router.matchByPath(host[0]) : router.matchByName(host[0], host[1]);
}
function virtualizedMatch(router, match, oldMatch) {
    var _a;
    if (!match.data.virtual)
        return match;
    if (!oldMatch) {
        // We went back in history or reloaded the page
        // - look for a possible virtualHost in state.
        if ((_a = match.state) === null || _a === void 0 ? void 0 : _a.virtualHost) {
            const hostMatch = router.matchByPath(match.state.virtualHost);
            return Object.assign(Object.assign({}, hostMatch), { virtualMatch: match });
        }
        // We just came newly to this website
        const hostMatch = matchVirtualHost(router, match);
        if (hostMatch)
            return Object.assign(Object.assign({}, hostMatch), { virtualMatch: match });
        return match;
    }
    if (oldMatch && !(oldMatch === null || oldMatch === void 0 ? void 0 : oldMatch.data.virtual)) {
        // We move from a non-virtual to a virtual match
        updateVirtualHost(router, oldMatch.path, match.path, match.state);
    }
    return Object.assign(Object.assign({}, oldMatch), { virtualMatch: match });
}
exports.virtualizedMatch = virtualizedMatch;
