<template>
  <!-- eslint-disable vue/html-self-closing -->
  <div class="graph-container">
    <svg ref="graphRef"></svg>
    <div class="text-transform">
      <div ref="textsRef" class="text-node-container" />
    </div>
    <a href="https://snf.ch/" aria-label="SNF" target="blank">
      <snf-logo />
    </a>
  </div>
</template>

<script setup>
import {
  ref, computed, onMounted, watch, toRef,
} from 'vue';
import { useWithinBreakpoint } from '@/hooks/breakpoints';
import drawGraph, { getNodeData, getTransform } from './graph';
import { useWindowSize } from '@/hooks/observer';
import SnfLogo from '@/components/utils/SnfLogo.vue';

const props = defineProps({
  data: { type: Object },
  highlightedLevel: { type: String },
  hasSideContent: { type: Boolean, default: false },
});

const graphRef = ref(null);
const textsRef = ref(null);

const graphTransform = ref({
  scale: 0,
  translateX: 0,
  translateY: 0,
});

const data = toRef(props, 'data');
const hasSideContent = toRef(props, 'hasSideContent');

const isDesktop = useWithinBreakpoint();

const hasReducedWidth = computed(() => hasSideContent.value && isDesktop.value);

const offsetX = computed(() => (hasReducedWidth.value ? '-33.5%' : '0%'));
const offsetY = computed(() => '0%');

const levelMap = {
  program: {
    levelHighlight: [0],
    levelSeparations: [0, 100, 250, 500],
  },
  programs: {
    levelHighlight: [0],
    levelSeparations: [0, 100, 250, 500],
  },
  sdg: {
    levelHighlight: [1],
    levelSeparations: [0, 100, 250, 500],
  },
  sdgs: {
    levelHighlight: [1],
    levelSeparations: [0, 100, 250, 500],
  },
  topics: {
    levelHighlight: [2],
    levelSeparations: [0, 100, 250, 500],
  },
  projects: {
    levelHighlight: [3],
    levelSeparations: [0, 100, 250, 500],
  },
};

const highlightedLevels = computed(
  () => levelMap[props.highlightedLevel] ?? {
    levelHighlight: [],
    levelSeparations: [0, 50, 180, 350],
  },
);

const zoom = ref(undefined);

const updateGraph = () => {
  const availableWidth = hasReducedWidth.value ? window.innerWidth * 0.335 : window.innerWidth;
  const availableHeight = window.innerHeight;

  requestAnimationFrame(() => {
    const levelSeparation = highlightedLevels.value.levelSeparations.map((l) => {
      const level = isDesktop.value ? l : Math.log(l) ** 3.5 * 0.3;
      if (Math.abs(level) === Infinity) return 0;
      return level;
    });

    const nodeData = getNodeData({
      data: data.value,
      nodeSize: isDesktop.value ? 13 : 8,
      levelSeparations: levelSeparation,
      levelHighlights: highlightedLevels.value.levelHighlight,
    });

    const transform = getTransform(nodeData, availableWidth, availableHeight, hasReducedWidth.value);

    graphTransform.value = transform;

    const graphData = drawGraph({
      data: nodeData,
      svgNode: graphRef.value,
      textNode: textsRef.value,
      levelSeparations: levelSeparation,
      levelHighlights: highlightedLevels.value.levelHighlight,
      hasReducedWidth: hasReducedWidth.value,
      transform,
      width: availableWidth,
      height: availableHeight,
      zoom: zoom.value,
    });

    zoom.value = graphData.zoom;
  });
};

useWindowSize(updateGraph);
watch([highlightedLevels, data, hasSideContent], updateGraph);
onMounted(updateGraph);
</script>

<style scoped lang="scss">
.graph-container {
  --offset-x: v-bind(offsetX);
  --offset-y: v-bind(offsetY);

  --graph-scale: v-bind(graphTransform.scale);
  --graph-translateX: v-bind(graphTransform.translateX);
  --graph-translateY: v-bind(graphTransform.translateY);

  --default-transforms: translateY(calc(var(--header-height) / 2));

  --camera-speed: 1.1s;

  cursor: grab;

  width: 100%;
  height: 100vh;

  svg {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 0;
    user-select: none;
    pointer-events: all;

    :deep {
      .header-spacer {
        transform: var(--default-transforms);
      }

      .root {
        transition: transform var(--camera-speed) ease-in-out;
      }
      .animation-root {
        transition: transform var(--camera-speed) ease-in-out;
      }

      .node,
      .circle,
      .path {
        transition: opacity 0.3s;

        &.hidden {
          opacity: 0;
        }
      }

      .root {
        transition: transform var(--transition-fast) ease;
      }

      .node-circle {
        cursor: pointer;
        fill: var(--node-color);
        transition: color var(--transition-slow) ease;

        &.active,
        &.main.focused {
          --node-color: var(--node-color-highlight) !important;
        }

        &.main.focused {
          --node-color: var(--node-color-highlight) !important;
        }
      }
      circle.focus {
        pointer-events: none;
        transition: opacity var(--transition-fast) ease;
      }
      .circle-highlight {
        will-change: transform;
        transform: none;
        opacity: 0.2;
        transition: transform var(--transition-fast) ease;

        &.highlight-1.active {
          transform: scale(1.6);
        }

        &.highlight-2.active {
          transform: scale(2.4);
        }
      }
    }
  }
  .text-transform {
    width: 100%;
    height: 100vh;
    position: fixed;
    pointer-events: none;
    transition: transform var(--camera-speed) ease-in-out;
    transform-origin: top left;
  }
  .text-node-container {
    width: 100%;
    height: 100%;
    transform: translateY(calc(var(--header-height) / 2 * (1 / var(--zoom-scale))));
    transition: transform var(--transition-fast) ease;

    :deep {
      .node-text {
        pointer-events: none;
        position: fixed;
        left: calc(50% + var(--text-x));
        top: calc(50% + var(--text-y));
        width: 100%;
        max-width: 9rem;
        white-space: pre-line;
        font-size: px(12);
        line-height: px(19);
        font-weight: bold;
        opacity: 0;
        user-select: none;
        hyphens: auto;
        word-break: break-word;

        --offset: 1rem;
        @screen md {
          --offset: 3rem;
          max-width: 13rem;
          font-size: px(15);
        }

        transition:
          top var(--transition-fast) ease,
          opacity var(--transition-fast) ease,
          transform var(--transition-slow) ease;

        &.in {
          opacity: 1;
        }

        &.invisible {
          opacity: 0;
        }

        &.anchor-left {
         margin-left: var(--offset);
          transform:
            translateX(calc(var(--offset) * -1))
            translateY(-50%);

          &.in {
            transform:
              translateY(-50%);
          }
        }

        &.anchor-right {
          margin-left: calc(var(--offset) * -1);
          direction: rtl;
          transform:
            translateX(calc(-100% + var(--offset)))
            translateY(-50%);

          &.in {
            transform:
              translateX(-100%)
              translateY(-50%);
          }
        }
      }
    }
  }
}
</style>
