<template>
  <section class="autocomplete-section">
    <header class="header">
      <div class="title">
        {{ title }}
      </div>
      <Go class="show-all" :to="linkToAll">
        {{ t("showAll") }}
      </Go>
    </header>
    <slot />
  </section>
</template>

<script setup>
import { useTranslation } from '@/jkcms';

const t = useTranslation();

defineProps({
  title: { type: String },
  linkToAll: { type: String },
});
</script>

<style lang="scss" scoped>
.autocomplete-section {
  font-size: px(14);
  font-weight: $bold;
  overflow: hidden;
  margin-bottom: px(26);
  white-space: nowrap;

  &:last-of-type {
    margin-bottom: 0;
  }

  :slotted(span), :slotted(a) {
    display: flex;
    align-items: center;
    margin-top: px(8);
    margin-bottom: px(8);

    span {
      white-space: nowrap;
      width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  font-weight: $semi-bold;
  line-height: px(19);
  opacity: 0.5;
  position: relative;
  padding-bottom: px(4);
  margin-bottom: px(10);

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    background: currentColor;
    opacity: 0.5;
    height: 1px;
    width: 100%;
  }
}
</style>
