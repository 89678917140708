const createState = () => ({
  selectedTags: {},
  open: false,
});

const actions = {};
const mutations = {
  setOpen(state, open) {
    state.open = open;
  },
  selectTag(state, tag) {
    let group = state.selectedTags[tag.group];
    group = (group || []).filter((tagId) => tagId !== tag.id);
    state.selectedTags = {
      ...state.selectedTags,
      [tag.group]: group.concat(tag.id),
    };
  },
  removeTag(state, tag) {
    const group = state.selectedTags[tag.group];
    state.selectedTags = {
      ...state.selectedTags,
      [tag.group]: (group || []).filter((tagId) => tagId !== tag.id),
    };
  },
};

const getters = {
  isOpen: (state) => state.open,
  isSelected: (state) => (tag) => state.selectedTags[tag.group]?.includes(tag.id),
  selectedTagsByGroup: (state) => (group) => state.selectedTags[group],
  selectedTags: (state) => Object.values(state.selectedTags).flatMap((x) => x),
  groupedSelectedTags: (state) => state.selectedTags,
};

export default {
  namespaced: true,
  state: createState,
  actions,
  mutations,
  getters,
};
