import { useHead } from '@vueuse/head';
import { computed, unref } from 'vue';
import { useJkcms } from '@/jkcms';

function strip(text = '') {
  if (!text) return '';
  const nobreak = text.replaceAll('<br>', ' ');
  const doc = new DOMParser().parseFromString(nobreak, 'text/html');
  return doc.body.textContent || '';
}

export function useMeta(info) {
  const cms = useJkcms();
  const origin = computed(() => unref(cms)?.layout?.origin);

  const websiteTitle = computed(() => unref(cms)?.layout?.title || 'NFP 73');

  const pageTitle = computed(() => {
    let title = unref(unref(info)?.title);
    title = strip(title);
    if (title && title !== websiteTitle.value) return `${title} – ${websiteTitle.value}`;
    return websiteTitle.value;
  });

  const computedDesc = computed(() => {
    const desc = unref(unref(info)?.description);
    return strip(desc);
  });

  useHead({

    title: pageTitle,

    meta: computed(() => [
      { name: 'description', content: unref(computedDesc) },
      { property: 'og:description', content: unref(computedDesc) },
      { property: 'og:title', content: strip(unref(unref(info)?.title)) },
      { name: 'og:image', content: unref(unref(info)?.image) || '' },
      { property: 'og:type', content: unref(unref(info)?.type) || 'website' },
      { property: 'og:site_name', content: unref(websiteTitle) },
    ]),

    link: computed(() => {
      if (!unref(unref(info)?.translatedPaths)) return [];
      const tags = [];

      Object.entries(unref(unref(info)?.translatedPaths))
        .forEach((e) => tags.push({
          rel: 'alternate',
          hreflang: e[0],
          href: unref(origin) + e[1],
        }));

      return tags;
    }),

  });
}
