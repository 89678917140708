/* eslint-disable no-param-reassign */
import {
  computed, isReactive, reactive, toRaw,
} from 'vue';

function assertStore(store) {
  if (!store || !store.ids || !store.entities) throw new Error(`Invalid entity store '${store}'.`);
}

function assertEntity(data) {
  if (!data?.id) throw new Error(`Invalid entity data '${data}'. Missing id.`);
}

export function addMany(store, data) {
  assertStore(store);
  data.forEach(assertEntity);

  const ids = data.map((e) => e.id);

  const newIds = store.ids.filter((id) => ids.includes(id)).concat(ids);
  const newEntities = isReactive(store.entities) ? toRaw(store.entities) : store.entities;
  // eslint-disable-next-line no-restricted-syntax
  for (const entity of data) {
    newEntities[entity.id] = entity;
  }

  store.ids = newIds;
  store.entities = newEntities;
}

export function addEntity(store, data) {
  assertStore(store);
  assertEntity(data);

  // Replace
  if (store.entities[data.id]) {
    store.entities[data.id] = data;
    return;
  }

  store.ids.push(data.id);
  store.entities[data.id] = data;
}

export function getEntities(store, sortComparer) {
  assertStore(store);
  const { entities } = store;

  if (sortComparer) {
    const resultArr = Object.values(entities);
    resultArr.sort(sortComparer);
    return resultArr;
  }

  const result = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const id of store.ids) {
    result.push(entities[id]);
  }

  return result;
}

export function getEntity(store, id) {
  assertStore(store);
  return store.entities[id];
}

export function removeEntity(store, data) {
  assertStore(store);
  const remove = (realEntity) => {
    store.ids.filter((id) => id !== realEntity.id);
    delete store.entities[realEntity.id];
  };

  if (typeof data === 'string' || typeof data === 'number') {
    const realEntity = getEntity(data);
    if (realEntity) {
      remove(realEntity);
    }
  } else {
    assertEntity(data);

    remove(data);
  }
}

export function removeMany(store, data) {
  assertStore(store);
  if (!store) return;

  const entities = data
    .map((entity) => (typeof data === 'string' || typeof data === 'number' ? getEntity(entity) : entity))
    .filter((x) => x);

  const idsToRemove = entities.map((entity) => entity.id);

  store.ids = store.ids.filter((id) => !idsToRemove.includes(id));
  store.entities = Object.fromEntries(
    Object.entries(store.entities).filter(([id]) => !idsToRemove.includes(id)),
  );
}

export function replaceEntities(store, entities) {
  assertStore(store);
  entities.forEach(assertEntity);

  const ids = entities.map((entity) => entity.id);
  const mappedEntities = Object.fromEntries(entities.map((entity) => [entity.id, entity]));

  store.ids = ids;
  store.entities = mappedEntities;
}

export const createEntityStore = (
  { initialEntities, sortComparer, store } = { store: reactive({ ids: [], entities: {} }) },
) => {
  if (initialEntities?.length) {
    initialEntities.forEach(addEntity);
  }

  return {
    store,
    addOne(data) {
      addEntity(store, data);
    },
    addMany(data) {
      addMany(store, data);
    },
    setAll(data) {
      replaceEntities(store, data);
    },
    removeOne(data) {
      removeEntity(store, data);
    },
    removeMany(data) {
      removeMany(store, data);
    },
    removeAll() {
      replaceEntities(store, []);
    },
    getById(id) {
      return computed(() => getEntity(store, id));
    },
    getAll() {
      return computed(() => getEntities(store, sortComparer));
    },
    getIds() {
      return computed(() => store.ids);
    },
    getEntities() {
      return computed(() => store.entities);
    },
  };
};
