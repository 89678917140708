"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _BrowserRouterFactory_defaultOptions;
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyControllersDefaultStrategy = exports.applyControllers = exports.changedControllers = exports.controllerIdentity = void 0;
const html5History_1 = require("./html5History");
const router_1 = require("./router");
function equals(a, b) {
    if (a === b)
        return true;
    if (Object.is(a, b))
        return true;
    if (Array.isArray(a) && Array.isArray(b)) {
        if (a.length !== b.length)
            return false;
        return a.every((v, i) => equals(v, b[i]));
    }
    if (typeof a === 'object' && typeof b === 'object' && a && b) {
        const keys = Array.from(new Set([...Object.keys(a), ...Object.keys(b)]));
        return keys.every((k) => {
            if (!(k in a))
                return false;
            if (!(k in b))
                return false;
            return equals(a[k], b[k]);
        });
    }
    return false;
}
function controllerIdentity(controller, match) {
    if (controller.getIdentity) {
        return controller.getIdentity(match);
    }
    else if (controller.parameters) {
        const identityParameters = Object.fromEntries(Object.entries(controller.parameters).map(([paramType, keys]) => {
            const matchParams = match.parameters[paramType];
            const identityObject = Object.fromEntries(Object.entries(matchParams).filter(([key]) => keys.includes(key)));
            return [paramType, identityObject];
        }));
        return identityParameters;
    }
    return null;
}
exports.controllerIdentity = controllerIdentity;
function applyController(controller, method, ...args) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!controller)
            return null;
        if (controller[method]) {
            return controller[method](controller.identity, ...args);
        }
        return null;
    });
}
function changedControllers(prevMatch, newMatch) {
    var _a, _b, _c, _d;
    const padSameLength = (a = [], b = []) => a.concat(Array.from({ length: b.length - a.length }).fill(null));
    const oldControllers = (_b = (_a = prevMatch === null || prevMatch === void 0 ? void 0 : prevMatch.data.controllers) === null || _a === void 0 ? void 0 : _a.map((controller) => (Object.assign(Object.assign({}, controller), { identity: controllerIdentity(controller, prevMatch) })))) !== null && _b !== void 0 ? _b : [];
    const newControllers = (_d = (_c = newMatch.data.controllers) === null || _c === void 0 ? void 0 : _c.map((controller) => (Object.assign(Object.assign({}, controller), { identity: controllerIdentity(controller, newMatch) })))) !== null && _d !== void 0 ? _d : [];
    const controllers = padSameLength(oldControllers, newControllers)
        .map((oldController, i) => {
        var _a;
        const newController = (_a = newControllers[i]) !== null && _a !== void 0 ? _a : null;
        if (!oldController && !newController)
            return null;
        const equalIdentity = equals(oldController === null || oldController === void 0 ? void 0 : oldController.identity, newController === null || newController === void 0 ? void 0 : newController.identity);
        const equalFunctions = (oldController === null || oldController === void 0 ? void 0 : oldController.name) === (newController === null || newController === void 0 ? void 0 : newController.name);
        if (!equalIdentity || !equalFunctions) {
            return {
                old: oldController,
                new: newController,
            };
        }
        return null;
    })
        .filter((x) => x);
    return {
        old: controllers.map((controller) => controller.old).filter((c) => c),
        new: controllers.map((controller) => controller.new).filter((c) => c),
    };
}
exports.changedControllers = changedControllers;
function applyControllers(controllers, controllerMethod, router, data) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const sortedControllers = controllers
            .slice()
            .sort((a, b) => (b.priority || 0) - (a.priority || 0));
        // Depend execution model on context-internal state.
        // The queue holds yet to execute controllers
        // The stack holds already executed controllers
        let context = {
            data: data,
            router,
            stack: [],
            queue: sortedControllers,
        };
        const concurrentPromises = [];
        // Context can only be updated within synchronous / non-concurrent controllers.
        // Return values of concurrent controllers are ignored.
        while (context.queue.length) {
            const controller = context.queue.shift();
            if (!controller.concurrent) {
                context = (_a = (yield applyController(controller, controllerMethod, context))) !== null && _a !== void 0 ? _a : context;
            }
            else {
                concurrentPromises.push(applyController(controller, controllerMethod, context));
            }
            context.stack.push(controller);
        }
        yield Promise.all(concurrentPromises);
        return context;
    });
}
exports.applyControllers = applyControllers;
function applyControllersDefaultStrategy(router, oldMatch, newMatch, data) {
    return __awaiter(this, void 0, void 0, function* () {
        const controllers = changedControllers(oldMatch, newMatch);
        const context = yield applyControllers(controllers.old, 'stop', router, data);
        return applyControllers(controllers.new, 'start', router, context.data);
    });
}
exports.applyControllersDefaultStrategy = applyControllersDefaultStrategy;
class BrowserRouterFactory extends router_1.default {
    constructor(opts) {
        super(opts);
        _BrowserRouterFactory_defaultOptions.set(this, {
            history: (opts) => new html5History_1.default(opts),
        });
        this.options = Object.assign(Object.assign({}, __classPrivateFieldGet(this, _BrowserRouterFactory_defaultOptions, "f")), this.options);
        this.history = this.options.history({
            router: this,
            onNavigate: this.options.onNavigate,
        });
    }
    navigate(path) {
        var _a, _b;
        (_a = this.history) === null || _a === void 0 ? void 0 : _a.onNavigate(path !== null && path !== void 0 ? path : (_b = this.history) === null || _b === void 0 ? void 0 : _b.getPath());
    }
    init() {
        this.history.init();
    }
    stop() {
        this.history.stop();
    }
    href(name, parameters = {}, query = {}) {
        const match = this.matchByName(name, parameters);
        const path = this.matchToPath(match, query);
        return this.history.href(path);
    }
    pushState(name, parameters = {}, query = {}, data) {
        const match = this.matchByName(name, parameters);
        const path = this.matchToPath(match, query);
        this.pushStateByPath(path, data);
    }
    pushStateByPath(path, data) {
        const scrollPosition = {
            x: window.scrollX,
            y: window.scrollY,
        };
        window.history.replaceState(Object.assign(Object.assign({}, window.history.state), { scrollPosition }), document.title);
        window.history.pushState(data, document.title, this.history.href(path));
        this.history.onNavigate(path);
    }
    replaceState(name, parameters = {}, query = {}, data) {
        const match = this.matchByName(name, parameters);
        const path = this.matchToPath(match, query);
        this.replaceStateByPath(path, data);
    }
    replaceStateByPath(path, data) {
        window.history.replaceState(data, document.title, this.history.href(path));
        this.history.onNavigate(path);
    }
}
exports.default = BrowserRouterFactory;
_BrowserRouterFactory_defaultOptions = new WeakMap();
