"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reactify = exports.unrefDeep = exports.collectRefs = void 0;
const vue_1 = require("vue");
function collectRefs(ob) {
    const refs = [];
    if (typeof ob === 'object') {
        for (const el of ob) {
            refs.concat(collectRefs(el));
            if ((0, vue_1.isRef)(ob)) {
                refs.push(el);
            }
        }
    }
    return refs;
}
exports.collectRefs = collectRefs;
function unrefDeep(ob) {
    if (typeof ob === 'object') {
        if (Array.isArray(ob)) {
            return ob.map((el) => {
                return unrefDeep((0, vue_1.unref)(el));
            });
        }
        else {
            return Object.fromEntries(Object.entries(ob).map(([key, value]) => [key, unrefDeep((0, vue_1.unref)(value))]));
        }
    }
    else {
        return (0, vue_1.unref)(ob);
    }
}
exports.unrefDeep = unrefDeep;
/**
 * Converts plain function into a reactive function.
 * The converted function accepts refs as it's arguments
 * and returns a ComputedRef, with proper typing.
 *
 * @param fn - Source function
 */
function reactify(fn) {
    return function (...args) {
        return (0, vue_1.computed)(() => (0, vue_1.unref)(fn.apply(this, args.map(i => (0, vue_1.unref)(i)))));
    };
}
exports.reactify = reactify;
