<template>
  <span v-if="!routePath">
    <slot />
  </span>

  <a
    v-else-if="isAnchor(routePath)"
    v-scroll-to="isAnchor(routePath)"
    :href="isAnchor(routePath)"
    class="cursor-pointer"
    :class="{ current: isMatching }"
    @click="$emit('click', $event)"
  >
    <slot />
  </a>

  <a
    v-else-if="routePath.startsWith('http')"
    :href="routePath"
    rel="nofollow noopener"
    target="_blank"
    :class="{ current: isMatching }"
    @click="$emit('click', $event)"
  >
    <slot />
  </a>

  <a
    v-else-if="routePath.startsWith('tel:') || routePath.startsWith('mailto:')"
    href="#"
    :class="{ current: isMatching }"
    @click.prevent="special(to)"
  >
    <slot />
  </a>

  <a
    v-else
    :href="routePath"
    :class="{ current: isMatching }"
    @click="$emit('click', $event)"
  >
    <slot />
  </a>
</template>

<script>
import { computed, toRef, unref } from 'vue';
import {
  useMatch, useNameToPath, usePathMatch,
} from 'vue-jkrouter';
import { useJkcms } from '@/jkcms';

export default {
  props: {
    to: { type: String, default: null },
    name: { type: String, default: null },
    parameters: { type: Object, default: () => ({}) },
    query: { type: Object, default: () => ({}) },
    exact: { type: Boolean, default: false },
    matchVirtual: { type: Boolean, default: false },
  },
  emits: ['click'],
  setup(props) {
    const cms = useJkcms();
    const options = computed(() => ({ virtual: props.matchVirtual, exact: props.exact }));
    const pathMatch = usePathMatch(toRef(props, 'to'), options);
    const nameMatch = useMatch(toRef(props, 'name'), { locale: cms.locale }, options);
    const parameters = toRef(props, 'parameters');
    const namePath = useNameToPath(
      toRef(props, 'name'),
      computed(() => ({ ...unref(parameters), locale: cms.locale })),
      toRef(props, 'query'),
    );

    const isMatching = computed(
      () => (props.to && !!pathMatch.value) || (props.name && !!nameMatch.value),
    );

    const path = computed(() => {
      if (props.to) return props.to;
      if (!props.name) return null;
      return unref(namePath);
    });

    return {
      isMatching,
      routePath: path,
    };
  },
  methods: {
    isAnchor(to) {
      if (to.startsWith('#')) return to;
      return false;
    },
    special(to) {
      this.$emit('click');
      window.location.href = to.replace(/ /g, '');
    },
  },
};
</script>
