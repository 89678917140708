import {
  toRaw, unref, computed, reactive,
} from 'vue';
import { useStore } from 'vuex';
import {
  createEntityStore,
  getEntity,
  getEntities,
  addEntity,
  replaceEntities,
  removeEntity,
  removeMany,
  addMany,
} from './core';

// eslint-disable-next-line import/prefer-default-export
export const createVuexEntityStore = (singularName, pluralName) => {
  const entityStore = createEntityStore();

  const getters = {
    getById: (state) => (id) => getEntity(state, id),
    all: (state) => getEntities(state),
    ids: (state) => state.ids,
    entities: (state) => Object.values(state.entities),
  };

  const mutations = {
    addOne(state, data) {
      addEntity(state, data);
    },
    addMany(state, data) {
      addMany(state, data);
    },
    setAll(state, data) {
      replaceEntities(state, data);
    },
    removeOne(state, data) {
      removeEntity(state, data);
    },
    removeMany(state, data) {
      removeMany(state, data);
    },
    removeAll(state) {
      replaceEntities(state, []);
    },
  };
  const actions = {
    persist({ state }) {
      const script = document.createElement('script');
      script.innerHTML = `window.INIT_STORE_${pluralName.toUpperCase()}=${JSON.stringify(state)};`;
      document.head.appendChild(script);
    },
  };

  const createAdapter = (store) => {
    const path = (part) => `${pluralName}/${part}`;
    const createMutation = (name) => (...args) => store.commit(path(name), ...args);
    const createGetter = (name) => computed(() => store.getters[path(name)]);
    const mutationMap = Object.fromEntries(
      Object.keys(mutations).map((mutation) => [mutation, createMutation(mutation)]),
    );
    const getterMap = Object.fromEntries(
      Object.keys(getters).map((getter) => [getter, createGetter(getter)]),
    );
    return {
      entityStore: store[pluralName],
      ...mutationMap,
      ...getterMap,
    };
  };

  const hook = () => reactive(createAdapter(useStore()));
  const singleHook = (id) => {
    const adapter = hook();
    return computed(() => adapter.getById(unref(id)));
  };

  return {
    singularName,
    pluralName,
    createAdapter,
    hook,
    singleHook,
    store: {
      namespaced: true,
      state: () => window[`INIT_STORE_${pluralName.toUpperCase()}`] ?? toRaw(entityStore.store),
      getters,
      actions,
      mutations,
    },
  };
};
