<template>
  <div class="main-content" :class="{ open }">
    <button v-if="!disableClose" class="closer" @click="open = !open" />
    <div class="side-content">
      <aside>
        <slot :open="open" />
      </aside>
    </div>
  </div>
</template>

<script setup>
import { useOptionalModel } from '@/hooks/model';

const props = defineProps({
  modelValue: { type: Boolean, default: false },
  disableClose: { type: Boolean, default: false },
});

const emit = defineEmits(['update:modelValue']);

const open = useOptionalModel(props, emit);
</script>

<style lang="scss" scoped>
.main-content {
  --side-content-width: 67%;
}
.side-content {
  --content-width: var(--side-content-width);

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  //overflow-x: hidden;
 // overflow-y: auto;
  z-index: 99;
  pointer-events: none;

  & > * {
    pointer-events: all;
  }

  aside {
    display: flex;
    flex-flow: column;
    position: absolute;
    right: 0;
    top: 0;
    width: var(--content-width);
    background: var(--color-gray-500);
    min-height: 100vh;
    height: 100%;
    overflow: hidden;
    padding-top: 11rem;

    & > :deep(article.content) {
      display: flex;
      flex-flow: column;
      flex: 1;
    }

    :deep(.constrain), :deep(.footer) {
      padding-left: px(50);
      padding-right: px(73);
    }

    :deep(.constrain) {
      @screen md {
        padding-left: px(80);
        padding-right: px(100);
      }
      @screen lg {
        padding-left: px(80);
        padding-right: px(200);
      }
      @screen xl {
        padding-left: px(150);
        padding-right: px(300);
      }
    }

    --footer-x-padding: 0;
    --footer-y-padding: #{px(58)};

    :deep(.footer) {
      margin-top: auto;
    }

    will-change: transform;
    transition: transform var(--transition-fast) ease;
    transform: translateX(100%);

    .main-content.open & {
      transform: translateX(0);
      height: initial;
      min-height: 100%;
    }

    & > :deep(*) {
      animation: var(--transition-slow) ease forwards fade-out;
    }
  }

  .main-content.open & {
    aside > :deep(*) {
      animation: var(--transition-slow) ease forwards fade-in;
    }
  }
}

.closer {
  @apply rounded-full;

  position: fixed;
  width: 4px;
  height: px(90);
  top: 50vh;
  right: 0;
  opacity: 0;
  pointer-events: none;
  transform-origin: center;
  transform: translate(calc(-50% + 4px), -50%);
  transition:
    opacity var(--transition-fast) ease,
    right var(--transition-fast) ease,
    transform var(--transition-slow) ease;
  background: var(--color-orange-300);
  cursor: pointer;
  z-index: 100;

  .main-content.open & {
    opacity: 1;
    right: var(--side-content-width);
    pointer-events: all;
  }

  &:hover {
    transform: translate(calc(-50% + 4px), -50%) scale(1.1);
  }

  &::after {
    position: absolute;
    content: '';
    width: 27px;
    height: 100%;
    top: 0;
    left: 0;
    transform: translateX(calc(-50% + 4px));
  }
}
</style>
