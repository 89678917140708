<template>
  <section class="news-page">
    <div class="header-spacer" />

    <article class="media-center">
      <ContentOverlay :model-value="true" disable-close>
        <div class="constrain">

          <h1 class="mb-0">
            {{ payload.title }}
          </h1>
          <p v-if="payload.datum||payload.start||payload.ort" class="mt-2">
            <span v-if="payload.datum">{{ beautify(payload.datum) }}</span>
            <span v-if="payload.start">{{ beautify(payload.start) }} - {{ beautify(payload.ende) }}</span>
            <span v-if="payload.ort"> / {{ payload.ort }}</span>
          </p>

          <div v-if="payload.lead" class="font-bold mt-6 mb-8" v-html="payload.lead" />

          <div class="relative">
            <swiper
              v-if="payload.bild?.id"
              class="slider rounded-big mb-6 mt-8"
              :space-between="24"
              :watch-slides-progress="true"
              :thumbs="{ swiper: thumbsSwiper }"
              :modules="[Thumbs,Navigation]"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
            >
              <swiper-slide>
                <Image class="image big" :src="payload.bild" />
              </swiper-slide>
              <swiper-slide v-for="image in payload.galerie" :key="image.id">
                <Image :src="image" class="image big" />
              </swiper-slide>
            </swiper>
            <span v-if="payload.galerie?.length" class="swiper-button-prev">
              <div class="squared-icon">
                <ChevronIcon />
              </div>
            </span>
            <span v-if="payload.galerie?.length" class="swiper-button-next">
              <div class="squared-icon">
                <ChevronIcon />
              </div>
            </span>
          </div>
          <swiper
            v-if="payload.galerie?.length"
            class="slider thumbs rounded-big mb-12"
            :slides-per-view="3"
            :space-between="24"
            :watch-slides-progress="true"
            :modules="[Thumbs]"
            @swiper="s => thumbsSwiper = s"
          >
            <swiper-slide>
              <Image class="image" :src="payload.bild" />
            </swiper-slide>
            <swiper-slide v-for="image in payload.galerie" :key="image.id">
              <Image :src="image" class="image" />
            </swiper-slide>
          </swiper>

          <div class="content mt-8" v-html="payload.inhalt" />

          <!--
            <div class="mt-14" v-html="payload.author || payload.kontakt" />
          -->

        </div>
        <Footer class="footer" sidebar />
      </ContentOverlay>
    </article>

    <snf-logo grey />

  </section>
</template>

<script setup>
import { computed, ref } from 'vue';
import { format, isValid } from 'date-fns';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Thumbs, Navigation } from 'swiper';
import ContentOverlay from '@/components/utils/ContentOverlay.vue';
import Footer from '@/components/structure/Footer.vue';
import { useJkcms } from '@/jkcms';
import SnfLogo from '@/components/utils/SnfLogo.vue';
import { useMeta } from '@/hooks/meta';
import ChevronIcon from '@/assets/images/chevron.svg';

const cms = useJkcms();
const payload = computed(() => cms.page?.content.data || {});

const thumbsSwiper = ref(null);

function beautify(rawDate) {
  const date = new Date(rawDate);
  return isValid(date) ? format(date, 'dd.MM.yyyy') : rawDate;
}

useMeta(computed(() => ({
  ...cms?.page?.meta,
  type: 'article',
  description: cms?.page?.meta?.description || payload.value?.lead || payload.value?.inhalt,
  image: payload.value?.bild?.normal,
})));

</script>

<style lang="scss" scoped>

.image {
  @apply rounded-big bg-gray-200;
  aspect-ratio: 2 / 1;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  &.big {
    aspect-ratio: 16 / 9;
  }
}

:deep(.content) {
    a {
        border-bottom: 1px solid var(--color-active);
    }
    ol {
        list-style-type: lower-roman;
    }
    ul {
        list-style-type: disc;
        padding-left: 1rem;
        margin-bottom: .75em;
    }
}

.thumbs {
  .swiper-slide {
    cursor: pointer;
    opacity: .3;
    transition: opacity .3s;

    &:hover,
    &.swiper-slide-thumb-active {
      opacity: 1;
    }
  }

}

.swiper-button-prev,
.swiper-button-next {
  @apply rounded-full;

  overflow: hidden;
  width: fit-content;
  height: fit-content;
  background: var(--color-gray-400);

  &::after {
    content: none;
  }

  :deep(svg) {
    max-width: px(13);
    max-height: px(13);
    path {
      stroke: var(--color-gray-300)!important;
    }
  }
}

.swiper-button-prev {
  transform: rotate(90deg);
  left: 1rem;
}
.swiper-button-next {
  transform: rotate(-90deg);
  right: 1rem;
}

</style>
