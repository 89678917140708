<template>
  <transition name="appear">
    <div v-if="!cookie && general && !beta" id="cookie-wrapper">
      <div class="mb-6 -mt-1" v-html="general.cookie" />
      <div class="flex gap-4">
        <Button
          class="nfp-button white ml-auto"
          :arrow="false"
          @click="cookie = 'decline'"
        >
          {{ t('decline') }}
        </Button>
        <Button
          class="nfp-button mr-auto"
          :inverted="true"
          :arrow="false"
          @click="cookie = 'Allow'"
        >
          {{ t('accept') }}
        </Button>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed, unref, watch } from 'vue';
import { useStorage } from '@vueuse/core';
import { useStore } from 'vuex';
import { useGtm } from '@gtm-support/vue-gtm';
import { optIn as enableGtag } from 'vue-gtag';
import Button from '../utils/Button.vue';
import { useJkcms, useTranslation } from '@/jkcms';

const store = useStore();

const beta = computed(() => store.state.cms.beta);
const cookie = useStorage('cookie', '');

const cms = useJkcms();
const general = computed(() => unref(cms)?.layout?.general);
const t = useTranslation();

const gtm = useGtm();

watch(
  [cookie, beta],
  (a) => {
    // not accepted
    if (!a[0] || a[0] === 'decline') return;
    // not in prerender
    if (window.prerender) return;
    // not if beta
    if (a[1]) return;
    // start tracking
    enableGtag();
    gtm.enable(true);
  },
  { immediate: true },
);

</script>

<style scoped lang="scss">
#cookie-wrapper {
  position: fixed;
  z-index: 1000;
  @apply p-6;
  right: 0;
  left: 0;
  bottom: 0;
  @apply rounded-t-small;
  width: 100%;
  max-width: none;
  background: var(--color-green-100);

  @media print {
    display:none;
  }

  @screen md {
    left: inherit;
    right: 2rem;
    bottom: 2rem;
    max-width: 20rem;
    @apply rounded-small;
  }

  :deep(a) {
    border-bottom: 1px solid currentColor;
  }

  .white {
    @apply bg-white;
    @include hover {
      @apply bg-black;
    }
  }

}
</style>
