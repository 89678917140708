<template>
  <div class="header-spacer" />
  <article class="Search">
    <header class="constrain">
      <div class="row-12">
        <div class="col-12 md:col-10 md:offset-1">
          <div class="search-bar">
            <div class="icon">
              <SearchIcon class="search-icon" />
            </div>
            <input
              v-model="searchTerm"
              class="search-input title"
              type="text"
              :aria-label="t('search')"
              @keydown="onKeyDown"
            >
            <ChevronIcon v-if="!searchTerm" class="go icon" />
            <CloseIcon v-else class="close icon" @click="clear" />
          </div>
          <FilterBar :entities="allEntities" @change="shouldFilterChange" />
        </div>
      </div>
    </header>
    <section class="search-results light">
      <div class="constrain">
        <div class="row-12">
          <article class="col-12 md:col-10 md:offset-1">
            <span v-if="loading" class="no-data h3">
              <font-awesome-icon
                class="m-auto block"
                icon="circle-notch"
                spin
              />
            </span>
            <span v-else-if="!hasResults" class="no-data h3">
              {{ t('noResults') }}
            </span>
            <template v-else>
              <EntityList
                :projects="foundProjects"
                :topics="foundTopics"
                :publications="allPublications"
                :media="foundMedias"
                :filter="shouldFilter"
              />
            </template>
          </article>
        </div>
      </div>
    </section>
  </article>
  <Footer />
</template>

<script setup>
import {
  unref, computed, onMounted, watch, ref,
} from 'vue';
import { useRoute } from 'vue-jkrouter';
import { useTranslation } from '@/jkcms';
import SearchIcon from '@/assets/images/search.svg';
import ChevronIcon from '@/assets/images/chevron.svg';
import CloseIcon from '@/assets/images/close.svg';
import Footer from '@/components/structure/Footer.vue';
import { useTagFilter } from '@/hooks/filter';
import { useSearch } from '@/hooks/search';
import EntityList from '../utils/EntityList.vue';
import FilterBar from '../utils/FilterBar.vue';
import { useMeta } from '@/hooks/meta';

const t = useTranslation();

const match = useRoute();

const {
  term: searchTerm, results: searchResults, loading, search,
} = useSearch();

watch(match, (newMatch) => {
  searchTerm.value = newMatch.parameters.query.term?.toString();
  search();
});

const useSearchType = (type) => computed(
  () => {
    const hasType = (typeof type === 'string') ? (r) => r.type === type : (r) => type.includes(r.type);
    return unref(searchResults)?.results.filter(hasType).map((topicMatch) => topicMatch.data) ?? [];
  },
);

const shouldFilter = ref(() => true);

const shouldFilterChange = (nextFilter) => {
  shouldFilter.value = nextFilter;
};

const foundTopics = useSearchType('topic');
const foundProjects = useTagFilter(useSearchType('project'));
const foundMedias = useTagFilter(useSearchType(['media', 'veranstaltungen', 'news', 'photo-gallery', 'page']));
const foundSyntheses = useSearchType('synthesis');
const foundPublications = useSearchType('publication');
const allPublications = computed(() => unref(foundSyntheses).concat(unref(foundPublications)));

const allEntities = computed(() => [
  ...unref(foundTopics),
  ...unref(foundProjects),
  ...unref(foundMedias),
  ...unref(allPublications),
]);

const hasResults = computed(() => unref(allEntities).length);

const clear = () => {
  searchTerm.value = '';
};

const onKeyDown = (e) => {
  if (e.key !== 'Enter') return undefined;
  search();
  return false;
};

onMounted(() => {
  if (unref(searchTerm)) search();
});

useMeta({
  title: searchTerm,
});
</script>

<style lang="scss" scoped>
.search-bar {
  position: relative;
  margin-bottom: px(42);

  .icon {
    position: absolute;
    width: px(25);
    height: px(25);
    left: px(45);
    top: 50%;
    transform: translateY(-50%);

    @include responsive('left', px(24), px(45));
    @include responsive('width', px(16), px(25));
    @include responsive('height', px(16), px(25));
  }

  .go.icon,
  .close.icon {
    left: initial;

    @include responsive('right', px(24), px(45));
    @include responsive('width', px(12), px(16));
    @include responsive('height', px(12), px(16));
  }

  .close.icon {
    cursor: pointer;
  }

  .go.icon {
    transform: translateY(-50%) rotate(-90deg);
  }

  .search-input {
    @apply rounded-full;

    width: 100%;
    background: var(--color-white);
    border: 2px solid var(--color-gray-400);

    @include responsive('padding', px(10) px(20), px(20) px(90));
    text-align: center;

    &:focus {
      outline: none;
    }
  }
}

.search-results {
  background: transparentBackground(currentColor, 0.08);
  padding-top: var(--part-padding);
  padding-bottom: var(--part-padding);
}
</style>
