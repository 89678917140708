/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */

function updateNode(node, angle, levelSeparation) {
  node.angle = angle;
  node.radius = typeof levelSeparation === 'function' ? levelSeparation(node) : levelSeparation * node.depth;
  node.x = Math.sin(node.angle) * node.radius;
  node.y = Math.cos(node.angle) * node.radius;
}

function createLevelMap(root) {
  const levels = [
    /* <level>: {
      nodes: <node[]>,
      clusters: <numClusters>
    } */
  ];
  let prevNode;

  // Fill nodes per level
  root.each((node) => {
    const level = levels[node.depth];
    if (!level) {
      levels[node.depth] = {
        nodes: [node],
        clusters: 1,
      };
    } else {
      level.nodes.push(node);

      if (prevNode.parent !== node.parent) {
        level.clusters += 1;
      }
    }
    prevNode = node;
  });

  return levels;
}

function calculatePositions(
  node,
  levels,
  { clusterSeparationMultiplier = 1.3, levelSeparation = 1 },
) {
  let clusterGaps = 0;
  let previousNodes = 0;
  const level = levels[node.depth];

  // eslint-disable-next-line no-restricted-syntax
  for (const [i, levelNode] of Object.entries(level.nodes)) {
    const prevNode = level.nodes[i - 1];
    if (prevNode && prevNode.parent !== levelNode.parent) {
      clusterGaps += 1;
    }

    if (levelNode === node) {
      break;
    }
    previousNodes += 1;
  }

  const fullCircle = 2 * Math.PI;

  // c = clusterGaps
  // n = numNodes
  // m = clusterSeparationMultiplier
  // x = nodeSpacing
  // 2PI - cxm - x(n-c) = 0
  // --> x = 2PI / (n - c + cm)
  const allClusterGaps = level.clusters === 1 ? 0 : level.clusters;
  const nodeSpacing = fullCircle
    / (level.nodes.length - allClusterGaps + allClusterGaps * clusterSeparationMultiplier);
  const clusterSpacing = nodeSpacing * clusterSeparationMultiplier;

  const angle = clusterGaps * clusterSpacing + nodeSpacing * (previousNodes - clusterGaps);
  node.radius = levelSeparation * node.depth;

  updateNode(node, angle, levelSeparation);
}

function averageAngle(nodes) {
  const xs = nodes.reduce((x, node) => x + Math.sin(node.angle), 0) / nodes.length;
  const ys = nodes.reduce((y, node) => y + Math.cos(node.angle), 0) / nodes.length;
  return Math.atan2(xs, ys);
}

function createCopy(node) {
  const copy = node.copy();
  copy.eachAfter((descendant) => {
    descendant.data = { ...descendant.data };
  });
  return copy;
}

export default (options) => (basisRoot) => {
  const root = createCopy(basisRoot);
  const levels = createLevelMap(root);

  // First calculate positions
  for (const level of levels) {
    for (const node of level.nodes) {
      calculatePositions(node, levels, options);
    }
  }

  // eslint-disable-next-line for-direction
  for (let i = levels.length - 2; i > 0; i--) {
    const level = levels[i];
    const cumulativeAngleDiff = [0, 0];

    for (const node of level.nodes) {
      const center = node.children?.length ? averageAngle(node.children) : 0;
      const diff = node.angle - center;

      cumulativeAngleDiff[0] += Math.sin(diff);
      cumulativeAngleDiff[1] += Math.cos(diff);
    }

    for (const node of level.nodes) {
      updateNode(
        node,
        node.angle
          - Math.atan2(
            cumulativeAngleDiff[0] / level.nodes.length,
            cumulativeAngleDiff[1] / level.nodes.length,
          ),
        options.levelSeparation,
      );
    }
  }

  // Center first node of first level
  const firstLevelOffset = levels[1]?.nodes[0];
  if (firstLevelOffset) {
    const moveAllByAngle = -firstLevelOffset.angle;

    root.each((node) => {
      updateNode(
        node,
        node.angle + moveAllByAngle,
        options.levelSeparation,
      );
    });
  }

  return root;
};
