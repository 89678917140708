<template>
  <Teleport to="#overlay" :disabled="isDesktop">
    <div class="action-buttons" role="menu" v-bind="$attrs">
      <slot />
    </div>
  </Teleport>
</template>

<script setup>
import { useWithinBreakpoint } from '@/hooks/breakpoints';

const isDesktop = useWithinBreakpoint('md');
</script>

<style lang="scss" scoped>
.action-buttons {
  display: flex;
  flex-flow: column;
  row-gap: px(16);
  position: absolute;
  top: 0;
  left: calc(100% + #{px(34)});

  @media (max-width: theme('screens.md')) {
    position: fixed;
    height: fit-content;
    width: fit-content;
    top: initial;
    left: px(34);
    bottom: px(34);
    z-index: 1000;
  }
}
</style>
